/* eslint-disable react-hooks/exhaustive-deps */
import camelcase from 'camelcase';
import { KeyboardEvent, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';
import FilterOrb from '../FilterOrb';
import InputUnit from '../InputUnit';
import SquaredUnit from '../SquaredUnit';

type Translations = {
  title?: string;
  width: string;
  length: string;
  add: string;
};

type Props = {
  translations: Translations;
  onCalculate: (surfaces: Surface[]) => void;
  className?: string;
  preFilledSurfaces?: Surface[];
};

type Surface = {
  length: number;
  width: number;
  total: number;
};

const SurfaceCalculator = ({ preFilledSurfaces, className, onCalculate, translations }: Props) => {
  const [surfaces, setSurfaces] = useState<Surface[]>([]);

  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);

  const handleAdd = () => {
    const calculatedTotal = length * width;
    // toFixed returns a string, which will break the typing of the object. Conversion to Number is needed
    const convertedTotal = Number(calculatedTotal).toFixed(3);

    if (calculatedTotal === 0) {
      return;
    }

    const newSurface = { length, width, total: Number(convertedTotal) };

    const allSurfaces = [...surfaces, newSurface];

    setSurfaces(allSurfaces);
  };

  const handleRemove = (index: number) => {
    const allSurfaces = [...surfaces];

    allSurfaces.splice(index, 1);

    setSurfaces(allSurfaces);
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAdd();
    }
  };

  useEffect(() => {
    if (preFilledSurfaces) {
      setSurfaces(preFilledSurfaces);
    }
  }, []);

  useEffect(() => {
    onCalculate(surfaces);
  }, [surfaces]);

  return (
    <div className={twMerge('flex flex-col gap-4', className)}>
      {translations.title && <p className="font-bold">{translations.title}</p>}
      <div className="flex flex-col gap-3 md:flex-row">
        <div className="flex w-full flex-col gap-2">
          <span>{translations.length}</span>
          <InputUnit
            min="0"
            name="length-input-unit"
            onChange={e => setLength(Number(e.target.value))}
            onKeyDown={handleEnter}
            step="1"
            unit="m"
            value={length}
          />
        </div>
        <div className="flex w-full flex-col gap-2">
          <span>{translations.width}</span>
          <InputUnit
            min="0"
            name="width-input-unit"
            onChange={e => setWidth(Number(e.target.value))}
            onKeyDown={handleEnter}
            step="1"
            unit="m"
            value={width}
          />
        </div>
        <Button
          className="min-h-10 w-full md:w-fit md:self-end"
          label={translations.add}
          onClick={handleAdd}
          type="primary"
        />
      </div>
      {!!surfaces.length && (
        <div className="flex flex-wrap gap-2">
          {surfaces.map((surface, index) => (
            <FilterOrb
              ariaLabel={`${camelcase(translations.title ?? '')}-${surface.total}`}
              className="bg-gray-light"
              key={`surface-calculator-orb-${surface.total}-${index}`}
              label={
                <span>
                  {surface.total}
                  <SquaredUnit />
                </span>
              }
              onClick={() => handleRemove(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SurfaceCalculator;
