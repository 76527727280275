import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false, authScope: process.env.BOSS_API_AUTH_SCOPE_MAIL };
const HEADERS = {
  'Content-Type': 'application/json',
};

/**
 * Submit form data
 */
export const submitForm = (formData: Record<string, string>, locale: string) => {
  return bossApi(`mail/v2/mails/`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    body: formData,
    locale,
  });
};
