import type { Nullable, SimpleImage } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import { Image, Link } from '../index';

export type INewsArticleCard = {
  annotation: string;
  description?: string;
  isHighlighted?: boolean;
  id: string;
  image?: Nullable<SimpleImage>;
  link: string;
  title: ReactNode;
  translations: {
    buttonText: string;
  };
};

/**
 * Represents the properties of the NewsArticleCArd
 * @property {string} annotation - for now: the date the article was published but it might be used for tags or something in the future
 * @property {string} description - a short description of the content of the article
 * @property {boolean} isHighlighted - whether the article is highlighted (or not). Highlighted articles have a different (bigger) layout
 * @property {Nullable<SimpleImage>} image - the image used as illustration for the article.
 * @property {string} link - link to the article in question
 * @property {string} title - title of the article
 * @property {string} id - id of the article
 * @property {object} translations - an object containing the necessary copy for the component
 */

const WrapperStyle = cva('flex flex-col ', {
  variants: {
    isHighlighted: {
      true: 'md:grid md:grid-cols-3 md:gap-12',
    },
  },
});

const ImageStyle = cva('aspect-[9/5] h-auto object-cover overflow-hidden', {
  variants: {
    isHighlighted: {
      true: 'mb-3.5 md:col-span-2',
      false: 'mb-5',
    },
  },
});

const NewsArticleCard = ({
  annotation,
  description,
  image,
  isHighlighted,
  link,
  title,
  translations,
}: INewsArticleCard) => {
  return (
    <div
      className={WrapperStyle({
        isHighlighted,
      })}
    >
      <Image
        alt={image?.imageAlt ?? ''}
        src={image?.imageUrl}
        wrapperClassName={ImageStyle({
          isHighlighted,
        })}
      />
      <div className="flex flex-col">
        <p className="text-s opacity-0.6 mb-2.5">{annotation}</p>
        <h2 className="h3 mb-3">{title}</h2>
        <p className="body mb-3">{description}</p>
        <Link className="button border-b-brand border-b-1 w-fit flex-none border-solid pb-1 uppercase" href={link}>
          {translations?.buttonText}
        </Link>
      </div>
    </div>
  );
};

export default NewsArticleCard;
