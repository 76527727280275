import { Placement } from '@floating-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Popper from '../Popper';

type Props = {
  buttonClassName?: string;
  children: ReactNode;
  className?: string;
  content: ReactNode;
  floatingClassName?: string;
  icon?: IconProp;
  placement?: Placement;
};

/**
 * Places a tooltip at the top right of the passed children
 **/
const Tooltip = ({
  buttonClassName,
  children,
  className,
  content,
  floatingClassName,
  icon = faCircleInfo,
  placement = 'top',
}: Props) => (
  <Popper
    button={
      <button
        aria-label="tooltip-button"
        className={twMerge('absolute bottom-full left-full flex', buttonClassName)}
        type="button"
      >
        <FontAwesomeIcon className="text-blue-dark text-xs" icon={icon} />
      </button>
    }
    className={className}
    floating={
      <div className={twMerge('z-modal max-w-10 rounded-md bg-white p-3 shadow-sm', floatingClassName)}>{content}</div>
    }
    placement={placement}
  >
    {children}
  </Popper>
);

export default Tooltip;
