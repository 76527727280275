import { faCheck, faCircle, faCircleCheck, faClose, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { twMerge } from 'tailwind-merge';

import PrimaryArticleCard from './Primary';
import SecondaryArticleCard from './Secondary';
import { CardLabelProps } from '../CardLabel';
import Presence from '../Presence';

type ArticleCardType = 'default' | 'extended';

interface Translations {
  articleRef: string;
  itemdeleted: string;
  amount: string;
  unit: string;
  color?: string;
  selectColor: string;
  delete: string;
  deleted: string;
  netPrice: string;
  grossPrice: string;
  discount: string;
  discountInfo?: string;
}

interface Article {
  name: string;
  id: string;
  productId: string;
  product?: {
    name: string;
  };
}

export interface CardProps {
  labels?: CardLabelProps[];
  className?: string;
  article: Article;
  translations: Translations;
  onRemove?: () => void;
  deleted?: boolean;
  type?: ArticleCardType;
  onUpdateQuantity?: (quantity: number) => void;
  onUpdatePackaging?: (packaging: string) => void;
  onColorSelect?: () => void;
  quantity?: number;
  packaging?: string;
  packagingOptions?: {
    name: string;
    quantity: number;
  }[];
  imageSrc?: string | null;
  color?: {
    name: string;
    rgb: string;
  };
  isPaint?: boolean;
  showColorPicker?: boolean;
  slug?: string;
  title?: string;
  price?: {
    netInclVat: number;
    netExclVat: number;
    salesPrice: number;
  };
  discount?: number;
  discountInfo?: string;
  testid?: 'article-card';
  isUpdating?: boolean;
  isDeleting?: boolean;
  discountConditions?: string[];
  disabled?: boolean;
}
export interface Props extends CardProps {
  onSelect?: () => void;
  selected?: boolean;
  variant?: 'primary' | 'secondary';
}

const ArticleCardStyle = cva(
  'flex items-center relative text-left w-full flex focus:outline-none focus:ring-2 focus:ring-blue',
  {
    variants: {
      selected: {
        true: 'ring-1 ring-blue',
        false: 'ring-transparent',
      },
      deleted: {
        false: 'bg-white',
        true: 'bg-opacity-10 bg-gray',
      },
      variant: {
        primary: '',
        secondary: 'shadow-sm m-1',
      },
    },
  },
);

const Card = ({ variant, onSelect: handleSelect, selected, deleted, isDeleting, ...props }: Props) => {
  const card =
    variant === 'primary' ? (
      <PrimaryArticleCard deleted={deleted} {...props} />
    ) : (
      <SecondaryArticleCard deleted={deleted} {...props} />
    );

  if (handleSelect) {
    return (
      <div
        aria-label="select article"
        className={twMerge('flex items-center', ArticleCardStyle({ selected, deleted, variant }))}
      >
        <button className="h-full px-5" onClick={!isDeleting ? handleSelect : undefined}>
          <FontAwesomeIcon className={twMerge('text-blue')} icon={selected ? faCircleCheck : faCircle} />
        </button>

        {card}
      </div>
    );
  }

  return <div className={twMerge(ArticleCardStyle({ deleted }))}>{card}</div>;
};

const CardDeleteStyling = cva('', {
  variants: {
    state: {
      validating: '-translate-x-[160px]',
      deleted: 'hidden',
      default: '',
    },
  },
});

const DeleteButtonStyling = cva('w-40', {
  variants: {
    state: {
      validating: '',
      deleted: 'w-full',
      default: '',
    },
  },
});

const ArticleCard = ({
  onRemove,
  quantity,
  translations,
  variant,
  article,
  type,
  testid,
  disabled = false,
  ...props
}: Props) => {
  const [cardState, setCardState] = useState<'deleted' | 'validating' | 'default'>('default');

  const popupHandlers = useSwipeable({
    onSwiped: eventDetail => {
      if (eventDetail.dir === 'Right') {
        if (cardState === 'default') {
          setCardState('validating');
        } else {
          handleDelete();
        }
      }
    },
  });

  const handleDelete = () => {
    setCardState('deleted');
    onRemove?.();
  };

  const cancelDelete = () => {
    setCardState('default');
  };

  /**
   * Will intercept when value for the quantity is 0 and perform a card state change for deletion.
   * @date 10-1-2024 - 10:21:33
   */
  const handleUpdateQuantity = (value: number) => {
    if (value === 0) {
      setCardState('validating');
    } else {
      setCardState('default');
      if (props.onUpdateQuantity && quantity !== value) {
        props.onUpdateQuantity(value);
      }
    }
  };

  return (
    <Presence className="overflow-hidden" id={article.id} visible>
      <div
        className={twMerge('relative flex bg-white transition', CardDeleteStyling({ state: cardState }))}
        {...popupHandlers}
        data-testid="article-card"
      >
        {disabled && <div className="absolute inset-0 z-10 h-full w-full bg-white mix-blend-color"></div>}
        <Card
          {...props}
          article={article}
          isDeleting={cardState === 'validating'}
          onRemove={onRemove ? () => setCardState(prev => (prev === 'default' ? 'validating' : 'default')) : undefined}
          onUpdateQuantity={props.onUpdateQuantity ? handleUpdateQuantity : undefined}
          quantity={quantity}
          testid={testid}
          translations={translations}
          type={type}
          variant={variant}
        />
        {type === 'extended' && onRemove && (
          <div className="relative">
            <button
              className={twMerge(
                'bg-red-dark absolute left-full flex h-full cursor-pointer flex-col items-center justify-center gap-2 overflow-hidden p-5 text-white transition',
                DeleteButtonStyling({ state: cardState }),
              )}
              onClick={handleDelete}
            >
              <FontAwesomeIcon
                className="cursor-pointer text-xl text-white"
                icon={cardState === 'deleted' ? faCheck : faTrashCan}
              />
              <p>{cardState === 'deleted' ? translations.deleted : translations.delete}</p>
            </button>
            <button
              className="text-red-dark absolute left-full top-5 z-10 ml-[120px] h-5 w-5 rounded-full bg-white"
              onClick={cancelDelete}
            >
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        )}
      </div>
    </Presence>
  );
};

export default ArticleCard;
