import { Nullable, SimpleImage } from '@boss/types/b2b-b2c';

import { Image, Link } from '..';

/**
 * @returns {component} A small card to show a brand logo
 * @property {Nullable<SimpleImage>} logo The logo to be displayed
 */

export type BrandCardsProps = {
  logo: Nullable<SimpleImage>;
  link: string;
};

const BrandCards = ({ logo, link }: BrandCardsProps) => (
  <Link href={link}>
    <div className="flex aspect-[10/9] items-center justify-center bg-white px-6 shadow-2xl">
      <Image alt={logo?.imageAlt ?? 'logo'} className="mb-3 object-contain" src={logo?.imageUrl} />
    </div>
  </Link>
);

export default BrandCards;
