import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { Datepicker } from '@boss/ui';

type Filters = {
  fromDate: Date | undefined;
  toDate: Date | undefined;
};

type Props = {
  filters: Filters;
  setFilters: (val: Filters) => void;
  unsetTodayAsMaxDate?: boolean;
  className?: string;
};

const FromToDatePicker = ({ className, filters, setFilters, unsetTodayAsMaxDate }: Props) => {
  const { t } = useTranslation(['account']);
  const today = new Date();
  const { locale } = useRouter();

  return (
    <div className={twMerge('xs:flex-row flex flex-col gap-5 pt-6 md:gap-0 lg:col-span-2', className)}>
      <div className="flex w-full items-center justify-between" data-testid="datepicker-from">
        <Datepicker
          className="focus:shadow-outline w-full appearance-none border-b border-l border-t leading-tight text-gray-700 shadow focus:outline-none"
          inputClassName="bg-white py-2 xs:rounded-r-none border-0"
          locale={locale}
          maxDate={!unsetTodayAsMaxDate ? today : undefined}
          onChange={newDate => setFilters({ ...filters, fromDate: newDate })}
          placeholder={t('invoices.filters.from', { ns: 'account' }).toString()}
          value={filters.fromDate}
        />
      </div>
      <div className="xs:flex relative hidden w-0.5 bg-white">
        <div className="absolute right-0 top-[20%] h-[60%] w-0.5 bg-gray-200"></div>
      </div>
      <div className="flex w-full items-center justify-between" data-testid="datepicker-to">
        <Datepicker
          className="focus:shadow-outline w-full appearance-none rounded border-b border-r border-t leading-tight text-gray-700 shadow focus:outline-none"
          inputClassName="bg-white py-2 xs:rounded-l-none border-0"
          locale={locale}
          maxDate={!unsetTodayAsMaxDate ? today : undefined}
          minDate={filters.fromDate ?? undefined}
          onChange={newDate => setFilters({ ...filters, toDate: newDate })}
          placeholder={t('invoices.filters.to', { ns: 'account' }).toString()}
          value={filters.toDate}
        />
      </div>
    </div>
  );
};

export default FromToDatePicker;
