import { ButtonProps } from '@boss/types/b2b-b2c';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import ButtonOrAnchor from '../_Button';

const ButtonStyles = cva(
  'flex gap-3.75 w-fit h-fit items-center text-sm rounded-2 leading-[150%] px-5 py-1.5 transition duration-400 ease',
  {
    variants: {
      type: {
        primary: 'bg-blue-dark text-white hover:opacity-50',
        secondary: 'bg-white border-1  border-solid border-blue-dark hover:text-blue hover:border-blue text-blue-dark',
        tertiary: 'text-blue underline underline-offset-4 px-0 hover:text-blue-dark',
        link: 'text-blue-dark text-base underline underline-offset-4 px-0 hover:text-blue',
        mobileMenu: '',
      },
      disabled: {
        true: 'bg-gray px-5 pointer-events-none text-white no-underline',
      },
    },
  },
);

const Button = ({
  icon = faChevronRight,
  iconPosition,
  type,
  disabled,
  onClick,
  href,
  label,
  alternativeText,
  className,
  onKeyDown,
  hideLabel,
  testId,
  submitButton,
  anchorClassName,
  labelClassName,
  fileToDownload,
  trackInfo,
}: ButtonProps) => (
  <ButtonOrAnchor
    alternativeText={alternativeText}
    anchorClassName={anchorClassName}
    className={twMerge(ButtonStyles({ type, disabled }), className)}
    disabled={disabled}
    fileToDownload={fileToDownload}
    href={href}
    onClick={onClick}
    onKeyDown={onKeyDown}
    submitButton={submitButton}
    testId={testId}
    trackInfo={trackInfo}
  >
    {icon && (iconPosition === 'left' || iconPosition === 'both') && <FontAwesomeIcon icon={icon} />}
    {!hideLabel && label && <span className={twMerge('w-full', labelClassName)}>{label}</span>}
    {icon && (iconPosition === 'right' || iconPosition === 'both') && <FontAwesomeIcon icon={icon} />}
  </ButtonOrAnchor>
);

export default Button;
