import { order } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false, authScope: process.env.BOSS_API_AUTH_SCOPE_ORDER };

export const getOrderById = (orderId: string, locale: string) => {
  return bossApi<order.Order>(`/order/v2/orders/${orderId}`, {
    ...DEFAULT_OPTIONS,
    method: 'GET',
    locale,
  });
};

export const createOrder = async (
  QueryParams: {
    basketid: string;
    paymenttype: string;
    payreference: string;
    paytimestamp: string;
    amount?: string;
  },
  locale: string,
) => {
  const filteredQueryParams = Object.fromEntries(
    Object.entries(QueryParams).filter(([, value]) => value !== undefined),
  );

  return bossApi<order.Order>(`/order/v2/orders?${new URLSearchParams(filteredQueryParams).toString()}`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    locale,
  });
};
