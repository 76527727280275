import * as Sentry from '@sentry/nextjs';

import { PAYMENT_TYPE_UNION } from '@boss/services/client';

/**
 * Get payment status request
 *
 * @async
 * @returns {Promise}
 * @param payId
 */
const getPaymentStatus = async (payId: string, locale: string) => {
  try {
    const paymentRequest = await fetch(`/api/payment${payId}?locale=${locale}`, {
      method: 'POST',
    });

    return await paymentRequest.json();
  } catch (error) {
    console.error(error);

    Sentry.captureException(error, {
      tags: {
        type: 'Get payment status',
      },
    });

    throw error;
  }
};

/**
 * Create payment request
 *
 * @async
 * @returns {Promise}
 * @param type
 * @param locale
 * @param referenceId
 * @param amount
 * @param accountId
 * @param storeServiceId
 */
const createPaymentRequest = async ({
  type,
  locale,
  referenceId,
  amount,
  accountId,
  storeServiceId,
  invoiceId,
  description,
}: {
  type: PAYMENT_TYPE_UNION;
  locale: string;
  referenceId: string;
  amount: number;
  accountId?: string;
  storeServiceId?: string;
  invoiceId?: string;
  description?: string;
}) => {
  try {
    const paymentRequest = await fetch(`/api/payment?locale=${locale}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type: type,
        referenceId,
        locale: locale,
        amount: amount,
        accountId,
        storeServiceId,
        invoiceId,
        description,
      }),
    });

    return await paymentRequest.json();
  } catch (error) {
    console.error(error);

    Sentry.captureException(error, {
      tags: {
        type: 'Creating payment request',
      },
    });

    throw error;
  }
};

export { getPaymentStatus, createPaymentRequest };
