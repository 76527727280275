import { GoogleTagManager } from '@next/third-parties/google';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { createContext, useEffect, useRef } from 'react';

import { useEventTracker, useProfile } from '../../hooks';

type Props = {
  children: React.ReactNode;
  pageProps: AppProps['pageProps'];
  gtmId: string;
};

export const PagePropsContext = createContext(undefined);

const getLabel = (element: HTMLElement) => {
  return (
    element.innerText ||
    element.id ||
    (element instanceof HTMLAnchorElement ? element.href : null) ||
    (element.getAttribute('data-icon') ? `link icon: ${element.getAttribute('data-icon')}` : '')
  );
};

const getTrackInfo = (element: HTMLElement) => {
  const trackInfo = element.getAttributeNames().reduce((acc, attr) => {
    if (attr.startsWith('data-track-')) {
      const key = attr.replace('data-track-', '');

      acc[key] = element.getAttribute(attr) ?? '';
    }
    return acc;
  }, {} as Record<string, string>);

  return {
    event: trackInfo.customevent,
    data: Object.keys(trackInfo).reduce((acc, key) => {
      if (key !== 'customevent') {
        acc[key] = trackInfo[key];
      }
      return acc;
    }, {} as Record<string, string>),
  };
};

const GoogleTagManagerProvider = ({ children, pageProps, gtmId }: Props) => {
  const { status } = useProfile();
  const { trackPageView, trackLinkClick, trackCustomEvent } = useEventTracker();
  const router = useRouter();

  const isTracked = useRef<string>();

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // Type assertion to ensure target is an HTMLElement
      const target = event.target as HTMLElement;

      // Find the nearest button or link element, starting from the clicked element
      const clickableElement = target.closest('button, a, [role="link"]') as HTMLElement;

      if (clickableElement) {
        const isButton = clickableElement.tagName === 'BUTTON';
        const label =
          getLabel(clickableElement) ||
          (target?.getAttribute('data-icon') ? `link icon: ${target?.getAttribute('data-icon')}` : '');
        const ariaLabel = target.getAttribute('aria-label');

        const trackInfo = getTrackInfo(clickableElement);

        if (trackInfo.event) {
          trackCustomEvent(trackInfo.event, {
            ...trackInfo.data,
          });
        }

        trackLinkClick({
          label,
          ariaLabel,
          type: isButton ? 'button' : 'link',
          pageInfo: {
            slug: router.asPath,
            pageTitle: pageProps.pageTitle ?? router.asPath,
          },
        });
      }
    };

    document.addEventListener('click', handleClick);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  useEffect(() => {
    if (status === 'loading' || isTracked.current === router.asPath) {
      return;
    }

    trackPageView(pageProps.pageType);
    isTracked.current = router.asPath;
  }, [router.asPath, status, trackPageView, pageProps.pageType]);

  return (
    <PagePropsContext.Provider value={pageProps}>
      {gtmId && status !== 'loading' && <GoogleTagManager gtmId={gtmId} />}
      {children}
    </PagePropsContext.Provider>
  );
};

export default GoogleTagManagerProvider;
