import { useTranslation } from 'next-i18next';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch';
import { twMerge } from 'tailwind-merge';

import { FilterOrb } from '@boss/ui';

import Facets from '../Facets';

type Props = {
  className?: string;
  title?: string;
  attributes: string[];
  actionType?: 'refine' | 'navigate' | 'expand';
  expandableFacets?: boolean;
};

/**
 * From a refinement label in breadcrumb format, return the last substring
 * input: "foo > bar"
 * output: "bar"
 *
 * @param label - string
 * @returns  string
 */
const getRefinementLabel = (label: string) => {
  const strArray = label.split(' > ');

  return strArray[strArray.length - 1];
};

const FilterPanel = ({ className, title, attributes, actionType }: Props) => {
  const { t } = useTranslation();
  const facetTranslations: Record<string, string> = t('facets', { returnObjects: true });
  const attributeTranslations: Record<string, string> = t('attributes', { returnObjects: true });
  const { items: refinementGroups, refine: refineRefinement } = useCurrentRefinements();
  const { refine: removeFilters } = useClearRefinements();
  const activeRefinements = refinementGroups.map(refinementGroup => refinementGroup.refinements)?.flat();

  return (
    <div className={twMerge('flex flex-col gap-12', className)}>
      {title && <h1 className="text-highlightColor whitespace-nowrap first-letter:uppercase">{title}</h1>}

      {!!activeRefinements?.length && (
        <div className="relative flex flex-col gap-3">
          <div className="flex items-center justify-between">
            <span className="text-titleColor">{t('activeFilters')}</span>
            <button className="small text-blue-dark underline" onClick={removeFilters}>
              {t('removeFilters')}
            </button>
          </div>
          {activeRefinements.map(refinement => {
            const labelValue = getRefinementLabel(refinement.label);
            const label = facetTranslations[labelValue] ?? labelValue;

            return <FilterOrb key={refinement.value} label={label} onClick={() => refineRefinement(refinement)} />;
          })}
          <div className="h-0.25 bg-gray-light absolute -bottom-4 w-full" />
        </div>
      )}

      {!!attributes?.length && (
        <div className={'flex flex-col gap-10'}>
          {attributes.map(attribute => {
            const label = attributeTranslations[attribute] ?? t('categories');

            return <Facets actionType={actionType} attribute={attribute} collapsible key={attribute} label={label} />;
          })}
        </div>
      )}
    </div>
  );
};

export default FilterPanel;
