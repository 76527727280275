import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useScript } from 'usehooks-ts';

import { Button } from '@boss/ui';

type Props = {
  productId: string;
};

// Dynamic environment querying does not work in next. Keys need to be present in advance hence this mapping
const KEYS: Record<string, unknown> = {
  nl: process.env.NEXT_PUBLIC_ETRUSTED_WIDGET_KEY_NL_BE,
  fr: process.env.NEXT_PUBLIC_ETRUSTED_WIDGET_KEY_FR_BE,
  'nl-NL': process.env.NEXT_PUBLIC_ETRUSTED_WIDGET_KEY_NL_NL,
};

/**
 * Fetches a trusted shop key based on convention
 *
 * NEXT_PUBLIC_ETRUSTED_WIDGET_KEY_${locale.toUpperCase().replace('-', '_')
 */
const getTrustedShopsKey = (locale: string): string | null => {
  if (KEYS[locale]) {
    return String(KEYS[locale]);
  }

  return null;
};

const ProductReviewList = ({ productId }: Props) => {
  const { t } = useTranslation('product');
  const { locale } = useRouter();
  const status = useScript('https://integrations.etrusted.com/applications/widget.js/v2');
  const key = getTrustedShopsKey(locale || '');

  const REVIEW_POLICY_PATH = '/online-review-policy';

  const pagePolicyMap: Record<string, string> = {
    nl: REVIEW_POLICY_PATH,
    fr: REVIEW_POLICY_PATH,
    'nl-NL': REVIEW_POLICY_PATH,
  };

  if (status === 'ready' && key) {
    return (
      <>
        <h3>{t('productReviewList.title')}</h3>
        <div className="mb-10 mt-5 flex flex-row items-center gap-2">
          {t('productReviewList.policy')}
          <Button
            className="text-dark-grey"
            href={pagePolicyMap[locale as string]}
            label={t('productReviewList.button')}
            type="link"
          />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: `<etrusted-widget data-etrusted-widget-id=${key} data-sku=${productId} />`,
          }}
        />
      </>
    );
  }

  return null;
};

export default ProductReviewList;
