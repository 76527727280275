import { trackEvents } from '@boss/constants/shared';
import { useRouter } from '@boss/hooks';
import { IContentItem } from '@boss/services';
import { ISearchObject } from '@boss/types/b2b-b2c';
import { PageCard } from '@boss/ui';
import { getPagePathByPageType } from '@boss/utils';

import CommonSearchGrid from './commonSearchGrid';

type Props = {
  indexName: string;
  onUpdateTotalResults: (results: number | null) => void;
  query?: string;
};

const ContentPageSearch = ({ indexName, onUpdateTotalResults: handleResultsUpdate, query }: Props) => {
  const { locale } = useRouter();

  return (
    <CommonSearchGrid<IContentItem & ISearchObject>
      indexName={indexName}
      onUpdateTotalResults={handleResultsUpdate}
      renderCard={(page, index) => {
        const slug = getPagePathByPageType(page.slug, locale, 'contentPage', page?.tags);

        return (
          <PageCard
            {...page}
            imageSrc={page.mainImage?.src}
            slug={slug}
            trackInfo={{
              customEvent: trackEvents.SEARCH_RESULT_SELECTED,
              searchIndex: 'contentPages',
              resultName: page.title,
              searchPosition: index + 1,
              searchQuery: query ?? '',
            }}
          />
        );
      }}
      sortOptions={[]}
    />
  );
};

export default ContentPageSearch;
