import { useTranslation } from 'react-i18next';

import { FormType, useFormField, useFormSubmit } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

type Props = {
  type: FormType;
};

const ColorSupportLabForm = ({ type }: Props) => {
  const { onSubmit, isSubmitting, isSuccess, reset } = useFormSubmit();
  const { clientnumber, name, email, phonenumber, termsandconditions, contactname, firstname, lastname } =
    useFormField();
  const { t } = useTranslation('forms');
  const formFieldsTranslations: Record<string, string> = t('fields', { returnObjects: true });

  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...name,
      name: 'companyname',
      required: true,
    },
    {
      ...phonenumber,
    },
    email,
    {
      ...contactname,
      label: formFieldsTranslations.contactName,
    },
    {
      label: formFieldsTranslations.preferenceDate,
      name: 'preferenceDate',
      type: 'date',
      required: true,
      placeholder: 'Kies jouw startdatum',
    },
    {
      label: formFieldsTranslations.colors,
      name: 'orderinfo',
      type: 'textarea',
      required: true,
    },
    {
      label: formFieldsTranslations.message,
      name: 'message',
      type: 'textarea',
      required: false,
    },
    termsandconditions,
  ];

  const handleSubmit = (vals: FormValues) => {
    onSubmit(type, {
      ...vals,
      firstname: firstname.initialValue as string,
      lastname: lastname.initialValue as string,
    });
  };

  return (
    <DynamicForm
      fields={buildFormFields(baseFields)}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onSubmit={handleSubmit}
      variant="light"
    />
  );
};

export default ColorSupportLabForm;
