import Quagga from '@ericblade/quagga2';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useHits } from 'react-instantsearch';

import { productDetailPageBase } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { ISearchProduct } from '@boss/types/b2b-b2c';
import { Alert, Modal, Select } from '@boss/ui';
import { slugify } from '@boss/utils';

import Scanner from './Scanner';
import VirtualSearchBox from '../Algolia/VirtualSearchBox';

const BarcodeScannerModal = ({ onClose: handleClose, indexName }: { onClose: () => void; indexName: string }) => {
  const [cameras, setCameras] = useState<{ deviceId: string; label: string; kind: string; groupId: string }[]>([]);
  const [cameraError, setCameraError] = useState(null);
  const scannerRef = useRef(null);
  const [scanResult, setScanResult] = useState<string>();
  const [selectedCameraId, setSelectedCameraId] = useState('');
  const { results: searchResults } = useHits<ISearchProduct>();
  const [foundProduct, setFoundProduct] = useState<ISearchProduct>();
  const { t } = useTranslation('common');
  const { push } = useRouter();

  const enableCamera = async () => {
    const enableCamera = async () => {
      await Quagga.CameraAccess.request(null, {});
    };
    const disableCamera = async () => {
      await Quagga.CameraAccess.release();
    };
    const enumerateCameras = async () => {
      const cameras = await Quagga.CameraAccess.enumerateVideoDevices();

      if (cameras?.length) {
        setSelectedCameraId(cameras[1].deviceId ?? cameras[0].deviceId);
      }

      return cameras;
    };

    enableCamera()
      .then(disableCamera)
      .then(enumerateCameras)
      .then(cameras => setCameras(cameras))
      .then(() => Quagga.CameraAccess.disableTorch()) // disable torch at start, in case it was enabled before and we hot-reloaded
      .catch(err => setCameraError(err));
    return () => disableCamera();
  };

  useEffect(() => {
    enableCamera();
  }, []);

  useEffect(() => {
    if (searchResults?.hits.length && searchResults.query && scanResult) {
      const products = searchResults.hits;
      const product = products.find(p => {
        return p.articles.some(a => a.barcodes?.includes(scanResult));
      });

      if (!product) {
        return;
      }

      const productSlug = `${product.seoTitle ? slugify(product.seoTitle) : slugify(product.name)}/${product.id}`;

      setFoundProduct(product);
      if (productSlug) {
        push(`/${productDetailPageBase}/${productSlug}`);
        handleClose();
      }
    }
  }, [searchResults, scanResult, handleClose, push]);

  return (
    <Modal onClose={handleClose}>
      <div className="flex flex-col gap-4 p-6">
        {cameraError ? (
          <Alert className="mb-4" type="error">
            {t('barcodeScanner.cameraError')}
          </Alert>
        ) : (
          <>
            {foundProduct && (
              <div className="mb-4 rounded-md bg-gray-100 p-4">
                <h3 className="mb-2 text-lg font-semibold">{t('barcodeScanner.result')}:</h3>
                <p className="text-xl font-bold">{foundProduct.name}</p>
              </div>
            )}
            <div className="relative overflow-hidden rounded-lg border-2 border-red-500" ref={scannerRef}>
              <canvas className="drawingBuffer absolute left-0 top-0 h-full w-full" />
              {selectedCameraId && (
                <Scanner cameraId={selectedCameraId} onDetected={setScanResult} scannerRef={scannerRef} />
              )}
            </div>
            <Select
              className="mt-4"
              label={t('barcodeScanner.selectCamera')}
              name="camera"
              onChange={e => setSelectedCameraId(e.target.value)}
              options={cameras.map(camera => ({ label: camera.label, value: camera.deviceId }))}
              value={selectedCameraId}
            />
          </>
        )}
      </div>
      <VirtualSearchBox indexName={indexName} query={scanResult ?? ''} />
    </Modal>
  );
};

export default BarcodeScannerModal;
