import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { toast } from 'sonner';

import { useRouter } from '@boss/hooks';
import { PAYMENT_TYPE_UNION } from '@boss/services/client';

import { createPaymentRequest, getPaymentStatus } from './connector';

/**
 * useMutation implementation to create payment request
 * @returns {object} An object with a mutate function and mutation status
 */
const useGetPaymentStatus = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return useMutation({
    mutationFn: async ({ payId }: { payId: string }) => {
      return getPaymentStatus(payId, locale);
    },
    onError: () => {
      toast.error(t('toast.getPaymentStatus.error.title'));
    },
  });
};

/**
 * useMutation implementation to create payment request
 * @returns {object} An object with a mutate function and mutation status
 */
const useCreatePaymentRequest = () => {
  const { t } = useTranslation('common');

  return useMutation({
    mutationFn: async ({
      type,
      locale,
      referenceId,
      amount,
      accountId,
      storeServiceId,
      invoiceId,
      description,
    }: {
      type: PAYMENT_TYPE_UNION;
      locale: string;
      referenceId: string;
      amount: number;
      accountId?: string;
      storeServiceId?: string;
      invoiceId?: string;
      description?: string;
    }) => {
      return createPaymentRequest({
        type,
        locale,
        referenceId,
        amount,
        accountId,
        storeServiceId,
        invoiceId,
        description,
      });
    },
    onError: () => {
      toast.error(t('toast.createPaymentRequest.error.title'));
    },
  });
};

export { useGetPaymentStatus, useCreatePaymentRequest };
