export { default as AccountPage } from './accountPage';
export { default as CategoryOverviewPage } from './categoryOverviewPage';
export { default as CategoryPage } from './categoryPage';
export { default as ColorOverviewPage } from './colorOverviewPage';
export { default as ColorToolOverviewPage } from './colorToolOverviewPage';
export { default as GoogleTagManagerProvider } from './googleTagManager';
export { default as InspirationDetailPage } from './inspirationDetailPage';
export { default as InspirationOverviewPage } from './inspirationOverviewPage';
export { default as LoginPage } from './loginPage';
export { default as OverviewPage } from './overviewPage';
export { default as PaintguidePage } from './paintguidePage';
export { default as PaintguideResultPage } from './paintguideResultsPage';
export { default as ProductDetailPage } from './productDetailPage';
export { default as RegisterPage } from './registerPage';
export { default as RoadmapDetailPage } from './roadmapDetailPage';
export { default as RoadmapOverviewPage } from './roadmapOverviewPage';
export { default as SearchPage } from './searchPage';
export { default as WorkshopPage } from './workshopPage';
export { default as notFoundPage } from './notFoundPage';
export { default as WorkshopCheckoutPage } from './workshopCheckoutPage';
