import { IProduct } from '@boss/services';
import { IAccount, IAppointment } from '@boss/services/client';
import {
  ContactInformationFields,
  ContentPageFields,
  FaqFields,
  OverviewFields,
  SectionFields,
} from '@boss/types/b2b-b2c';
import { TAccountPageType } from '@boss/utils';

import AdvicePage from './advices';
import ContactPage from './contact';
import ContactsPage from './contacts';
import DashboardPage from './dashboard';
import Discounts from './discounts';
import InfoPage from './info';
import InvoicePage from './invoices';
import ListsPage from './lists';
import OrdersPage from './orders';
import AccountPaintguideResults from './paintguideResults';
import Quotations from './quotation';
import { AccountNav } from '../../components/';
import { accountPageConfig } from '../../utils';

type Props = {
  locale: string;
  account: IAccount;
  appointments?: IAppointment[];
  activeTab: TAccountPageType;
  contactInfo: ContactInformationFields | null;
  popularThemePages?: (ContentPageFields | OverviewFields)[] | null;
  mostRecent: IProduct[];
  dashBoardSections?: SectionFields[] | null;
  setSubPage: (value: string) => void;
  faqTopics?: FaqFields[] | null;
};

const AccountPage = ({
  locale,
  account,
  activeTab,
  appointments,
  contactInfo,
  mostRecent,
  popularThemePages,
  dashBoardSections,
  setSubPage,
  faqTopics,
}: Props) => {
  const contactPageType = accountPageConfig.contactPageTypeToShow;

  return (
    <div>
      <div className="lg:grid-cols-account grid grid-cols-1 gap-5">
        <AccountNav currentPage={activeTab} locale={locale} />
        <div className="md:px-4.5 mt-5 px-0 md:mt-10 md:flex-1">
          {activeTab === 'INFO' && <InfoPage account={account} className="mb-20" />}
          {activeTab === 'DASHBOARD' && (
            <DashboardPage
              account={account}
              appointments={appointments}
              dashBoardSections={dashBoardSections}
              mostRecent={mostRecent}
            />
          )}
          {activeTab === 'ORDERS' && <OrdersPage />}
          {activeTab === 'CONTACT' && contactPageType === 'CONTACT' && (
            <ContactPage
              account={account}
              contactInfo={contactInfo}
              faqTopics={faqTopics}
              footerCards={popularThemePages}
              locale={locale}
            />
          )}
          {activeTab === 'ADVICE' && <AdvicePage />}
          {activeTab === 'CONTACT' && contactPageType === 'CONTACTS' && <ContactsPage locale={locale} />}
          {activeTab === 'INVOICES' && <InvoicePage />}
          {activeTab === 'PAINTGUIDES' && <AccountPaintguideResults locale={locale} />}
          {activeTab === 'DISCOUNT' && <Discounts />}
          {activeTab === 'QUOTATION' && <Quotations />}
          {['LIST', 'PROJECTS'].includes(activeTab) && <ListsPage setSubBreadcrumb={setSubPage} />}
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
