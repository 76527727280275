import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Image } from '..';
import Price from '../Price';

interface Props {
  className?: string;
  variant?: 'default' | 'light';
  imageSrc?: string | null;
  title: string;
  subtitle?: string;
  extra?: ReactNode;
  price: number;
  quantity?: number | null;
  salesQuantity?: number;
  salesUnit?: string;
  textClassName?: string;
  unit?: string;
  hideQuantityBadge?: boolean;
  hideUnit?: boolean;
  translations: {
    amount: string;
  };
}

export const TextStyling = cva('', {
  variants: {
    variant: {
      default: '',
      light: 'text-white',
    },
  },
});

const ArticleCardSimple = ({
  className,
  imageSrc,
  title,
  subtitle,
  extra,
  price,
  quantity,
  textClassName,
  variant = 'default',
  translations,
  salesQuantity,
  salesUnit,
  unit,
  hideUnit = false,
  hideQuantityBadge = false,
}: Props) => (
  <div className={twMerge('flex items-center gap-5', className)} data-testid="article-card">
    <div className="w-15 relative h-20">
      {imageSrc && <Image alt={title} className="object-contain" height={100} src={imageSrc} width={80} />}
      {!hideQuantityBadge && !!quantity && (
        <div className="bg-blue small absolute left-[80%] top-0 flex h-6 w-6 items-center justify-center rounded-full text-white">
          {quantity}
        </div>
      )}
    </div>

    <div className="flex w-full justify-between">
      <div className="flex max-w-[70%] flex-col gap-2">
        <span className={twMerge('h4 font-bold', TextStyling({ variant }), textClassName)}>{title}</span>
        {subtitle && <span className={twMerge(TextStyling({ variant }), textClassName)}>{subtitle}</span>}
        {extra && <>{extra}</>}
      </div>
      <div className="flex flex-col gap-2 text-right">
        <span className="flex flex-col md:flex-row md:gap-2">
          <span>
            <strong>{translations.amount}</strong>: {quantity}
            {!hideUnit && ` ${unit}`}
          </span>
          {salesQuantity && salesQuantity !== quantity && !hideUnit && (
            <span>{`(${salesQuantity / (quantity && quantity !== 0 ? quantity : 1)} ${salesUnit}/${unit})`}</span>
          )}
        </span>
        <span>
          {!!price && <Price className={`[&>*]:${twMerge(TextStyling({ variant }), textClassName)}`} value={price} />}
        </span>
      </div>
    </div>
  </div>
);

export default ArticleCardSimple;
