import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import Modal from '../Modal';

type Props = {
  title?: string;
  children: ReactNode;
  submitText?: string;
  form?: JSX.Element | null; // Accept a JSX element as the form prop
  showModal?: boolean;
  onSetShowModal?: (showModal: boolean) => void;
  noTitle?: boolean;
  noShadow?: boolean;
  hideEdit?: boolean;
};

const AddressBlockStyles = cva(
  'rounded-brand-sm md:min-h-30 relative z-0 h-full w-full pb-14 pl-6 pr-6 pt-10 [&>*]:break-all',
  {
    variants: {
      noShadow: {
        true: 'border-1 border-gray-light',
        false: 'shadow-lg',
      },
    },
    defaultVariants: {
      noShadow: false,
    },
  },
);

const AddressBlock = ({
  children,
  title,
  form,
  showModal,
  onSetShowModal: setShowModal,
  noTitle,
  noShadow,
  hideEdit,
}: Props) => {
  return (
    <>
      <div className="my-5 flex flex-col">
        {!noTitle && <h5 className="text-generic-blue mb-4 font-bold">{title}</h5>}
        <div
          className={AddressBlockStyles({
            noShadow,
          })}
        >
          {form && !hideEdit && setShowModal && (
            <button className="absolute right-4 top-5 cursor-pointer" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faPencil} size="lg" />
            </button>
          )}
          {children}
        </div>
      </div>
      {showModal && form && setShowModal && (
        <Modal className="md:p-15 px-4 py-10" onClose={() => setShowModal(false)}>
          {form}
        </Modal>
      )}
    </>
  );
};

export default AddressBlock;
