import { UrlObject } from 'url';

import { faBasketShopping, faHeart, faSquareList, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useLockedBody } from 'usehooks-ts';

import { basketPageSlugs, searchPageSlugs } from '@boss/constants/b2b-b2c';
import { useIsResponsive, useRouter } from '@boss/hooks';
import { LocalizedSlugs, Navigation } from '@boss/types/b2b-b2c';
import { MainNav } from '@boss/ui';
import { getAccountPageSlugByType } from '@boss/utils';

import { SearchMenuResultsContext, SearchMenuResultsProvider, useBasket, useLogin, useSuggestions } from '../../hooks';
import { accountPageConfig, basketPageConfig, headerVariant } from '../../utils/options';
import BarcodeScanner from '../BarcodeScanner';
import SearchHistory from '../SearchHistory';
import SearchResults from '../SearchResults';

type Props = {
  nav: Navigation;
  localizedSlugs: LocalizedSlugs;
  type?: 'default' | 'simple';
};

const Header = ({ nav, localizedSlugs, type = 'default' }: Props) => {
  const [searchValue, _setSearchValue] = useState('');
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false);
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);
  const { locale, asPath, push } = useRouter();
  const isMobile = useIsResponsive();
  const { amountOfItems } = useBasket();
  const { navigateToProtectedPage } = useLogin();
  const { suggestions, addSuggestion, clearSuggestions, removeSuggestion } = useSuggestions();

  const setSearchValue = (val: string) => {
    setShowSearchResults(val?.length > 2);
    _setSearchValue(val);
    addSuggestion(val);
  };

  const handleBasket = () => {
    const basketPagePath = '/' + basketPageSlugs[locale];

    if (basketPageConfig.needsLogin) {
      navigateToProtectedPage(basketPagePath);
    } else {
      push(basketPagePath);
    }
  };

  useLockedBody(isOpen, 'root');

  const handleMobileMenuClick = () => {
    setIsOpen(isOpen => !isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
    _setSearchValue('');
  };

  const buildSearchUrl = (val: string): UrlObject => ({
    pathname: locale ? searchPageSlugs[locale] : '/',
    query: { q: val },
  });

  const handleSearch = (value: string) => {
    _setSearchValue(value);
    setShowSearchSuggestions(false);
    push(buildSearchUrl(value));
  };

  useEffect(() => {
    if (!isMobile) {
      closeMenu();
    }
  }, [isMobile]);

  useEffect(() => {
    closeMenu();
  }, [asPath, locale]);

  // NOTE: Added safeguard to let ts play along
  if (!locale) {
    return null;
  }

  return (
    <SearchMenuResultsProvider>
      <SearchMenuResultsContext.Consumer>
        {({ searchResults }) => {
          return (
            <>
              <MainNav
                barcodeScanner={<BarcodeScanner />}
                basket={{
                  id: 'my-basket-id',
                  label: t('header.basket.label'),
                  onClick: handleBasket,
                  icon: faBasketShopping,
                  amount: amountOfItems,
                }}
                className={twMerge('z-nav relative')}
                closeMenu={closeMenu}
                extraNav={nav.headerExtraBarItems}
                favorite={{
                  id: 'mijn-wishlist-id',
                  label: t('header.lists.label'),
                  onClick: () =>
                    navigateToProtectedPage(
                      `/${getAccountPageSlugByType(accountPageConfig.listPageTypeToShow, locale)}`,
                    ),
                  icon: headerVariant === 'primary' ? faHeart : faSquareList,
                }}
                handleMobileMenuClick={handleMobileMenuClick}
                highlightedCta={nav.highlightedCta?.[0]}
                isOpen={isOpen}
                localizedSlugs={localizedSlugs}
                logo={nav.headerLogo}
                mainNav={nav.headerNavigationItems}
                onCloseSearchMenu={() => setShowSearchResults(false)}
                onSearch={() => handleSearch(searchValue?.trim())}
                onShowSearchSuggestions={setShowSearchSuggestions}
                personal={{
                  id: 'mijn-colora-id',
                  label: t('header.myColora.label') as string, // Casting to string due to weird type issue regarding DefaultTFuncReturn
                  onClick: () => navigateToProtectedPage(),
                  icon: faUserCircle,
                }}
                searchLabel={t('header.search.label')}
                searchResults={showSearchResults ? searchResults : []}
                searchValue={searchValue}
                secondaryNavItems={nav.secondaryCtas}
                setSearchValue={setSearchValue}
                showSearchSuggestions={showSearchSuggestions}
                suggestions={
                  suggestions?.length ? (
                    <SearchHistory
                      onClear={clearSuggestions}
                      onRemove={removeSuggestion}
                      onSelect={handleSearch}
                      suggestions={suggestions}
                      translations={{
                        title: t('header.searchSuggestions.title'),
                        clearAll: t('header.searchSuggestions.clearAll'),
                      }}
                    />
                  ) : undefined
                }
                translations={{
                  menuLabel: t('header.menu.label'),
                }}
                type={type}
                variant={headerVariant}
              />
              {searchValue && showSearchResults && <SearchResults query={searchValue} />}
            </>
          );
        }}
      </SearchMenuResultsContext.Consumer>
    </SearchMenuResultsProvider>
  );
};

export default Header;
