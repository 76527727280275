import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import * as Yup from 'yup';

import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { useContacts, useIncidentTypes, useWorkSitesDetail } from '../../client-queries';
import { FormType, useFormField, usePointOfSale, useProfile, useServiceRequest } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const FORM_FIELD_KEYS = [
  'clientnumber',
  'companyname',
  'firstname',
  'lastname',
  'incidenttype',
  'preferenceDate',
  'deliverymethod',
  'new',
  'deliverywarehouseid',
  'worksite',
  'deliverydate',
  'message',
  'termsandconditions',
] as const;

const DELIVERY_OPTION_KEYS = ['deliverywarehouseid', 'worksite', 'address'] as const;

type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};
type DeliveryOptionKey = (typeof DELIVERY_OPTION_KEYS)[number];

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

type Address = {
  street: string;
  streetnumber: string;
  zipcode: string;
  city: string;
  postbox: string;
  country: string;
};

const excludedFieldKeysMap: Record<DeliveryOptionKey, string[]> = {
  deliverywarehouseid: ['worksite', 'address', 'new'],
  worksite: ['deliverywarehouseid', 'address', 'new'],
  address: ['deliverywarehouseid', 'worksite'],
};

const MachineForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type,
}: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useServiceRequest();
  const { t } = useTranslation('forms');
  const chooseTranslation = t('select.choose');
  const { locale } = useRouter();
  const { stores } = usePointOfSale();
  const { data: contacts } = useContacts(locale);

  const { data: profile } = useProfile();
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const {
    clientnumber,
    termsandconditions,
    street,
    streetnumber,
    bus,
    zipcode,
    city,
    country,
    firstname,
    lastname,
    companyname,
  } = useFormField();
  const { data: worksites } = useWorkSitesDetail(locale);

  const { data: incidentTypes } = useIncidentTypes('Equipment Rental');
  const storeOptions = stores.map(store => ({ value: store.id, label: store.name }));

  storeOptions.unshift({ value: '', label: chooseTranslation });

  const deliveryMethodOptions = DELIVERY_OPTION_KEYS.map(key => ({
    value: key,
    label: t(`select.machineDeliveryOptions.${key}`),
  }));

  const today = new Date();

  const [deliveryMethod, setDeliveryMethod] = useState(deliveryMethodOptions[0].value);
  const [address, setAddress] = useState<Address>();
  const [chosenWorksite, setChosenWorksite] = useState<string>('');

  const worksiteOptions =
    worksites?.map((worksite, index) => ({
      value: index.toString(),
      label: `${worksite.street} ${worksite.streetnumber}, ${worksite.zipcode} ${worksite.city}`,
    })) ?? [];

  worksiteOptions.unshift({ value: '', label: chooseTranslation });

  const incidentOptions =
    incidentTypes?.map(incidentType => ({
      value: incidentType.id,
      label: incidentType.name,
    })) ?? [];

  incidentOptions.unshift({ value: '', label: chooseTranslation });

  const shouldShowField = (fieldName: string) => {
    const excludedKeys = excludedFieldKeysMap[deliveryMethod];

    return !excludedKeys.includes(fieldName);
  };

  const customRequiredFieldValidation = (fieldName: string, customFieldName?: string) => {
    return Yup.string().test(
      'required',
      t('errors.required', { value: t(`fields.${customFieldName ?? fieldName}`) }) ?? '',
      function (value) {
        const shouldShow = shouldShowField(fieldName);

        return !shouldShow || (shouldShow && !!value);
      },
    );
  };

  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...companyname,
      disabled: true,
    },
    {
      ...firstname,
      initialValue: contact?.firstname ?? '',
      disabled: true,
    },
    {
      ...lastname,
      initialValue: contact?.lastname ?? '',
      disabled: true,
    },
    {
      name: 'incidenttype',
      type: 'select',
      // add an empty option to the beginning of the list
      options: incidentOptions,
    },
    {
      name: 'deliverymethod',
      type: 'select',
      options: deliveryMethodOptions,
      initialValue: deliveryMethodOptions[0]?.value,
      required: false,
      validation: customRequiredFieldValidation('deliverymethod'),
    },
    {
      name: 'deliverywarehouseid',
      type: 'select',
      options: storeOptions,
      label: t('fields.store'),
      required: false,
      validation: customRequiredFieldValidation('deliverywarehouseid'),
    },
    {
      name: 'worksite',
      type: 'select',
      options: worksiteOptions,
      disclaimer: <span className="small italic">{t('disclaimers.worksite')}</span>,
      required: false,
      validation: customRequiredFieldValidation('worksite'),
    },
    {
      name: 'new',
      type: 'address',
      fields: [street, streetnumber, bus, zipcode, city, country],
      colStyle: 'md:col-span-6',
      required: false,
    },
    {
      name: 'preferenceDate',
      type: 'date',
      initialValue: today.toISOString().split('T')[0] + 'T12:00:00',
      disclaimer: t('disclaimers.preferenceDate'),
    },
    {
      name: 'message',
      type: 'textarea',
      label: t('fields.questionHelp'),
      required: false,
    },
    termsandconditions,
  ];

  const setValueOrEmptyString = (value: string | number): string => {
    return value?.toString() ?? '';
  };

  const checkAddressFields = (values: FormValues) => {
    return values.street || values.streetnumber || values.zipcode || values.city || values.country || values.bus;
  };

  const checkForNewAddressValues = (values: FormValues, address: Address | undefined) => {
    return (
      values.street !== address?.street ||
      values.streetnumber !== address?.streetnumber ||
      values.zipcode !== address?.zipcode ||
      values.city !== address?.city ||
      values.country !== address?.country ||
      values.bus !== address?.postbox
    );
  };

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    const newDeliveryMethod = DELIVERY_OPTION_KEYS.find(type => type === formik.values.deliverymethod);

    if (newDeliveryMethod === 'worksite' && formik.values.worksite !== chosenWorksite) {
      setChosenWorksite(formik.values.worksite);
      // Since selected worksite is a string (because of limited functionality in the select input), we need to convert it to a number
      const foundWorksite = worksites?.[parseInt(formik.values.worksite)];

      if (foundWorksite) {
        const newAddress = {
          street: setValueOrEmptyString(foundWorksite?.street),
          streetnumber: setValueOrEmptyString(foundWorksite?.streetnumber),
          zipcode: setValueOrEmptyString(foundWorksite?.zipcode),
          city: setValueOrEmptyString(foundWorksite?.city),
          country: setValueOrEmptyString(foundWorksite?.country),
          postbox: setValueOrEmptyString(foundWorksite?.postbox),
        };

        if (checkForNewAddressValues(newAddress, address)) {
          setAddress(newAddress);
        }
      }
    } else if (
      newDeliveryMethod === 'address' &&
      checkAddressFields(formik.values) &&
      checkForNewAddressValues(formik.values, address)
    ) {
      setAddress({
        street: setValueOrEmptyString(formik.values.street),
        streetnumber: setValueOrEmptyString(formik.values.streetnumber),
        zipcode: setValueOrEmptyString(formik.values.zipcode),
        city: setValueOrEmptyString(formik.values.city),
        country: setValueOrEmptyString(formik.values.country),
        postbox: setValueOrEmptyString(formik.values.bus),
      });
    }

    if (newDeliveryMethod && deliveryMethod !== newDeliveryMethod) {
      setDeliveryMethod(newDeliveryMethod);
    }
  };

  const handleSubmit = (vals: FormValues) => {
    let submitObject = {
      ...vals,
    };

    if (address && vals.deliverymethod !== 'deliverywarehouseid') {
      submitObject = {
        ...submitObject,
        ...address,
        deliverymethod: 'address',
      };
    } else {
      const store = stores.find(store => store.id === vals.deliverywarehouseid);

      submitObject = {
        ...submitObject,
        street: '',
        streetnumber: '',
        zipcode: '',
        city: '',
        country: '',
        bus: '',
        deliverymethod: store?.storetype === 'Colora' ? 'colorastore' : 'bossdepot',
      };
    }

    onSubmit(type, submitObject);
  };

  const formFields = buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite);

  return (
    <DynamicForm
      buttonProps={{
        label: t('buttons.submitQuestion') ?? '',
      }}
      className={className}
      disclaimer={t('disclaimers.machinerentaldisclaimer')}
      fields={formFields}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onFormValuesChange={handleFormValuesChange}
      onSubmit={handleSubmit}
      shouldShowField={shouldShowField}
      variant="light"
    />
  );
};

export default MachineForm;
