import Quagga from '@ericblade/quagga2';
import {
  QuaggaJSCodeReader,
  QuaggaJSResultCallbackFunction,
  QuaggaJSResultObject,
  QuaggaJSResultObject_CodeResult,
} from '@ericblade/quagga2';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

type ScannerProps = {
  onDetected: (code: string) => void;
  scannerRef: React.RefObject<HTMLDivElement>;
  onScannerReady?: () => void;
  cameraId?: string;
  facingMode?: string;
  constraints?: {
    width?: number;
    height?: number;
  };
  locator?: {
    patchSize: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
    halfSample: boolean;
    willReadFrequently: boolean;
  };
  decoders?: QuaggaJSCodeReader[];
  locate?: boolean;
};

const getMedian = (arr: number[]): number => {
  const newArr = [...arr].sort((a, b) => a - b);
  const half = Math.floor(newArr.length / 2);

  return newArr.length % 2 === 1 ? newArr[half] : (newArr[half - 1] + newArr[half]) / 2;
};

const getMedianOfCodeErrors = (codeResults: QuaggaJSResultObject_CodeResult): number => {
  const errors = codeResults.decodedCodes.flatMap(x => x.error ?? 0);

  return getMedian(errors);
};

const defaultConstraints = {
  width: 1480,
  height: 1640,
};

const defaultLocatorSettings = {
  patchSize: 'medium' as const,
  halfSample: true,
  willReadFrequently: true,
  numOfWorkers: typeof navigator !== 'undefined' ? navigator?.hardwareConcurrency ?? 4 : 4,
  frequency: 20,
};

const defaultDecoders: QuaggaJSCodeReader[] = [
  'ean_reader',
  'code_128_reader',
  'ean_8_reader',
  'code_39_reader',
  'upc_reader',
  'upc_e_reader',
];

const Scanner = ({
  onDetected,
  scannerRef,
  onScannerReady,
  cameraId,
  facingMode,
  constraints = defaultConstraints,
  locator = defaultLocatorSettings,
  decoders = defaultDecoders,
  locate = true,
}: ScannerProps) => {
  const [lastDetectedCode, setLastDetectedCode] = useState<string | null>(null);
  const detectionCountRef = useRef<{ [key: string]: number }>({});
  const lastDetectionTimeRef = useRef<number>(0);

  const errorCheck: QuaggaJSResultCallbackFunction = useCallback(
    (result: QuaggaJSResultObject) => {
      if (!onDetected) {
        return;
      }

      const err = getMedianOfCodeErrors(result.codeResult);
      const currentTime = Date.now();

      if (err < 0.1) {
        const detectedCode = result.codeResult.code ?? '';

        detectionCountRef.current[detectedCode] = (detectionCountRef.current[detectedCode] || 0) + 1;

        if (
          detectionCountRef.current[detectedCode] >= 3 &&
          detectedCode !== lastDetectedCode &&
          currentTime - lastDetectionTimeRef.current > 1000
        ) {
          setLastDetectedCode(detectedCode);
          onDetected(detectedCode);
          detectionCountRef.current = {};
          lastDetectionTimeRef.current = currentTime;
        }
      }
    },
    [onDetected, lastDetectedCode],
  );

  const handleProcessed: QuaggaJSResultCallbackFunction = result => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (!drawingCtx || !drawingCanvas) {
      return;
    }

    drawingCtx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);

    if (result) {
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: 'red', lineWidth: 4 });
      }
      if (result.codeResult && result.codeResult.code) {
        drawingCtx.font = '24px Arial';
        drawingCtx.fillStyle = 'green';
        drawingCtx.fillText(result.codeResult.code, 10, 20);
      }
    }
  };

  useLayoutEffect(() => {
    let ignoreStart = false;
    const init = async () => {
      if (ignoreStart) {
        return;
      }

      try {
        await Quagga.init(
          {
            inputStream: {
              type: 'LiveStream',
              constraints: {
                ...constraints,
                ...(cameraId && { deviceId: cameraId }),
                ...(!cameraId && { facingMode }),
              },
              target: scannerRef.current as HTMLElement,
            },
            locator,
            decoder: {
              readers: decoders,
              multiple: false,
            },
            locate,
          },
          async err => {
            if (err) {
              console.error('Error starting Quagga:', err);
              return;
            }

            Quagga.onProcessed(handleProcessed);

            if (scannerRef.current) {
              await Quagga.start();
              onScannerReady?.();
            }
          },
        );

        Quagga.onDetected(errorCheck);
      } catch (error) {
        console.error('Error initializing Quagga:', error);
      }
    };

    init();

    return () => {
      ignoreStart = true;
      Quagga.stop();
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
    };
  }, [
    cameraId,
    onDetected,
    onScannerReady,
    scannerRef,
    errorCheck,
    constraints,
    locator,
    decoders,
    locate,
    facingMode,
  ]);

  return <div className="h-full w-full" ref={scannerRef} />;
};

export default Scanner;
