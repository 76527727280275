import { cva } from 'class-variance-authority';
import { ComponentProps } from 'react';

import Datepicker from '../Datepicker';
import FormFieldWrapper from '../FormFieldWrapper';
import InputField from '../InputField';

type InputFieldProps = ComponentProps<typeof InputField>;

type Props = Omit<InputFieldProps, 'type' | 'onChange'> & {
  onChange: (val: string) => void;
  locale: string;
};

const InputFieldStyles = cva(
  'bg-white rounded-brand-xs border-0 text-gray-dark w-full ring-inset ring-1 focus:ring-2 focus:ring-inset focus:ring-gray-light',
  {
    variants: {
      error: {
        true: '!ring-error !text-error !focus:ring-error',
      },
      variant: {
        light: 'ring-gray-light',
        dark: '!bg-brown-light ring-transparent',
        transparent: 'ring-transparent',
      },
    },
  },
);

const DateField = ({
  className,
  error,
  touched,
  name,
  label,
  variant,
  ref,
  disclaimer,
  required,
  tooltip,
  id,
  onChange,
  value = '',
  locale,
  ...props
}: Props) => (
  <FormFieldWrapper
    className={className}
    disclaimer={disclaimer}
    error={error}
    id={id}
    label={label}
    name={name}
    required={required}
    tooltip={tooltip}
    touched={touched}
  >
    <Datepicker
      {...props}
      className={InputFieldStyles({ variant, error: !!error })}
      inputClassName="rounded-none px-3 py-2.5"
      isoFormat
      locale={locale}
      onChange={onChange}
      value={value}
    />
  </FormFieldWrapper>
);

export default DateField;
