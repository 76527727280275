import { ButtonProps } from '@boss/types/corporate-jobs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import ButtonOrAnchor from '../_Button';

const ButtonStyles = cva(
  'flex gap-2.5 h-fit items-center uppercase text-md leading-[15px] px-3.25 py-3.25 transition duration-400 ease',
  {
    variants: {
      type: {
        primary:
          'bg-brand border-1 border-transparent border-solid text-white hover:text-brand hover:bg-white hover:border-1 hover:border-solid hover:border-brand',
        secondary: 'text-black border-1 border-solid border-black hover:bg-black hover:text-white',
        tertiary: 'bg-white text-brand hover:text-white hover:bg-brand',
        link: 'text-black pb-3.25 border-b-1 border-green px-0 hover:text-green',
        mobileMenu:
          'bg-white text-black hover:bg-black hover:text-white hover:border-1 hover:border-solid hover:border-white',
      },
      disabled: {
        true: 'bg-black px-3.25 pointer-events-none text-white no-underline opacity-50',
      },
      onDark: {
        primary: '',
        secondary: 'text-white border-1 border-solid border-black hover:bg-white hover:text-black',
        tertiary: '',
        link: 'text-white pb-3.25 border-b-1 border-green px-0 hover:text-green',
        mobileMenu: '',
        false: '',
      },
    },
  },
);

const Button = ({
  alternativeText,
  anchorClassName,
  className,
  disabled,
  fileToDownload,
  hideLabel,
  href,
  icon,
  iconPosition = 'left',
  label,
  labelClassName,
  onClick,
  onDark,
  onKeyDown,
  submitButton,
  testId,
  trackInfo,
  type,
}: ButtonProps) => (
  <ButtonOrAnchor
    alternativeText={alternativeText}
    anchorClassName={anchorClassName}
    className={twMerge(ButtonStyles({ type, disabled, onDark: onDark ? type : false }), className)}
    disabled={disabled}
    fileToDownload={fileToDownload}
    href={href}
    onClick={onClick}
    onKeyDown={onKeyDown}
    submitButton={submitButton}
    testId={testId}
    trackInfo={trackInfo}
  >
    {icon && iconPosition === 'left' && <FontAwesomeIcon icon={icon} />}
    {!hideLabel && label && <span className={twMerge('w-full', labelClassName)}>{label}</span>}
    {icon && iconPosition === 'right' && <FontAwesomeIcon icon={icon} />}
  </ButtonOrAnchor>
);

export default Button;
