import { useMemo } from 'react';

import { useProductMap } from '..';
import { useArticlesByIds, useGetOrderById, useProductsById } from '../../client-queries';
import { COLOR_TYPE } from '../../constants';

const useOrderDetail = (orderId: string) => {
  const {
    data: order,
    isLoading: orderLoading,
    isError: orderError,
    isSuccess: orderLoaded,
  } = useGetOrderById(orderId);
  const { mapProductSlug } = useProductMap();

  const orderItemIds = useMemo(() => {
    const itemIds = order?.lines?.filter(line => line.itemId).map(line => line.itemId);

    return [...new Set(itemIds ?? [])];
  }, [order]);

  /*
   * Todo: remove the article fetching step once the productId is available in the order lines
   */
  const { data: articles, isSuccess: articlesLoaded, isLoading: articlesLoading } = useArticlesByIds(orderItemIds);

  const uniqueProductIds = articlesLoaded
    ? [
        ...new Set(
          articles
            ?.reduce((prev: string[], article) => (article.productId ? [...prev, article.productId] : prev), [])
            .flat(),
        ),
      ]
    : [];

  const { data: products } = useProductsById(uniqueProductIds, orderLoaded);

  const mappedOrderLines = useMemo(() => {
    return (
      order?.lines.map(line => {
        /*
         * Todo: get productId from the order line instead of the fetched articles (see above todo)
         */
        const productId = articles?.find(product => product?.id === line.itemId);
        const product = products?.find(product => product?.id === productId?.productId);

        const slug = product
          ? mapProductSlug({
              productName: product?.name ?? '',
              id: product?.id ?? '',
              seoTitle: product?.seoTitle,
              colorId: line?.colorId,
              colorGroups: line?.colorTypeGroups,
            })
          : '';

        return {
          ...line,
          name: line?.name ?? '',
          id: line.itemId,
          productId: product?.id ?? '',
          colorCode: line?.colorCode ?? '',
          colorId: line.colorId,
          slug,
          myMx: line?.colorTypeGroups.includes(COLOR_TYPE.MYMX),
        };
      }) ?? []
    );
  }, [order, products, articles, mapProductSlug]);

  return {
    order,
    orderLines: mappedOrderLines,
    isLoading: orderLoading || articlesLoading,
    isError: orderError,
  };
};

export { useOrderDetail };
