import { useTranslation } from 'next-i18next';
import { useContext, useEffect } from 'react';

import { trackEvents } from '@boss/constants/shared';
import { ProductCard } from '@boss/ui';

import { useAccountPriceInfoByProductId } from '../../client-queries';
import { PagePropsContext } from '../../containers/googleTagManager';
import { useEventTracker, useFavorites, usePrice, useProductMap, useProfile } from '../../hooks';

const ProductCardWrapper = ({ price, ...props }: React.ComponentProps<typeof ProductCard>) => {
  const { t } = useTranslation('common');
  const { favorites, toggleFavorite } = useFavorites();
  const { showPrice } = usePrice();
  const { data: accountPriceInfo, isLoading: priceInfoLoading } = useAccountPriceInfoByProductId(props.id);

  const priceToShow = accountPriceInfo?.prices?.fromPriceAmount || price;
  const { trackCustomEvent } = useEventTracker();
  const pageProps = useContext(PagePropsContext);
  const { mapProductLabels } = useProductMap();
  const { isLoggedIn } = useProfile();

  useEffect(() => {
    const trackingProps = {
      productId: props.id,
      productName: props.title,
      productCategory: props.trackInfo?.category,
      pageInfo: pageProps,
      seoTitle: props.trackInfo?.seoTitle,
    };

    trackCustomEvent(trackEvents.PRODUCT_DISPLAYED, trackingProps);
    // to avoid firing multiple events
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labels =
    props.labels ?? accountPriceInfo?.labels
      ? [...mapProductLabels(accountPriceInfo?.labels ?? []), ...(props.labels ?? [])]
      : undefined;

  return (
    <ProductCard
      {...props}
      handleAddToWishlist={() => toggleFavorite('PRODUCTS', props.id)}
      inWishlist={favorites['PRODUCTS']?.includes(props.id)}
      labels={labels}
      price={showPrice ? priceToShow : undefined}
      priceIsLoading={isLoggedIn ? priceInfoLoading : false}
      translations={{
        quantityPrefix: props.translations?.quantityPrefix ?? t('quantityPrefix'),
        disclaimer: props.translations?.disclaimer ?? t('fromPriceDisclaimer'),
        unitPrice: accountPriceInfo?.prices?.fromPriceUnit
          ? t(`fromPriceUnits.${accountPriceInfo?.prices?.fromPriceUnit}`) ?? ''
          : '',
      }}
    />
  );
};

export default ProductCardWrapper;
