import { cva } from 'class-variance-authority';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import Button from '../Button';
import Price from '../Price';

type Translations = {
  invoice: string;
  amount: string;
  dueDate: string;
  transactionDate: string;
  dueDateExpired: string;
  tomorrow: string;
  payAction: string;
  payPending: string;
  downloadAction: string;
  payed: string;
  amountTooHigh: string;
};

export interface InvoiceProps {
  invoiceNumber: string;
  transactionDate: Date;
  dueDate: Date;
  amount: number;
  translations: Translations;
  isCompleted?: boolean;
  isExpired?: boolean;
  paymentPending: boolean;
  onHandlePayment?: () => void;
  onHandleDownload?: () => void;
}

export const dueDateLabelStyle = cva('', {
  variants: {
    expired: {
      true: 'text-red',
    },
  },
});

const DueDate = ({
  dueDate,
  isExpired,
  translations,
}: {
  dueDate: Date;
  isExpired: boolean;
  translations: Translations;
}) => {
  const today = new Date();
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  const formattedDueDate = format(dueDate, 'dd/MM/yyyy', { locale: nl });

  const dueDateIsTommorow =
    tomorrow.getFullYear() === dueDate.getFullYear() &&
    tomorrow.getMonth() === dueDate.getMonth() &&
    tomorrow.getDate() === dueDate.getDate();

  return (
    <div>
      {translations[isExpired ? 'dueDateExpired' : 'dueDate']}:{' '}
      <strong className={dueDateLabelStyle({ expired: isExpired || dueDateIsTommorow })}>
        {dueDateIsTommorow ? translations.tomorrow : formattedDueDate}
      </strong>
    </div>
  );
};

export const Invoice = ({
  invoiceNumber,
  transactionDate,
  dueDate,
  amount,
  isCompleted,
  isExpired,
  translations,
  paymentPending,
  onHandlePayment,
  onHandleDownload,
}: InvoiceProps) => {
  const onlinePaymentAllowed = amount <= 10000;
  const formattedTransactionDate = format(transactionDate, 'dd/MM/yyyy', { locale: nl });

  return (
    <div className="shadow-m flex-gap-5 flex flex-col gap-5 bg-white p-5">
      <div className="md:flex md:justify-between">
        <span className="text-blue-dark text-lg">
          {translations.invoice}: {invoiceNumber}
        </span>
        <span className="hidden md:inline-block">
          <span className="font-bold">{translations.transactionDate}:</span> {formattedTransactionDate}
        </span>
        <div className="md:hidden">
          <DueDate dueDate={dueDate} isExpired={!!isExpired} translations={translations} />
        </div>
      </div>
      <div className="md:hidden">
        <span className="font-bold">{translations.transactionDate}:</span> {formattedTransactionDate}
      </div>
      <div className="md:flex md:justify-between">
        <div className="flex items-center gap-1">
          <span className="text-xl">{translations.amount}:</span>
          <Price value={amount} variant="info" />
        </div>

        {onHandlePayment && !isCompleted && amount > 0 && (
          <Button
            className="w-auto px-10 py-3 sm:w-auto"
            disabled={paymentPending || !onlinePaymentAllowed}
            label={paymentPending ? translations.payPending : translations.payAction}
            onClick={onHandlePayment}
            type="primary"
          />
        )}
      </div>
      <div className="flex justify-between">
        <div className="hidden md:inline-block">
          {isCompleted ? (
            translations.payed
          ) : (
            <DueDate dueDate={dueDate} isExpired={!!isExpired} translations={translations} />
          )}
        </div>

        {onHandleDownload && <Button label={translations.downloadAction} onClick={onHandleDownload} type="tertiary" />}
      </div>
      {!onlinePaymentAllowed && <div className="text-blue text-sm">{translations.amountTooHigh}</div>}
    </div>
  );
};

export default Invoice;
