import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: true, authScope: process.env.BOSS_API_AUTH_SCOPE_ORDER };

export const updateInvoicePaymentStatus = async ({
  invoiceId,
  timeStamp,
  paid,
  paymentReference,
  locale,
}: {
  invoiceId: string;
  timeStamp: string;
  paid: boolean;
  paymentReference?: string;
  locale: string;
}) => {
  return bossApi(`/order/v2/invoices/${invoiceId}`, {
    ...DEFAULT_OPTIONS,
    locale,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    body: [
      {
        path: 'invoiceonlinepayed',
        op: 'replace',
        value: paid,
      },
      {
        path: 'paymentreference',
        op: 'replace',
        value: paymentReference,
      },
      {
        path: 'paymenttimestamp',
        op: 'replace',
        value: timeStamp,
      },
    ],
  });
};
