import { faBasketShopping, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/nextjs';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { SCREENS, basketPageSlugs, trackEvents } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { IProduct } from '@boss/services';
import { IArticle, ISearchProduct, SimpleColor } from '@boss/types/b2b-b2c';
import {
  Alert,
  ArticleCardSimple,
  Button,
  CarouselProducts,
  Modal,
  Orb,
  Presence,
  ProductCard,
  Skeleton,
} from '@boss/ui';

import { useBasket, usePrice } from '../../hooks';
import { crossSellingModalConfig, mapBasketLine, productCardVariant } from '../../utils';
import { ArticleIdsObject } from '../ArticlesTable';

export const TopContentStyling = cva('p-5 md:p-10', {
  variants: {
    variant: {
      primary: 'bg-blue-light/30',
      secondary: 'bg-blue-dark',
    },
  },
});

export const RelatedHeaderStyling = cva('', {
  variants: {
    variant: {
      primary: 'h2 text-blue',
      secondary: 'h4 md:h2 font-bold',
    },
  },
});

export const LightTextStyling = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: 'text-white',
    },
  },
});

export const SubtitleStyling = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: 'md:h3 md:font-bold',
    },
  },
});

export const ProductCardStyling = cva('', {
  variants: {
    variant: {
      primary: 'bg-beige-light',
      secondary: '',
    },
  },
});

export const ContinueButtonStyling = cva('', {
  variants: {
    variant: {
      primary: 'text-textColor bg-white',
      secondary: 'border-1 border-white',
    },
  },
});

export const PayNowButtonStyling = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: 'text-blue-dark bg-white',
    },
  },
});

type Props = {
  articles: IArticle[] | null;
  className?: string;
  crossSellArticles: IArticle[];
  onClose: () => void;
  product: IProduct | ISearchProduct;
  selectedArticleIdsObject: ArticleIdsObject;
  selectedColor?: SimpleColor;
};

const CrossSellingModal = ({
  onClose,
  className,
  crossSellArticles,
  product,
  articles,
  selectedColor,
  selectedArticleIdsObject,
}: Props) => {
  const { variant } = crossSellingModalConfig;
  const { locale } = useRouter();
  const { showPrice } = usePrice();
  const { addBasketLine } = useBasket();
  const [hasError, setHasError] = useState(false);
  const [addedArticleIds, setAddedArticleIds] = useState<string[]>([]);
  const { t } = useTranslation('product');
  const amountOfArticles = Object.keys(selectedArticleIdsObject).length;

  const handleAddToCart = async (article: IArticle) => {
    try {
      await addBasketLine(mapBasketLine(article, { quantity: 1, unit: article.unit }));
      setAddedArticleIds(prevArticleIds => [...new Set([...prevArticleIds, article.id])]);

      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'CrossSellingModal Add to cart',
        },
      });
    }
  };

  return (
    <Modal
      className={twMerge(
        'max-w-10/10 md:max-h-8/10 md:max-w-8/10 left-0 max-h-[100vh] w-full p-0 md:left-5 md:w-auto md:p-0',
        className,
      )}
      closeButtonClassName={LightTextStyling({ variant })}
      onClose={onClose}
    >
      <div className={twMerge('flex flex-col gap-4', TopContentStyling({ variant }))}>
        <div className="md:gap-15 flex gap-4">
          <Orb className="flex items-center justify-center bg-white" size="lg">
            <FontAwesomeIcon className="text-blue" icon={faCheck} size="2xl" />
          </Orb>

          <div className="flex flex-col md:gap-5">
            <h1 className={twMerge('text-blue', LightTextStyling({ variant }))}>{t('crossSelling.title')}</h1>
            <span className={twMerge(LightTextStyling({ variant }), SubtitleStyling({ variant }))}>
              {t(`crossSelling.${amountOfArticles > 1 ? 'subtitlePlural' : 'subtitle'}`)}
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          {Object.entries(selectedArticleIdsObject).map(([articleId, articleValues]) => {
            const { quantity, unit } = articleValues;
            const article = articles?.find(x => x.id === articleId);
            const unitTranslation = t(`unit.${unit}${quantity > 1 ? 'Plural' : ''}`) ?? '';

            if (!article || quantity === 0) {
              return null;
            }

            return (
              <ArticleCardSimple
                imageSrc={product.packshot.src}
                key={article.id}
                price={article.pricing?.price}
                subtitle={
                  selectedColor
                    ? `${t('crossSelling.card.color')}: ${selectedColor?.code ?? ''} ${selectedColor?.name ?? ''}`
                    : undefined
                }
                title={`${quantity} ${unitTranslation} ${article.name ?? product.name ?? ''}`}
                translations={{
                  amount: t('amount', { ns: 'common' }),
                }}
                variant={variant === 'secondary' ? 'light' : 'default'}
              />
            );
          })}
        </div>

        <div className="flex flex-wrap justify-center gap-2 md:justify-end md:gap-4">
          <Button
            className={ContinueButtonStyling({ variant })}
            label={t('crossSelling.actions.continueShopping')}
            onClick={onClose}
            type="primary"
          />
          <Button
            className={PayNowButtonStyling({ variant })}
            href={basketPageSlugs[locale]}
            label={t('crossSelling.actions.payNow')}
            type="primary"
          />
        </div>
      </div>

      {!!crossSellArticles?.length && (
        <div className="md:pt-15 flex flex-col gap-3 p-5 pb-10 pt-5 md:gap-6 md:p-10">
          <span className={RelatedHeaderStyling({ variant })}>{t('crossSelling.relatedTitle')}</span>
          <Presence
            id="cross-selling-modal-presence"
            loader={
              <CarouselProducts>
                {/* CarouselProducts requires an array, Repeat returns a Fragment which does not match this condition */}
                <Skeleton className="h-100 w-full" />
                <Skeleton className="h-100 w-full" />
                <Skeleton className="h-100 w-full" />
                <Skeleton className="h-100 w-full" />
              </CarouselProducts>
            }
            visible
          >
            <CarouselProducts
              options={{
                navigation: true,
                slidesPerView: 2,
                breakpoints: {
                  [SCREENS.md]: {
                    slidesPerView: 2.4,
                  },
                  [SCREENS.lg]: {
                    slidesPerView: 4,
                  },
                },
              }}
            >
              {crossSellArticles.map((article, index) => {
                const added = addedArticleIds.includes(article.id);

                const _trackInfo = {
                  index,
                  customEvent: trackEvents.VIEW_LIST_ITEM,
                  item_id: article.id,
                  item_name: article.name,
                  item_list_name: 'cross-selling',
                };

                return (
                  <ProductCard
                    cartIcon={added ? faCheck : faBasketShopping}
                    cartLabel={t(`crossSelling.actions.${added ? 'added' : 'add'}`) ?? ''}
                    className={ProductCardStyling({ variant })}
                    handleAddToCart={() => handleAddToCart(article)}
                    id={article.id}
                    imageSrc={article.productImage?.src}
                    key={article.id}
                    price={showPrice ? article.pricing?.price : undefined}
                    title={article?.name ?? ''}
                    trackInfo={_trackInfo}
                    variant={productCardVariant}
                  />
                );
              })}
            </CarouselProducts>
          </Presence>

          <Presence id="cross-selling-error-presence" visible={hasError}>
            <Alert title={t('crossSelling.error.title') ?? ''} type="error">
              <span>{t('crossSelling.error.subtitle')}</span>
            </Alert>
          </Presence>
        </div>
      )}
    </Modal>
  );
};

export default CrossSellingModal;
