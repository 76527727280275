import { Image } from '@boss/types/b2b-b2c';
import { ThemeColor } from '@boss/types/corporate-jobs';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';

import QuoteCarousel from './Carousel';
import QuotePrimary from './Primary';
import QuoteSecondary from './Secondary';

export type QuoteProps = {
  author?: string;
  className?: string;
  company?: string;
  icon?: IconDefinition;
  image?: Image;
  imagePosition?: 'left' | 'right';
  quote: string;
  roundedCorners?: boolean;
  variant?: 'primary' | 'secondary' | 'carousel';
  themeColor?: ThemeColor;
  background?: boolean;
};

export type QuoteInstanceProps = Omit<QuoteProps, 'variant'>;

const Quote = ({ variant = 'primary', ...props }: QuoteProps) => {
  if (variant === 'primary') {
    return <QuotePrimary {...props} />;
  }
  if (variant === 'secondary') {
    return <QuoteSecondary {...props} />;
  }
  if (variant === 'carousel') {
    return <QuoteCarousel {...props} />;
  }

  return null;
};

export default Quote;
