import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { IIncidentType, WorkerOrderType } from '@boss/services/client';

import { fetchAllIncidentTypes } from './connector';

import { incidentTypeKeys } from './index';

const useIncidentTypes = (workerOrderType: WorkerOrderType) => {
  const { locale } = useRouter();

  return useQuery<IIncidentType[]>(
    incidentTypeKeys.allIncidentTypes(workerOrderType, locale as string),
    async () => await fetchAllIncidentTypes(workerOrderType, locale as string),
  );
};

export { useIncidentTypes };
