import * as Sentry from '@sentry/nextjs';

import { IEvent, events as eventService } from '@boss/services/client';

const participateEvent = async ({
  eventId,
  data,
  isLoggedIn,
  locale,
}: {
  eventId: string;
  data: Record<string, unknown>;
  isLoggedIn?: boolean;
  locale: string;
}) => {
  try {
    return await eventService.participate({ eventId, data, isLoggedIn, locale });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Participate event',
      },
    });

    // Needs a re-throw
    throw error;
  }
};

const getEvent = async ({ eventId, locale }: { eventId: string; locale: string }) => {
  try {
    //TODO: uncomment the following line and remove this mock once the api works again
    // return await eventService.getEvent({ locale, eventId });

    return {
      id: 'Peindre, comment commencer',
      type: 'workshop',
      title: 'Schilderen hoe begin ik eraan',
      description: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Schilderen hoe begin ik eraan leert je alles stappen hoe je tot een perfect eindresultaat komt. Van voorbereiding tot afwerking.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
      location: 'Colora Waregem - Wijmeriestraat - waregem ',
      locationId: '7910',
      skuId: 'C000054',
      productLink: '14707304',
      date: '2024-12-31T00:00:00Z',
      startTime: '2024-12-31T15:00:00',
      endTime: '2024-12-31T17:00:00',
      duration: 90,
      price: 30,
      complete: false,
      numberOfParticipants: 10,
      status: 'active',
      language: locale,
    } as IEvent;
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Get event',
      },
    });
  }
};

export { participateEvent, getEvent };
