import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';

import { ProductCardProps } from '..';
import { Button, CardLabel, FavoriteButton, Image, Link, Price, Specifications, Tooltip } from '../../';

const ProductCardPrimary = ({
  cartLabel,
  className,
  handleAddToCart,
  handleAddToWishlist,
  id,
  imageSrc,
  inWishlist,
  labels,
  price,
  slug,
  specifications,
  testid = 'product-card',
  title,
  renderRating,
  translations,
  plusIcon,
  hideAddToCartAction,
  handleSelect,
  handleRemove,
  trackInfo,
}: ProductCardProps) => (
  <div className="sm:pt-12.5 h-full pt-5" data-testid={testid}>
    <div
      className={twMerge(
        'bg-green-light rounded-tl-30 shadow-m flex h-full w-full flex-col gap-3 px-3 pb-8 pt-5',
        className,
      )}
    >
      <Link className="flex flex-col justify-between gap-6" href={slug} trackInfo={trackInfo}>
        {imageSrc && (
          <div className="sm:-mt-17.5 -mt-10 flex h-fit self-end">
            <Image alt={title} className="w-50 h-50 object-contain" height={200} src={imageSrc} useNext width={200} />
          </div>
        )}

        <div className="relative flex flex-grow flex-col gap-3">
          {labels && (
            <div className="absolute -left-3 bottom-[calc(100%+12px)] flex flex-col gap-1">
              {labels.map((label, index) => (
                <CardLabel key={`${id ?? title}-label-${index}`} round="right" {...label} />
              ))}
            </div>
          )}

          <div className="flex justify-between gap-5">
            <h3 className="overflow-hidden text-ellipsis">{title}</h3>
            {renderRating && (
              <div className="min-h-[1.2rem]">
                <div className="my-2">{renderRating()}</div>
              </div>
            )}
          </div>

          {!!specifications?.length && (
            <div className="pb-3">
              <Specifications id={`${slug}-${title}`} items={specifications} />
            </div>
          )}
        </div>
      </Link>
      <div className="mt-auto flex h-fit w-full flex-wrap justify-between gap-5">
        {!!price && (
          <>
            {translations?.disclaimer ? (
              <Tooltip
                buttonClassName="top-2.5"
                content={<span className="small italic">{translations.disclaimer}</span>}
              >
                <Price prefix translations={translations} value={price} />
              </Tooltip>
            ) : (
              <Price prefix translations={translations} value={price} />
            )}
          </>
        )}
        <div className="flex items-center gap-2 self-end">
          {handleAddToWishlist && <FavoriteButton isFavorite={inWishlist} onClick={handleAddToWishlist} />}
          {(slug || !!handleAddToCart) && !hideAddToCartAction && (
            <Button
              className="ml-auto h-8 w-fit py-0	text-base"
              href={handleAddToCart ? undefined : slug}
              iconPosition="right"
              label={cartLabel}
              onClick={handleAddToCart}
              type="primary"
            />
          )}
          {handleSelect && (
            <Button
              className="ml-auto h-8 w-fit py-0	text-base"
              icon={plusIcon}
              iconPosition="right"
              onClick={handleSelect}
              trackInfo={trackInfo}
              type="primary"
            />
          )}
          {handleRemove && (
            <Button
              className="text-red ml-auto h-8 w-fit	py-0 text-base"
              icon={faTrashCan}
              iconPosition="right"
              onClick={handleRemove}
              type="secondary"
            />
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ProductCardPrimary;
