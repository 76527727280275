import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

import { IProduct } from '@boss/services';
import type { Color, ImagesWithProductPointerFields, RichTextFields, SectionFields } from '@boss/types/b2b-b2c';
import { ImageWithPointers } from '@boss/ui';

import { SectionMapperProps } from '..';
import { PointerCardWrapper } from '../../../';
import ComponentMapper from '../../ComponentMapper';

type PointerImage = ComponentProps<typeof ImageWithPointers> & {
  id?: string;
};

/**
 * Function that maps a list of pointerImages to one pointerImage object ready to be passed to the ImageWithPointers component
 * @param {ImagesWithProductPointerFields[]} images List of images that point to the same image but have their own focal point so multiple points can be placed on the same image.
 * @param {IProduct[]} products A list of products that's used to map the linked pointerImage's product to since the section content's pointerImages only have the product id
 * @param {IColor[]} colors A list of colors that's used to map the linked pointerImage's color to since the section content's pointerImages only have the color id
 * @returns {PointerImage} returns a mapped PointImage object
 */
const mapPointerImage = (
  images: ImagesWithProductPointerFields[],
  products?: IProduct[],
  colors?: Color[],
): PointerImage => {
  const baseImage = images[0];

  return {
    id: baseImage.image.id,
    alt: baseImage.image.title,
    src: baseImage.image.file.url,
    width: baseImage.image.file.details.image?.width ?? 0,
    height: baseImage.image.file.details.image?.height ?? 0,
    pointers: images.map(image => ({
      focalPoint: image.focalPoint.focalPoint,
      floatingContent: (
        <PointerCardWrapper
          className="w-50"
          color={colors?.find(color => color.id === image?.color?.colorId)}
          product={products?.find(product => product.id === image?.product?.productID)}
        />
      ),
    })),
  };
};

/**
 * Function that maps a section entry to an object of pointerImages and a singular richText entry. It wil exclude all other content.
 * @param {SectionFields} entry SectionFields entry
 * @param {IProduct[]} products A list of products that's used to map the linked pointerImage's product to since the section content's pointerImages only have the product id
 * @param {IColor[]} colors A list of colors that's used to map the linked pointerImage's color to since the section content's pointerImages only have the color id
 * @returns {object} returns an object containing the following properties:
 * @property {PointerImage[]} images List of mapped pointer images from the passed entry's content field
 * @property {RichTextFields} richText A singular richText entry that has been filtered from the passed entry's content field
 */
const mapProductPointerData = (entry: SectionFields, products?: IProduct[], colors?: Color[]) => {
  const { content } = entry;
  const richText = content.filter((item): item is RichTextFields => item.__typename === 'richText')?.[0];
  const images = content.filter(
    (item): item is ImagesWithProductPointerFields => item.__typename === 'imageWithProductPointer',
  );

  const pointerImages: PointerImage[] = [];

  images.forEach(image => {
    const imageId = image.image.id;

    if (pointerImages.some(image => image.id === imageId)) {
      return;
    }

    const matchedImages = images.filter(image => image.image.id === imageId);
    const mappedPointerImage = mapPointerImage(matchedImages, products, colors);

    pointerImages.push(mappedPointerImage);
  });

  return { images: pointerImages, richText };
};

const ImagesWithProductPointersMapper = ({ entry, theme, locale, products, colors }: SectionMapperProps) => {
  const { images, richText } = mapProductPointerData(entry, products, colors);

  const firstImage = images[0];
  const secondImage = images[1];

  if (!firstImage) {
    return null;
  }

  return (
    <div className="grid gap-5 lg:grid-cols-6" data-testid="product-pointers-grid">
      <div className="sm:max-h-100 max-h-60 lg:col-span-3">
        <ImageWithPointers imageClassName="rounded-brand rounded-bl-none" {...firstImage} />
      </div>
      {richText && (
        <ComponentMapper
          additionalProps={{
            className: twMerge(
              'shadow-m bg-white rounded-brand p-5 flex flex-col justify-center',
              secondImage ? '' : 'lg:col-span-3',
            ),
          }}
          entry={richText}
          locale={locale}
          theme={theme}
        />
      )}
      {secondImage && (
        <div className={twMerge('sm:max-h-100 max-h-60', richText ? 'lg:col-span-2' : 'lg:col-span-3')}>
          <ImageWithPointers imageClassName="rounded-brand rounded-bl-none" {...secondImage} />
        </div>
      )}
    </div>
  );
};

export default ImagesWithProductPointersMapper;
