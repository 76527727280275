import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/named
import { v4 as uuid } from 'uuid';

import { CHANNEL, trackEvents } from '@boss/constants/shared';
import { IWishlist, IWishlistLine } from '@boss/services/client';
import { Alert, Button } from '@boss/ui';
import { getAccountPageSlugs, isDefined } from '@boss/utils';

import { useAccountPriceInfo } from '../../../../client-queries';
import { ListNameEdit, PagedArticles } from '../../../../components';
import { ExtentedArticle } from '../../../../components/PagedArticles';
import { useArticles, useBasket, useEventTracker, useProductMap, useWishlistOperations } from '../../../../hooks';
import { accountPageConfig, convertColorCodeToId, isB2b } from '../../../../utils';
import ArticleSearch from '../search/articles';

type UpdateArticleData =
  | { type: 'quantity'; value: number }
  | { type: 'packaging'; value: string }
  | { type: 'color'; value: IWishlistLine['color'] };

interface Props {
  list: IWishlist;
  readonly?: boolean;
}

const mapWishlistColor = (color: IWishlistLine['color']): ExtentedArticle['selectedColor'] => ({
  code: color?.colorcode ?? '',
  rgb: color?.rgb ?? '',
  name: color?.colorname ?? '',
  id: convertColorCodeToId(color?.colorcode ?? ''),
});

const ArticleListDetail = ({ list, readonly }: Props) => {
  const { addBasketLines } = useBasket();
  const {
    removeFromList,
    createWishlistLine,
    updateWishlistLine,
    updateListName,
    listName,
    setListName,
    editMode,
    setEditMode,
    showError,
  } = useWishlistOperations(list);
  const { mapProductSlug } = useProductMap();
  const { listPageTypeToShow } = accountPageConfig;
  const [articles, setArticles] = useState<ExtentedArticle[]>([]);
  const listLineIds = list.lines.filter(line => line.typeid).map(line => line.typeid ?? '');
  const [listLineIdsToFetch, setListLineIdsToFetch] = useState(listLineIds);
  const prevLineIdsRef = useRef(new Set(listLineIds));
  const { articles: articleResults, articlesLoaded, articlesLoading } = useArticles(listLineIdsToFetch);
  const { trackCustomEvent } = useEventTracker();
  const accountPriceInfoData = useAccountPriceInfo(
    articles.map(article => {
      return {
        skuId: article.id,
        quantity: article.selectedQuantity ?? 1,
        unit: article.selectedPackaging ?? 'stk',
      };
    }),
  );

  const accountPriceInfo = accountPriceInfoData.map(({ data }) => data?.price).filter(isDefined);
  const accountPriceInfoLoading = accountPriceInfoData.some(({ isLoading }) => isLoading);

  const { t } = useTranslation('account', { keyPrefix: 'list.detailPage' });
  const { locale } = useRouter();

  useEffect(() => {
    if (!articlesLoaded) {
      return;
    }

    const articlesData = list.lines?.reduce((accumulator: ExtentedArticle[], wishListArticle) => {
      if (wishListArticle) {
        const article = articleResults.find(({ id }) => id == wishListArticle.typeid);

        if (!article) {
          return accumulator;
        }

        const selectedColor = mapWishlistColor(wishListArticle.color);

        accumulator.push({
          ...article,
          lineId: wishListArticle?.id ?? uuid(),
          selectedQuantity: wishListArticle?.quantity ?? 1,
          selectedPackaging: wishListArticle?.packaging ?? '',
          selectedColor,
          slug: mapProductSlug({
            slug: article.slug ?? '',
            colorId: selectedColor?.id,
            colorGroups: article.colortypegroups,
          }),
        });
      }
      return accumulator;
    }, []);

    setArticles(articlesData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articlesLoaded, articleResults.length, list.lines]);

  useEffect(() => {
    const currentLineIds = new Set(listLineIds);
    const lineAdded =
      currentLineIds.size > prevLineIdsRef.current.size &&
      [...currentLineIds].some(id => !prevLineIdsRef.current.has(id));

    if (lineAdded) {
      setListLineIdsToFetch(listLineIds ?? []);
    }

    prevLineIdsRef.current = currentLineIds;
  }, [listLineIds]);

  useEffect(() => {
    if (!list.lines?.length) {
      setArticles([]);
    }
  }, [list]);

  const addArticlesToList = async (articles: ExtentedArticle[]) => {
    const newWishListLines = articles
      .filter(article => !list.lines.some(({ typeid }) => typeid === article.id))
      .map(article => ({
        typeid: article.id,
        color: article?.selectedColor
          ? {
              colorcode: article.selectedColor?.code ?? '',
              colorname: article.selectedColor?.name ?? '',
              rgb: article.selectedColor?.rgb ?? '',
            }
          : undefined,
      }));

    const wishlistId = list.id ?? '';

    await Promise.all(
      newWishListLines.map(async wishlistLine => {
        await createWishlistLine({
          wishlistId,
          wishlistLine,
          wishlistType: list.type,
        });
      }),
    );
  };

  const updateArticle = (lineId: string, data: UpdateArticleData) => {
    const wishlistLine = list.lines.find(line => line.id === lineId);
    const wishlistId = list.id ?? '';

    if (wishlistLine && wishlistId) {
      updateWishlistLine({
        wishlistId,
        wishlistLine: {
          ...wishlistLine,
          [data.type]: data.value,
        },
        wishlistType: list.type,
      });
    }
  };

  const addToCart = (articles: ExtentedArticle[]) => {
    const basketLines = articles.map(article => ({
      item: {
        skuid: article.id,
        color: {
          colorcode: article.selectedColor?.code,
          colorname: article.selectedColor?.name,
          rgb: article.selectedColor?.rgb,
        },
        description: article.name,
      },
      quantity: {
        secondaryquantity: article.selectedQuantity ?? 1,
        secondaryunit: article.selectedPackaging ?? '',
      },
      price: {
        salesprice: article.price?.salesPrice,
      },
    }));

    const trackingProperties = {
      items: articles.map(article => ({
        item_id: article.productId,
        item_name: article.name,
        affiliation: `${locale} webshop`,
        currency: 'EUR',
        discount: article.pricing.discountPrice,
        item_brand: CHANNEL,
        price: article.pricing.price,
        quantity: article.quantity,
      })),
    };

    addBasketLines(basketLines);
    trackCustomEvent(trackEvents.ADD_TO_CART, trackingProperties);
  };

  return (
    <div className="mb-10 flex flex-col gap-3">
      {!readonly ? (
        <>
          <ListNameEdit
            editMode={editMode}
            listDescription={list.description ?? ''}
            listName={listName}
            setEditMode={setEditMode}
            setListName={setListName}
            updateListName={updateListName}
          />
          {showError && (
            <Alert className="mt-2" type="error">
              {t('updateError')}
            </Alert>
          )}
          {isB2b && <ArticleSearch addArticlesToList={articles => addArticlesToList(articles as ExtentedArticle[])} />}
        </>
      ) : (
        <h1>{listName}</h1>
      )}
      {!list.lines.length ? (
        <p className="mt-5">{t('emptyList')}</p>
      ) : (
        <PagedArticles
          accountPriceInfo={accountPriceInfo}
          articles={articles}
          editableArticles
          getConfirmLabel={quantity => t('addToCart', { quantity })}
          isLoading={articlesLoading}
          isUpdating={accountPriceInfoLoading}
          onConfirmSelection={addToCart}
          onRemove={removeFromList}
          onUpdateColor={(lineId, value) =>
            updateArticle(lineId, {
              type: 'color',
              value: {
                colorcode: value?.code,
                colorname: value?.name,
                rgb: value?.rgb,
              },
            })
          }
          onUpdatePackaging={(lineId, value) => updateArticle(lineId, { type: 'packaging', value })}
          onUpdateQuantity={(lineId, value) => updateArticle(lineId, { type: 'quantity', value })}
          showSelectAllButton
        />
      )}

      <Button
        className="ml-auto mt-5"
        href={`${getAccountPageSlugs(listPageTypeToShow)[locale as string]}`}
        label={t('backToOverview')}
        type="secondary"
      />
    </div>
  );
};

export default ArticleListDetail;
