import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';

import { Section, Specifications } from '@boss/ui';

import { B2BRegisterForm, B2CRegisterForm } from '..';
import { isB2b } from '../../utils';

const CardStyling = cva('rounded-brand flex flex-col gap-6 rounded-bl-none px-5 py-4 lg:col-span-5 md:gap-8 md:p-9', {
  variants: {
    variant: {
      b2cForm: 'bg-blue-light/30 lg:col-start-2',
      b2bForm: 'bg-blue-light/30 lg:col-span-8',
      info: 'bg-beige-light md:rounded-bl-brand md:rounded-br-none',
    },
  },
});

const RegisterSection = () => {
  const { t } = useTranslation('register', { keyPrefix: 'registerSection' });

  return (
    <Section
      className="md:pt-13 md:pb-35 grid gap-4 pb-20 md:grid-cols-1 md:gap-6 lg:grid-cols-10"
      content={
        <>
          <div className={CardStyling({ variant: isB2b ? 'b2bForm' : 'b2cForm' })}>
            <h2 className="md:h3">{t('title')}</h2>
            {isB2b ? <B2BRegisterForm /> : <B2CRegisterForm />}
          </div>
          {!isB2b && (
            <div className={CardStyling({ variant: 'info' })}>
              <div className="text-blue flex flex-col gap-3 md:gap-6">
                <span className="h3">{t('benefits.title')}</span>
                <Specifications
                  border={false}
                  className="md:gap-4"
                  id="register-client-benefits"
                  items={[1, 2].map((item, index) => (
                    <span className="text-textColor" key={`register-client-benefit-${item}-${index}`}>
                      {t(`benefits.${item}`)}
                    </span>
                  ))}
                />
              </div>
            </div>
          )}
        </>
      }
    />
  );
};

export default RegisterSection;
