import { SimpleColor } from '@boss/types/b2b-b2c';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import ColorButton from '../ColorButton';
import ShowMore from '../ShowMore';

type Props = {
  className?: string;
  colors?: SimpleColor[];
  gridClassName?: string;
  handleShowMore?: () => void;
  hasShowMore?: boolean;
  id?: string;
  onChangeColorClick?: (color: SimpleColor) => void;
  onClickAction?: 'navigate' | 'click';
  onFavorite?: (color: SimpleColor) => void;
  selectedColor?: SimpleColor;
  showMore?: boolean;
  translations: {
    trending?: string;
    noResults: string;
    showLess: string;
    showMore: string;
  };
  title?: string | null;
  subtitle?: string | null;
  variant?: 'primary' | 'secondary';
  titleClassName?: string;
  favoriteColorIds?: string[];
  colorType?: 'default' | 'transparant';
  children?: ReactNode;
};

const ColorsGrid = ({
  translations,
  colors,
  selectedColor,
  hasShowMore,
  handleShowMore,
  showMore,
  onChangeColorClick,
  onFavorite,
  title,
  subtitle,
  variant,
  className,
  titleClassName = 'font-bold',
  gridClassName,
  id,
  onClickAction = 'click',
  favoriteColorIds,
  colorType,
  children,
}: Props) => (
  <div className={twMerge('flex w-full flex-col gap-6 md:gap-10', className)}>
    <div className="flex flex-col gap-1">
      {title && <span className={titleClassName}>{title}</span>}
      {subtitle && <span className="text-gray-400">{subtitle}</span>}
    </div>
    {colors?.length ? (
      <div
        className={twMerge(
          'xs:grid-cols-3 grid grid-cols-2 justify-items-center gap-x-4 gap-y-6 sm:grid-cols-4 md:gap-x-5 md:gap-y-8',
          gridClassName,
        )}
        data-testid="most-popular-colors-modal"
      >
        {colors.map(color => {
          if (color) {
            return (
              <ColorButton
                active={color.code === selectedColor?.code}
                backgroundColor={color.rgb}
                colorType={colorType}
                id={color.code}
                isFavorite={favoriteColorIds?.includes(color.code?.toString())}
                key={`colors-grid-${color.code}-${color.name}-${id ?? ''}`}
                name={color.name}
                onClick={() => onChangeColorClick?.(color)}
                onClickAction={onClickAction}
                onFavorite={onFavorite ? () => onFavorite(color) : undefined}
                slug={color.slug}
                translations={translations}
                trending={color.trending}
                variant={variant}
              />
            );
          }
          return null;
        })}
        {children}
      </div>
    ) : (
      <span>{translations?.noResults}</span>
    )}
    {hasShowMore && handleShowMore && (
      <ShowMore
        className="caption md:body"
        isShowingMore={!!showMore}
        onClick={handleShowMore}
        translations={translations}
      />
    )}
  </div>
);

export default ColorsGrid;
