export const THEME_CLASSNAMES = {
  text: {
    Blue: 'text-theme-blue',
    Brown: 'text-theme-brown',
    Red: 'text-theme-red',
    Purple: 'text-theme-purple',
    Green: 'text-theme-green',
  },
  background: {
    Blue: 'bg-theme-blue',
    Brown: 'bg-theme-brown',
    Red: 'bg-theme-red',
    Purple: 'bg-theme-purple',
    Green: 'bg-theme-green',
  },
  backgroundWithPseudo: {
    Blue: 'bg-theme-blue before:bg-theme-blue after:bg-theme-blue',
    Brown: 'bg-theme-brown before:bg-theme-brown after:bg-theme-brown',
    Red: 'bg-theme-red before:bg-theme-red after:bg-theme-red',
    Purple: 'bg-theme-purple before:bg-theme-purple after:bg-theme-purple',
    Green: 'bg-theme-green before:bg-theme-green after:bg-theme-green',
  },
  bullet: {
    Blue: 'marker:text-theme-blue',
    Brown: 'marker:text-theme-brown',
    Red: 'marker:text-theme-red',
    Purple: 'marker:text-theme-purple',
    Green: 'marker:text-theme-green',
  },
};
