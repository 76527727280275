import * as bossClient from '@boss/boss-client';

/**
 * Sends form data
 */
export const send = async (formData: Record<string, string>, locale: string) =>
  await bossClient.form.submit(formData, locale);

export const sendClient = async (formData: Record<string, string>, locale: string) =>
  await bossClient.submitForm.submitForm(formData, locale);
