import type { Store } from '../types';

export const mapStore = (store: Store) => {
  const defaultOpeningHours = store.openinghours.defaults || [];

  return {
    ...store,
    openinghours: {
      ...store.openinghours,
      defaults: [
        ...defaultOpeningHours.map(openingHour => {
          return {
            ...openingHour,
            times: openingHour.times
              .map(time => {
                return {
                  fromSeconds: typeof time.from === 'number' ? time.from : null,
                  tillSeconds: typeof time.till === 'number' ? time.till : null,
                  from: time.from,
                  till: time.till,
                };
              })
              .sort((a, b) => (a.fromSeconds ?? 0) - (b.fromSeconds ?? 0)),
          };
        }),
      ],
    },
  };
};

export const mapLocale = (locale: string) => {
  if (locale === 'nl-NL') {
    return 'nl';
  }

  return locale;
};
