import { useTranslation } from 'next-i18next';

import { getOrderTypeByStatus } from '@boss/constants/b2b-b2c';
import { IOrderAddress } from '@boss/types/b2b-b2c';
import { Alert, OrderDetailProducts, Presence } from '@boss/ui';
import { formatDate } from '@boss/utils';

import { OrderDetailSkeleton, ServiceInformation } from '../../../../components';
import { useOrderDetail } from '../../../../hooks';
import { ordersVariant } from '../../../../utils';

type Props = {
  orderId: string;
};

export const Address = ({ title, address }: { title: string; address: IOrderAddress }) => {
  return (
    <div className="flex flex-col">
      <span className="mb-1 font-bold">{title}</span>
      <span>
        {address.street} {address.streetNumber}
      </span>
      <span>
        {address.zipcode} {address.city}
      </span>
      <span>{address.countryRegionId}</span>
    </div>
  );
};

const OrderDetail = ({ orderId }: Props) => {
  const { t } = useTranslation('account');
  const { order, orderLines, isLoading, isError } = useOrderDetail(orderId);

  const invoiceAddress = order?.addresses.find(address => address.role?.toUpperCase() === 'INVOICE');
  const deliveryAddress = order?.addresses.find(address => address.role?.toUpperCase() === 'DELIVERY');

  const fallbackAddress = order?.addresses[0];

  const statusTranslations = t('orderStatus', { returnObjects: true }) as { [key: string]: string };
  const statusType = order?.status ? getOrderTypeByStatus(order?.status) : undefined;

  return (
    <>
      <Presence id="orderDetails" isLoading={isLoading} loader={<OrderDetailSkeleton />} visible={!!order}>
        {order && (
          <>
            <h1>{t('orderDetail.title')}</h1>
            {order.customerReference && <h2>{order.customerReference}</h2>}
            <div className="flex gap-3 py-5">
              <span>
                <strong>{t('orderDetail.date')}: </strong> {formatDate(order.orderDate)}
              </span>
              <span>-</span>
              <span>
                <strong>{t('orderDetail.orderNumber')}: </strong> {order.orderId}
              </span>
              <span>-</span>
              <span>
                <strong>{t('orderDetail.status')}: </strong>
                <span className="text-blue">{statusTranslations[statusType ?? ''] ?? order.status}</span>
              </span>
            </div>
            {!!(order.distributionDescription && order.deliveryLocation) && (
              <div className="mb-3">{`${order.distributionDescription} ${order.deliveryLocation}`}</div>
            )}
            {!!order.worksiteDiscountCode && (
              <div className="mb-3">
                <strong>{t('orderDetail.discountCode')}: </strong>
                {`${order.worksiteDiscountCode} ${
                  order.worksiteDiscountDescription ? `(${order.worksiteDiscountDescription})` : ''
                }`}
              </div>
            )}
            <div className="flex flex-row gap-10">
              {deliveryAddress && (
                <div className="flex flex-col gap-3">
                  <Address address={deliveryAddress} title={t('orderDetail.deliveryAddress')} />
                  {(order.delivery.info || order.delivery.contact) && (
                    <div className="flex flex-col">
                      {order.delivery.info && (
                        <span>
                          <span className="font-bold">{t('orderDetail.deliveryInfo')}: </span>
                          {order.delivery.info}
                        </span>
                      )}
                      {order.delivery.contact && (
                        <span>
                          <span className="font-bold">{t('orderDetail.contact')}: </span>
                          {order.delivery.contact}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
              {invoiceAddress && (
                <div className="flex flex-col gap-3">
                  <Address address={invoiceAddress} title={t('orderDetail.invoiceAddress')} />
                  <div className="flex flex-col">
                    {order.invoice.info && (
                      <span>
                        <span className="font-bold">{t('orderDetail.infoInvoice')}: </span>
                        {order.invoice.info}
                      </span>
                    )}
                    <span>
                      <span className="font-bold">{t('orderDetail.separateForOrder')}: </span>
                      {t(order.invoice.separateForOrder ? 'ja' : 'nee', { ns: 'common' })}
                    </span>
                  </div>
                </div>
              )}
              {!deliveryAddress && !invoiceAddress && fallbackAddress && (
                <Address address={fallbackAddress} title={t('orderDetail.deliveryAddress')} />
              )}
            </div>
            <div className="py-10">
              {orderLines && (
                <OrderDetailProducts
                  products={orderLines}
                  translations={{
                    writereview: t('orderDetail.writereview'),
                    amount: t('orderDetail.amount'),
                    reorder: t('orderDetail.reorder'),
                    trackandtrace: t('orderDetail.trackandtrace'),
                    status: t('orderDetail.status'),
                    deliveredQuantity: t('orderDetail.deliveredQuantity'),
                    remainSalesPhysical: t('orderDetail.remainSalesPhysical'),
                    articleRef: t('orderDetail.articleRef'),
                  }}
                  variant={ordersVariant}
                />
              )}
            </div>
            <ServiceInformation className="my-10" />
          </>
        )}
      </Presence>
      {isError && (
        <Presence id={`$error-message-presence`} visible>
          <Alert className="break-words" iconSize="xl" type="error">
            {t('orderDetail.fetchError')}
          </Alert>
        </Presence>
      )}
    </>
  );
};

export default OrderDetail;
