/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'next-i18next';
import { ChangeEvent, useState } from 'react';

import { Button, InputUnit, Modal, Select, SquaredUnit, SurfaceCalculator, Tooltip } from '@boss/ui';
import { capitalize } from '@boss/utils';

type ConsumptionOption = {
  technique: string;
  usage: {
    average: number;
    min: number;
    max: number;
  };
};

type Props = {
  onClose: () => void;
  onConfirm: (total: number) => void;
  consumptionOptions: ConsumptionOption[];
};

type Surface = {
  length: number;
  width: number;
  total: number;
};

const CONSUMPTION_KEYS = ['max', 'average', 'min'] as const;

type ConsumptionKey = (typeof CONSUMPTION_KEYS)[number];

const getActiveConsumptionOption = (options: ConsumptionOption[], technique: string) =>
  options.find(option => option.technique === technique);

const SurfaceCalculatorModal = ({ onClose, consumptionOptions, onConfirm }: Props) => {
  const { t } = useTranslation(['product', 'common']);
  const [activeTechnique, setActiveTechnique] = useState(consumptionOptions?.[0].technique);
  const [activeConsumption, setActiveConsumption] = useState<ConsumptionKey>('average');
  const [paintSurfaces, setPaintSurfaces] = useState<Surface[]>([]);
  const [noPaintSurfaces, setNoPaintSurfaces] = useState<Surface[]>([]);
  const [layers, setLayers] = useState(1);

  const totalPaint = paintSurfaces.reduce((acc, curr) => acc + curr.total, 0);
  const totalNoPaint = noPaintSurfaces.reduce((acc, curr) => acc + curr.total, 0);
  const totalSurface = Number((totalPaint - totalNoPaint).toFixed(2));
  const activeConsumptionOption = getActiveConsumptionOption(consumptionOptions, activeTechnique);
  const activeUsage = activeConsumptionOption?.usage[activeConsumption] ?? 1;
  const amountOfPaintNeeded = (1 / activeUsage) * totalSurface;
  const totalPaintNeeded = Number((amountOfPaintNeeded * layers).toFixed(2));

  const genericTranslations = {
    add: t('add', { ns: 'common' }),
    height: t('height', { ns: 'common' }),
    length: t('length', { ns: 'common' }),
    width: t('width', { ns: 'common' }),
  };

  const onLayersChange = (e: ChangeEvent<HTMLInputElement>): void => setLayers(Number(e.target.value));
  const onConsumptionChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>): void =>
    setActiveConsumption(e.target.value as ConsumptionKey);

  return (
    <Modal
      ariaLabel="surface-calculator-modal"
      className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-x-10 md:gap-y-10"
      onClose={onClose}
    >
      <h3 className="md:col-span-full">{t('productPanel.surfaceCalculator.title')}</h3>

      <div className="flex flex-col gap-4">
        <span className="font-bold">{t('productPanel.surfaceCalculator.techniqueTitle')}</span>
        <Select
          name="technique"
          onChange={e => setActiveTechnique(e.target.value)}
          options={consumptionOptions.map(option => ({
            value: option.technique,
            label: capitalize(option.technique),
          }))}
          value={activeTechnique}
        />
      </div>

      <div className="flex flex-col gap-4">
        <span className="font-bold">{t('productPanel.surfaceCalculator.consumptionTitle')}</span>
        <Select
          name="consumption"
          onChange={onConsumptionChange}
          options={CONSUMPTION_KEYS.map(option => ({
            value: option,
            label: t(`productPanel.surfaceCalculator.consumption.${option}`),
          }))}
          value={activeConsumption}
        />
      </div>

      <SurfaceCalculator
        onCalculate={setPaintSurfaces}
        translations={{
          ...genericTranslations,
          title: t('productPanel.surfaceCalculator.includeSurfaceTitle') ?? '',
        }}
      />

      <SurfaceCalculator
        onCalculate={setNoPaintSurfaces}
        translations={{
          ...genericTranslations,
          title: t('productPanel.surfaceCalculator.excludeSurfaceTitle') ?? '',
        }}
      />

      <div className="relative flex h-fit flex-col gap-4">
        <span className="font-bold">{t('productPanel.surfaceCalculator.layersTitle')}</span>
        <InputUnit max="5" min="1" name="paint-calculator-input" onChange={onLayersChange} step="1" value={layers} />
        <span className="caption absolute -bottom-5 left-0">
          {t('productPanel.surfaceCalculator.layersDisclaimer')}
        </span>
      </div>

      <div className="flex flex-col flex-wrap items-end justify-end gap-6 md:col-span-full">
        <span className="h4">
          {t('productPanel.surfaceCalculator.totalSurface')}: {totalSurface} <SquaredUnit className="h4" />
        </span>
        <Tooltip
          content={<span className="min-w-105 caption">{t('productPanel.surfaceCalculator.disclaimer')}</span>}
          floatingClassName="max-w-105"
        >
          <span className="h4">
            {t('productPanel.surfaceCalculator.totalPaint')}: {totalPaintNeeded} {t('liter', { ns: 'common' })}
          </span>
        </Tooltip>
      </div>

      <Button
        className="justify-self-end md:col-span-full"
        label={t('confirm', { ns: 'common' })}
        onClick={() => {
          onConfirm(totalPaintNeeded);
          onClose();
        }}
        type="primary"
      />
    </Modal>
  );
};

export default SurfaceCalculatorModal;
