import { faFillDrip, faPalette, faPlus, faRefresh, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useLockedBody } from 'usehooks-ts';

import { IWishlist, IWishlists } from '@boss/services/client';
import { BasicVariant } from '@boss/types/b2b-b2c';
import { Alert, Button, ConfirmationModal, IconButton, Link, Presence } from '@boss/ui';
import { getAccountPageSlugs } from '@boss/utils';

import { useDeleteWishlist } from '../../../../client-queries';
import { AccountSkeleton } from '../../../../components';
import { accountPageConfig, isB2b } from '../../../../utils';

type Props = {
  wishlists: IWishlists | undefined;
  loading: boolean;
  variant?: BasicVariant;
};

type CreateListButtonProps = {
  label?: string;
  locale: string;
};

const CreateListButton = ({ label, locale }: CreateListButtonProps) => (
  <Button
    className="px-10 py-4"
    href={`${getAccountPageSlugs(accountPageConfig.listPageTypeToShow)[locale]}/create`}
    icon={faPlus}
    iconPosition="right"
    label={label}
    testId="create-list-button"
    type="primary"
  />
);

const ListCardStyle = cva('shadow-m relative', {
  variants: {
    variant: {
      primary: 'rounded-tl-none rounded-5 p-8',
      secondary: 'p-5 pr-7',
    },
  },
});

const ListsOverview = ({ wishlists, loading, variant = 'primary' }: Props) => {
  const { listPageTypeToShow, wishlistType } = accountPageConfig;
  const { t } = useTranslation('account', { keyPrefix: 'list.overview' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [wishListToDelete, setWishListToDelete] = useState<IWishlist>();
  const {
    mutate: handleDeleteWishlist,
    isError: deleteWishListError,
    isLoading: deleteWishlistLoading,
  } = useDeleteWishlist();
  const { locale, push } = useRouter();

  useLockedBody(showDeleteModal, 'root');

  const deleteWishlist = (wishlist: IWishlist) => {
    setWishListToDelete(wishlist);
    setShowDeleteModal(true);
  };

  const confirmDeleteWishlist = () => {
    setShowDeleteModal(false);
    if (wishListToDelete) {
      handleDeleteWishlist({
        wishlistId: wishListToDelete.id || '',
        wishlistType: wishListToDelete.type,
      });
    }
  };

  const createListButtonProps = {
    label: t('createNewList') || 'Maak nieuwe lijst aan',
    locale: locale as string,
  };

  const steps = Object.values(t('steps', { returnObjects: true }));

  const listCards = [
    {
      label: t('actions.favoriteColors'),
      subtitle: t('listCardActionLabels.colors', { count: wishlists?.favoritecolor?.lines.length ?? 0 }),
      icon: faPalette,
      id: wishlists?.favoritecolor?.id,
      type: 'favoritecolor',
    },
    {
      label: t('actions.favoriteProducts'),
      subtitle: t('listCardActionLabels.products', { count: wishlists?.favoriteproduct?.lines.length ?? 0 }),
      icon: faFillDrip,
      listType: 'product',
      id: wishlists?.favoriteproduct?.id,
      type: 'favoriteproduct',
    },
  ];

  if (listPageTypeToShow === 'LIST') {
    listCards.unshift({
      label: t('actions.frequentlyOrdered'),
      subtitle: t('actions.frequentlyOrderedSubtitle'),
      icon: faRefresh,
      id: wishlists?.frequentlyorderedsku?.id,
      type: 'frequentlyorderedsku',
    });
  }

  const listViewPage = `${getAccountPageSlugs(listPageTypeToShow)[locale as string]}/view`;

  return (
    <div>
      <h1 className="mb-5">{t('title')}</h1>
      <Presence id="account-ists-page" isLoading={loading} loader={<AccountSkeleton />} visible>
        {isB2b &&
          (!wishlists?.[wishlistType]?.length ? (
            <div className="grid grid-cols-1 pb-5 md:grid-cols-4 lg:grid-cols-5">
              <div className="col-span-2 lg:col-span-3">
                <div className="text-2xl font-bold">{t('subtitle')}</div>
                <div className="mb-5 text-xl font-bold">{t('subtitle2')}</div>
                <p className="mb-5">{t('info')}</p>
                <div className="hidden md:inline-block">
                  <CreateListButton {...createListButtonProps} />
                </div>
              </div>
              <div className="col-span-2 md:px-3 lg:col-span-2">
                <div className="bg-blue-light w-full bg-opacity-50 px-6 py-10">
                  {steps.map((item, index) => (
                    <div className={index + 1 !== steps.length ? 'mb-4' : ''} key={item}>{`${index + 1}. ${item}`}</div>
                  ))}
                  <div className="mt-5 inline-block md:hidden">
                    <CreateListButton {...createListButtonProps} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div>
                <div className="mb-5 text-xl font-bold">{t('myLists')}</div>
                {!deleteWishlistLoading && deleteWishListError && (
                  <Alert className="my-4" type="error">
                    {t('deleteWishlistError')}
                  </Alert>
                )}
                <div className="mb-10 grid grid-cols-1 gap-4 md:grid-cols-3">
                  {wishlists?.[wishlistType].map(list => (
                    <div className="relative" key={list.id}>
                      <Link className="cursor-pointer text-left" href={`${listViewPage}/${list.id}`} testId="list-card">
                        <div className={ListCardStyle({ variant: accountPageConfig.variant })}>
                          <div className="whitespace-wrap mb-2 overflow-hidden text-ellipsis text-xl">
                            {list.description}
                          </div>
                          {/* Had to split the button to a p when there are no items to preserve clickability of the text */}
                          {list.lines.length ? (
                            <button
                              className={list.lines.length ? 'text-blue underline' : ''}
                              disabled={!list.lines.length}
                            >
                              {t('listActionLabels.products', {
                                count: list.lines.length,
                              })}
                            </button>
                          ) : (
                            <p>
                              {t('listActionLabels.products', {
                                count: list.lines.length,
                              })}
                            </p>
                          )}
                        </div>
                      </Link>
                      <button className="absolute right-2 top-2" onClick={() => deleteWishlist(list || '')}>
                        <FontAwesomeIcon className="hover:text-red text-black" icon={faTrashCan} />
                      </button>
                    </div>
                  ))}
                </div>
                <div className="mb-5 text-center">
                  <CreateListButton {...createListButtonProps} />
                </div>
              </div>
              <div className="border-gray-light border-t-1 my-10 w-full border-solid" />
              <h2 className="h3 mb-10 tracking-normal">{t('standardListOverview')}</h2>
            </>
          ))}
        <div className="mb-10 grid grid-cols-1 gap-10 md:grid-cols-2 ">
          {listCards.map(listCardProps => (
            <IconButton
              key={listCardProps.label}
              onClick={() =>
                push({
                  pathname: `${listViewPage}/${listCardProps.id}`,
                  query: {
                    type: listCardProps.type,
                  },
                })
              }
              variant={accountPageConfig.variant}
              {...listCardProps}
            />
          ))}
        </div>
      </Presence>
      <ConfirmationModal
        cancelLabel={t('deleteWishlistModal.cancel')}
        confirmLabel={t('deleteWishlistModal.delete')}
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onConfirm={confirmDeleteWishlist}
      >
        <>
          <div>{t('deleteWishlistModal.text')}</div>
          <div className="my-2 text-lg">{wishListToDelete?.description}</div>
        </>
      </ConfirmationModal>
    </div>
  );
};

export default ListsOverview;
