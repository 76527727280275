import * as Sentry from '@sentry/nextjs';

import { WorkerOrderType, incidentType as incidentTypeService } from '@boss/services/client';

const fetchAllIncidentTypes = async (workerOrderType: WorkerOrderType, locale: string) => {
  try {
    return await incidentTypeService.getIncidentTypes(workerOrderType, locale);
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Get Incident Types',
      },
    });

    // Needs a re-throw
    throw error;
  }
};

export { fetchAllIncidentTypes };
