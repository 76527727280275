import { useIsResponsive } from '@boss/hooks';
import { cva } from 'class-variance-authority';

import { Image } from '../..';
import { StatusIcon } from '../OrderPreview/microComponents';

type Props = {
  title: string;
  status: 'ready' | 'onTheMove';
  description: string;
  products: {
    id: string;
    name: string;
    image?: {
      url: string;
      alt?: string;
    };
  }[];
  translations: {
    products: string;
  };
  variant: 'primary' | 'secondary';
};

const cardLayoutStyle = cva('text-gray-dark relative flex flex-col gap-2 rounded px-3 m:px-5 m-1 py-8 md:py-7', {
  variants: {
    variant: {
      primary: 'bg-green-light rounded-lg',
      secondary: 'bg-white shadow-sm',
    },
  },
});

const OrderCard = ({ title, description, products, translations, variant, status }: Props) => {
  const isMobile = useIsResponsive('lg');
  const itemsToShow = isMobile ? 2 : 4;

  return (
    <div className={cardLayoutStyle({ variant })}>
      <div className="text-gray-dark absolute -right-3 -top-4 flex h-12 w-12 items-center justify-center rounded-full bg-white shadow-lg">
        <StatusIcon status={status} />
      </div>

      {variant === 'primary' ? (
        <>
          <div className="mb-auto">
            <div className="text-md font-bold md:text-lg">{title}</div>
            <div className="inline text-xs leading-none md:hidden">{description}</div>
          </div>
          <div className="my-5 flex">
            {products?.length <= 1 ? (
              <div className="flex">
                <div className="md:w-15 ml-0 mr-5 w-10 md:ml-5 md:mr-10">
                  {products?.[0]?.image && (
                    <Image
                      alt={products?.[0].image.alt}
                      className="object-contain object-bottom"
                      height={200}
                      src={products[0].image.url}
                      useNext
                      width={200}
                    />
                  )}
                </div>
                <div className="text-xs md:text-xl">{products?.[0]?.name}</div>
              </div>
            ) : (
              <div className="flex items-center">
                <div className="flex">
                  {products
                    ?.filter(product => !!product.image)
                    .slice(0, itemsToShow)
                    .map(product => (
                      <div className="mr-5 w-7 md:w-7" key={product.id}>
                        <Image
                          alt={product?.image?.alt}
                          className="object-contain object-bottom"
                          height={200}
                          src={product?.image?.url}
                          useNext
                          width={200}
                        />
                      </div>
                    ))}
                </div>
                <span className="text-sm">{products.length > itemsToShow && `+ ${products.length - itemsToShow}`}</span>
              </div>
            )}
          </div>
          <div className="mt-auto hidden md:inline">{description}</div>
        </>
      ) : (
        <>
          <div className="text-md font-bold md:text-lg">{title}</div>
          <div className="md:text-md text-xs">{description}</div>

          <div className="text-md md:text-xl">
            <strong>{products.length} </strong>
            {translations.products}
          </div>

          <ul className="md:text-md list-disc pl-7 text-sm">
            {products.slice(0, 2).map(product => (
              <li key={product.id}>{product.name}</li>
            ))}
            {products.length > 2 && <li>...</li>}
          </ul>
        </>
      )}
    </div>
  );
};

export default OrderCard;
