import * as bossClient from '@boss/boss-client';
import { Nullable } from '@boss/types/b2b-b2c';

import { TQuotationFileLink, TQuotations } from './types';

/**
 * Get a quotations
 */
export const get = async (accountId: string, locale: string): Promise<Nullable<TQuotations>> => {
  return await bossClient.quotation.getQuotations(accountId, locale);
};

/**
 * get the link to a quotation file
 */
export const getFileLink = async (fileId: string, locale: string): Promise<Nullable<TQuotationFileLink>> => {
  return await bossClient.quotation.getQuotationFileLink(fileId, locale);
};
