import * as bossClient from '@boss/boss-client';

/**
 * Participate event by event id
 * @param {string} eventId - event ID
 * @param data
 */
export const participate = async ({
  eventId,
  data,
  isLoggedIn,
  locale,
}: {
  eventId: string;
  data: Record<string, unknown>;
  isLoggedIn?: boolean;
  locale: string;
}) => {
  return await bossClient.userEventClient.participateEvent({ eventId, data, isLoggedIn, locale });
};

export const getEvent = async ({ eventId, locale }: { eventId: string; locale: string }) => {
  return await bossClient.userEventClient.getEventById(locale, eventId);
};
