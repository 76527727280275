import { TAccountDiscountInfoType } from '@boss/services/client';

export * from './queries';

export const accountKeys = {
  account: (locale: string, accountId: string, status: string) => ['accountInfo', locale, accountId, status],
  paintguide: (locale: string, accountId: string) => ['paintguide', locale, accountId],
  paintguides: (locale: string, accountId: string) => ['paintguides', locale, accountId],
  contacts: (locale: string, accountId: string) => ['contacts', locale, accountId],
  appointments: (locale: string, accountId: string) => ['appointments', locale, accountId],
  invoices: (locale: string, accountId: string, filterOptions: { paid: boolean }) => [
    'invoices',
    locale,
    accountId,
    filterOptions,
  ],
  order: (locale: string, accountId: string, orderId: string) => ['order', locale, accountId, orderId],
  orders: (
    locale: string,
    accountId: string,
    filterOptions: { limit?: number; offset?: number; fromDate?: string; toDate?: string; orderId?: string },
  ) => ['orders', locale, accountId, filterOptions],
  inProcessOrders: (
    locale: string,
    accountId: string,
    filterOptions: { limit?: number; offset?: number; type: number },
  ) => ['inProcessOrders', locale, accountId, filterOptions],
  worksites: (locale: string, accountId: string, status: string) => ['worksites', locale, accountId, status],
  worksitesDetail: (locale: string, accountId: string, status: string) => [
    'worksitesDetail',
    locale,
    accountId,
    status,
  ],
  advices: (locale: string, accountId: string) => ['advices', locale, accountId],
  accountDiscountInfo: (locale: string, accountId: string, type: TAccountDiscountInfoType) => [
    'accountDiscountInfo',
    locale,
    accountId,
    type,
  ],
  subscription: (locale: string, accountId: string) => ['subscription', locale, accountId],
};
