import { eventPageSlugsB2B, homeDeliverySlugsB2B, overviewTypes } from '@boss/constants/b2b-b2c';

import { B2B_QUESTIONS, ENRICHED_QUESTIONS_MAP_B2B } from '../../../constants';
import { mergeOptions } from '../default';

const componentsToExclude = ['promotion-banner'];

const options = mergeOptions({
  youtubeLink: 'https://www.youtube.com/@bosspaintsprofessional9297',
  getSectionBgColor: () => 'bg-blue-light before:bg-blue-light after:bg-blue-light',
  headerVariant: 'secondary',
  stepperVariant: 'secondary',
  componentsToExclude,
  storeFinderVariant: 'secondary',
  storeCardVariant: 'secondary',
  orderCardVariant: 'secondary',
  articleCardVariant: 'secondary',
  inspirationGridVariant: 'secondary',
  displayPWAPopup: true,
  storeDetailTheme: 'pink',
  showProductDocuments: true,
  productPanelVariant: 'secondary',
  expandableFacets: true,
  accountPages: ['DASHBOARD', 'ORDERS', 'INVOICES', 'LIST', 'DISCOUNT', 'ADVICE', 'INFO', 'CONTACT'],
  accountNavigation: {
    mainNav: ['DASHBOARD', 'ORDERS', 'INVOICES', 'LIST', 'ADVICE', 'INFO', 'CONTACT', 'PRICE_LIST', 'LOGOUT'],
    navigationType: 'secondary',
  },
  paintguideQuestionAttributes: B2B_QUESTIONS,
  paintguideEnrichedQuestionMap: ENRICHED_QUESTIONS_MAP_B2B,
  accountShowAppointments: true,
  accountDashboardVariant: 'secondary',
  accountPageConfig: {
    showBreadcrumb: true,
    customHeader: false,
    variant: 'secondary',
    listPageTypeToShow: 'LIST',
    contactPageTypeToShow: 'CONTACTS',
    wishlistType: 'sku',
  },
  productLabelVisibilityConfig: {
    ecocheques: 'hide',
    'bossflash-action': 'hide',
  },
  productSortOptions: [
    {
      labelKey: 'relevance',
    },
    {
      labelKey: 'alphabetical',
      indexSuffix: 'name_asc',
    },
    {
      labelKey: 'priceAscending',
      indexSuffix: 'price_asc',
      condition: {
        isLoggedIn: true,
      },
    },
    {
      labelKey: 'priceDescending',
      indexSuffix: 'price_desc',
      condition: {
        isLoggedIn: true,
      },
    },
  ],
  promotionCardVariant: 'secondary',
  paintSystemTarget: 'VAK',
  ordersVariant: 'secondary',
  basketPageConfig: {
    pageBg: 'secondary',
    sideLayout: 'secondary',
    articlesWrapperBackground: 'none',
    productImages: false,
    needsLogin: true,
  },
  homeDeliverySlugs: homeDeliverySlugsB2B,
  priceConfig: {
    visible: 'auth',
  },
  stockConfig: {
    visible: 'auth',
  },
  colorsConfig: {
    visible: 'auth',
  },
  checkoutConfig: {
    showDeliveryDateStep: true,
    showOverviewStep: false,
    deliveryVariant: 'secondary',
    informationVariant: 'b2b',
    form: {
      hideFields: ['orderType', 'newsletter', 'ordertype'],
    },
    optionOrder: ['pickup', 'delivery'],
    showPaymentMethods: true,
  },
  crossSellingModalConfig: { variant: 'secondary' },
  seoConfig: { titleSuffixContent: 'bosspaints.be' },
  eventConfig: {
    variant: 'secondary',
    resultsPerPage: 8,
    hasFavoriteSection: false,
    showUpcomingEvents: true,
    eventPageSlugs: eventPageSlugsB2B,
    formType: 'education',
  },
  loginPageConfig: {
    enabled: false,
  },
  registerPageConfig: {
    enabled: true,
  },
  productPageConfig: {
    needsLoginForBasket: true,
  },
  storeConfig: {
    sortPriorityStoreType: 'Depot',
    lowerCasePrefix: 'colora',
    filterTypesOut: ['Geen'],
    orderDepotFirst: true,
  },
  loginSectionConfig: {
    variant: 'secondary',
  },
  homePageConfig: {
    showLoginSection: true,
    redirect: {
      destination: 'ACCOUNT',
      auth: true,
    },
  },
  unitConfig: {
    visible: true,
  },
  overviewPageTypes: [
    overviewTypes.TESTIMONIAL,
    overviewTypes.PAINTING_TIPS,
    overviewTypes.PROFESSIONAL_TIPS,
    overviewTypes.COLOR_TIPS,
  ],
  eventType: 'TRAINING',
});

export default options;
