import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { useAccount, useContacts } from '../../client-queries';
import { FormType, useFormField, usePointOfSale, useProfile, useServiceRequest } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

/**
 * Form fields that will be used.
 * Every field, even the conditional ones, should be included in this list.
 *
 */
const FORM_FIELD_KEYS = [
  'clientnumber',
  'companyname',
  'firstname',
  'lastname',
  'incidenttype',
  'preferenceDate',
  'deliverymethod',
  'colorastore',
  'bossdepot',
  'addresses',
  'invoiceaddress',
  'accountaddress',
  'new',
  'message',
  'termsandconditions',
];

/**
 * Delivery options that will be used.
 *
 * @type {readonly ["colorastore", "bossdepot", "addresses"]}
 */
const DELIVERY_OPTION_KEYS = ['', 'colorastore', 'bossdepot', 'addresses'] as const;
/**
 * Delivery address options that will be used.
 *
 * @type {readonly ["invoice", "address", "new"]}
 */
const DELIVERY_ADDRESS_OPTION_KEYS = ['invoice', 'address', 'new'] as const;

/**
 * Definition of the types used in the form.
 * FormFieldKey is a union of all the keys in FORM_FIELD_KEYS.
 * FieldOverwrite is a record with the keys of FORM_FIELD_KEYS and the values are partial FormField objects.
 * DeliveryOptionKey is a union of all the keys in DELIVERY_OPTION_KEYS.
 * DeliveryAddressOptionKey is a union of all the keys in DELIVERY_ADDRESS_OPTION_KEYS.
 */
type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};
type DeliveryOptionKey = (typeof DELIVERY_OPTION_KEYS)[number];
type DeliveryAddressOptionKey = (typeof DELIVERY_ADDRESS_OPTION_KEYS)[number];

/**
 * Map of the excluded fields for each delivery option.
 * The keys are the DeliveryOptionKey and the values are an array of FormFieldKey.
 */
const excludedDeliveryFieldKeysMap: Record<DeliveryOptionKey, string[]> = {
  colorastore: ['bossdepot', 'addresses', 'invoiceaddress', 'accountaddress', 'new'],
  bossdepot: ['colorastore', 'addresses', 'invoiceaddress', 'accountaddress', 'new'],
  addresses: ['colorastore', 'bossdepot'],
  '': ['colorastore', 'bossdepot', 'addresses', 'invoiceaddress', 'accountaddress', 'new'],
};

/**
 * Map of the excluded fields for each delivery address option.
 * The keys are the DeliveryAddressOptionKey and the values are an array of FormFieldKey.
 */
const excludedAddressFieldKeysMap: Record<DeliveryAddressOptionKey, string[]> = {
  invoice: ['accountaddress', 'new'],
  address: ['invoiceaddress', 'new'],
  new: ['accountaddress', 'invoiceaddress'],
};

/**
 * Type for the Machine Repair Component
 *
 * @param {FormType} type - The type of the form.
 * @param {StringWithAutoComplete<FormFieldKey>[]} fieldsToShow - The fields that will be shown in the form.
 * @param {FieldOverwrite} fieldsOverwrite - The fields that will be overwritten.
 * @param {FormField[]} additionalFields - Additional fields that will be added to the form.
 * @param {string} className - The class name of the form.
 */
type MachineRepairFormProps = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

type Address = {
  street: string;
  streetnumber: string;
  zipcode: string;
  city: string;
  postbox: string;
  country: string;
};

const MachineRepairForm: React.FC<MachineRepairFormProps> = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type,
}) => {
  const { onSubmit, isSubmitting, isSuccess, reset } = useServiceRequest();
  const { t } = useTranslation('forms');
  const chooseTranslation = t('select.choose');
  const { locale } = useRouter();
  const { stores } = usePointOfSale();
  const { data: account } = useAccount(locale);
  const { data: contacts } = useContacts(locale);

  const { data: profile } = useProfile();
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const { addresses } = account || {};
  const {
    clientnumber,
    termsandconditions,
    street,
    streetnumber,
    bus,
    zipcode,
    city,
    country,
    firstname,
    lastname,
    companyname,
  } = useFormField();

  /**
   * Mapped addresses of the account.
   */
  const allAccountAddresses =
    addresses?.map((key, index) => ({
      value: index.toString(),
      label: `${key.street} ${key.streetnumber}, ${key.zipcode} ${key.country}`,
    })) ?? [];

  allAccountAddresses.unshift({ value: '', label: chooseTranslation });

  /**
   * Mapped and filtered addresses for invoices.
   */
  const invoiceAddressesList = addresses?.filter(address => address.type === 'invoice');

  const invoiceAddresses =
    invoiceAddressesList?.map((key, index) => ({
      value: index.toString(),
      label: `${key.street} ${key.streetnumber}, ${key.zipcode} ${key.country}`,
    })) ?? [];

  invoiceAddresses.unshift({ value: '', label: chooseTranslation });

  const coloraStores = stores
    .filter(store => store.storetype === 'Colora')
    .map(store => ({ value: store.id, label: store.name }));

  coloraStores.unshift({ value: '', label: chooseTranslation });

  const depotStores = stores
    .filter(store => store.storetype === 'Depot')
    .map(store => ({ value: store.id, label: store.name }));

  depotStores.unshift({ value: '', label: t('select.choose') });

  const deliveryMethodOptions = DELIVERY_OPTION_KEYS.map(key => ({
    value: key,
    label: key === '' ? chooseTranslation : t(`select.machineDeliveryOptions.${key}`),
  }));

  const deliveryAddressOptions = DELIVERY_ADDRESS_OPTION_KEYS.map(key => ({
    value: key,
    label: t(`select.addressDeliveryOptions.${key}`),
  }));

  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryOptionKey>(deliveryMethodOptions[0].value);
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressOptionKey>(deliveryAddressOptions[0].value);

  const [address, setAddress] = useState<Address>();
  const [invoiceAddress, setInvoiceAddress] = useState<string>('');
  const [accountAddress, setAccountAddress] = useState<string>('');

  const shouldShowField = (fieldName: FormFieldKey) => {
    const excludedDeliveryKeys = excludedDeliveryFieldKeysMap[deliveryMethod];
    const excludedAddressKeys = excludedAddressFieldKeysMap[deliveryAddress];

    return !(excludedDeliveryKeys?.includes(fieldName) || excludedAddressKeys?.includes(fieldName));
  };

  const customRequiredFieldValidation = (fieldName: string, customFieldName?: string) => {
    return Yup.string().test(
      'required',
      t('errors.required', { value: t(`fields.${customFieldName ?? fieldName}`) }) ?? '',
      function (value) {
        const shouldShow = shouldShowField(fieldName);

        return !shouldShow || (shouldShow && !!value);
      },
    );
  };

  const today = new Date();
  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...companyname,
      disabled: true,
    },
    {
      ...firstname,
      initialValue: contact?.firstname ?? '',
      disabled: true,
    },
    {
      ...lastname,
      initialValue: contact?.lastname ?? '',
      disabled: true,
    },
    {
      name: 'deliverymethod',
      type: 'select',
      options: deliveryMethodOptions,
      initialValue: deliveryMethodOptions[0].value,
      required: false,
      validation: customRequiredFieldValidation('deliverymethod'),
    },
    {
      name: 'colorastore',
      type: 'select',
      options: coloraStores,
      initialValue: account?.preference.shop ?? coloraStores[0]?.value,
      required: false,
      validation: customRequiredFieldValidation('colorastore'),
    },
    {
      name: 'bossdepot',
      type: 'select',
      options: depotStores,
      initialValue: account?.preference.shop ?? depotStores[0]?.value,
      required: false,
      validation: customRequiredFieldValidation('bossdepot'),
    },
    {
      name: 'addresses',
      type: 'select',
      options: deliveryAddressOptions,
      initialValue: deliveryAddressOptions[0].value,
      required: false,
      validation: customRequiredFieldValidation('addresses'),
    },
    {
      name: 'invoiceaddress',
      type: 'select',
      options: invoiceAddresses,
      required: false,
      validation: customRequiredFieldValidation('invoiceaddress'),
    },
    {
      name: 'accountaddress',
      type: 'select',
      options: allAccountAddresses,
      required: false,
      validation: customRequiredFieldValidation('accountaddress'),
    },
    {
      name: 'new',
      type: 'address',
      fields: [street, streetnumber, bus, zipcode, city, country],
      required: false,
      colStyle: 'md:col-span-6',
    },
    {
      name: 'preferenceDate',
      type: 'date',
      initialValue: today.toISOString().split('T')[0] + 'T12:00:00',
      disclaimer: t('disclaimers.preferenceDate'),
    },
    {
      name: 'message',
      type: 'textarea',
      disclaimer: t('disclaimers.machineextrainfo'),
      label: t('fields.questionHelp'),
      required: true,
    },
    termsandconditions,
  ];

  const setValueOrEmptyString = (value: string | number): string => {
    return value?.toString() ?? '';
  };

  const setFoundAddress = (
    value: string,
    list: Address[] | undefined,
    setter: (val: string) => void,
    setValue: string | undefined,
  ) => {
    const foundAddress = list?.find((_, index) => index.toString() === value);

    if (foundAddress && value !== setValue) {
      setter(value);
      setAddress({
        street: setValueOrEmptyString(foundAddress?.street),
        streetnumber: setValueOrEmptyString(foundAddress?.streetnumber),
        zipcode: setValueOrEmptyString(foundAddress?.zipcode),
        city: setValueOrEmptyString(foundAddress?.city),
        country: setValueOrEmptyString(foundAddress?.country),
        postbox: setValueOrEmptyString(foundAddress?.postbox),
      });
    }
  };

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    const newDeliveryMethod = DELIVERY_OPTION_KEYS.find(type => type === formik.values.deliverymethod);
    const newAddressOptions = DELIVERY_ADDRESS_OPTION_KEYS.find(type => type === formik.values.addresses);

    const invoiceValue = formik.values.invoiceaddress;

    const accountValue = formik.values.accountaddress;

    const adressType = formik.values.addresses;

    if (newDeliveryMethod === 'addresses') {
      if (adressType === 'invoice') {
        setFoundAddress(invoiceValue, invoiceAddressesList, setInvoiceAddress, invoiceAddress);
      } else if (adressType === 'address') {
        setFoundAddress(accountValue, addresses, setAccountAddress, accountAddress);
      } else if (adressType === 'new') {
        setAddress({
          street: setValueOrEmptyString(formik.values.street),
          streetnumber: setValueOrEmptyString(formik.values.streetnumber),
          zipcode: setValueOrEmptyString(formik.values.zipcode),
          city: setValueOrEmptyString(formik.values.city),
          country: setValueOrEmptyString(formik.values.country),
          postbox: setValueOrEmptyString(formik.values.postbox),
        });
      }
    }

    if (newDeliveryMethod && deliveryMethod !== newDeliveryMethod) {
      setDeliveryMethod(newDeliveryMethod);
    }

    if (newAddressOptions && deliveryAddress !== newAddressOptions) {
      setDeliveryAddress(newAddressOptions);
    }
  };

  const handleSubmit = (vals: FormValues) => {
    let formObject = {};

    if (address && vals.deliverymethod === 'addresses') {
      formObject = {
        ...vals,
        ...address,
      };
    } else {
      formObject = {
        ...vals,
        street: '',
        streetnumber: '',
        zipcode: '',
        city: '',
        country: '',
        bus: '',
        deliverywarehouseid: vals.deliverymethod === 'bossdepot' ? vals.bossdepot : vals.colorastore,
      };
    }

    onSubmit(type, formObject);
  };

  const formFields = buildFormFields(
    baseFields,
    initialFieldsToShow ?? FORM_FIELD_KEYS,
    additionalFields,
    fieldsOverwrite,
  );

  return (
    <DynamicForm
      buttonProps={{
        label: t('buttons.submitQuestion') ?? '',
      }}
      className={className}
      disclaimer={t('disclaimers.machinerepairdisclaimer')}
      fields={formFields}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onFormValuesChange={handleFormValuesChange}
      onSubmit={handleSubmit}
      shouldShowField={shouldShowField}
      variant="light"
    />
  );
};

export default MachineRepairForm;
