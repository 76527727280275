import { useMutation, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { toast } from 'sonner';

import { getEvent, participateEvent } from './connector';
import { useProfile } from '../../hooks';

import { eventKeys } from '.';

const useParticipateEvent = ({
  toasts,
}: {
  toasts?: {
    success: { title: string; description: string };
    error: { title: string; description: string };
  };
}) => {
  const { t } = useTranslation('common');
  const { data: profile, isLoggedIn } = useProfile();
  const { locale } = useRouter();

  return useMutation({
    mutationFn: async ({ eventId, formData }: { eventId: string; formData: Record<string, unknown> }) =>
      await participateEvent({
        eventId,
        data: {
          ...formData,
          contactpersonid: profile?.extension_ContactPersonId,
        },
        isLoggedIn,
        locale: locale as string,
      }),
    onSuccess: () => {
      if (toasts?.success) {
        toast.success(toasts.success.title, { description: toasts.success.description });
      }
    },
    onError: () => {
      if (toasts?.error) {
        toast.error(toasts.error.title, { description: toasts.error.description });
      } else {
        toast.error(t('participate.error', { ns: 'event' }));
      }
    },
  });
};

const useGetEventById = (eventId: string) => {
  const { locale } = useRouter();

  return useQuery({
    queryKey: eventKeys.eventById(locale as string, eventId),
    queryFn: () => getEvent({ eventId, locale: locale as string }),
  });
};

export { useParticipateEvent, useGetEventById };
