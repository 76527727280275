import { types } from '@boss/boss-client';
import { IOrder, IOrderAddress, IOrderLine } from '@boss/types/b2b-b2c';

export const mapOrder = (order: types.order.Order): IOrder => ({
  orderId: order.salesid,
  orderDate: order.createddatetime,
  modeOfDelivery: order.deliverymode,
  customerReference: order.customerref,
  status: order.salesstatus,
  addresses: order.addresses?.map(mapClientOrderAddress) ?? [],
  lines: order.lines.map(mapClientOrderLine),
  delivery: {
    contact: order.delivery.contactfordelivery,
    info: order.delivery.info,
  },
  invoice: {
    separateForOrder: order.invoice.separatefororder,
    info: order.invoice.info,
  },
  distributionDescription: order.distributiondescription,
  deliveryLocation: order.deliverylocation,
  worksiteDiscountCode: order.worksitediscountcode,
  worksiteDiscountDescription: order.worksitediscountdescription,
});

const mapClientOrderAddress = (address: types.order.OrderAddress): IOrderAddress => ({
  role: address.role,
  street: address.street,
  streetNumber: address.streetnumber,
  postbox: address.postbox,
  zipcode: address.zipcode,
  city: address.city,
  countryRegionId: address.countryregionid,
});

const mapClientOrderLine = (line: types.order.OrderLine): IOrderLine => ({
  itemId: line.itemid,
  name: line.name,
  quantity: line.quantity.salesqty,
  unit: line.quantity.salesunit,
  colorCode: line.colorcode,
  colorId: line.colorid,
  colorName: line.colorname,
  rgb: line.rgb,
  status: line.salesstatus,
  remainSalesPhysical: line.quantity.remainsalesphysical,
  deliveredQuantity: line.quantity.deliveredquantity,
  secondaryUnit: line.quantity.secundaryunit,
  secondaryQuantity: line.quantity.secundaryqty,
  colorTypeGroups: line.colortypegroups,
});
