// TODO: Rename to fixtures and colocate. Even if duplicate code arises.
// Also, use the JSON format rather than TS. Api responses are JSON as well,
// not TS files.

import { accountMock, appointmentsMock, invoicesMock } from '../../__mocks__/account';
import { account } from '../../types';
import { buildUrlWithQuery } from '../../utils';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };
const MOCK_ACCOUNT_API = process.env.NEXT_PUBLIC_MOCK_ACCOUNT_API === 'true';
const HEADERS = {
  'Content-Type': 'application/json',
};

type GetParameters = { accountId: string; locale: string };

/**
 * Fetch an account by id
 */
export const getAccount = ({ accountId, locale }: GetParameters) => {
  if (MOCK_ACCOUNT_API) {
    return Promise.resolve(accountMock as unknown as account.Account);
  }
  return bossApi<account.Account>(`/account/v2/accounts/${accountId}`, { ...DEFAULT_OPTIONS, locale });
};

/**
 * Fetch an accounts appointments by id
 */
export const getAppointments = ({ accountId, locale }: GetParameters) => {
  if (MOCK_ACCOUNT_API) {
    return Promise.resolve(appointmentsMock as unknown as account.Appointments);
  }

  return bossApi<account.Appointments>(`/account/v2/accounts/${accountId}/appointments`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

const convertToDoubleDigit = (value: number) => {
  return value >= 10 ? value : `0${value}`;
};

// Formatting date for API usage. BOSS API required this sort of format eg: 2024-03-05
const formatDateForWorksites = () => {
  const workingDate = new Date();

  return `${workingDate.getFullYear()}-${convertToDoubleDigit(workingDate.getMonth() + 1)}-${convertToDoubleDigit(
    workingDate.getDate(),
  )}`;
};

/**
 * Fetch an acocunts worksites by id. Since we need to provide the current date, we format it and return it as query param.
 */
export const getWorksites = ({ accountId, locale }: { accountId: string; locale: string }) => {
  return bossApi<account.Worksites>(`/account/v2/accounts/${accountId}/worksites?date=${formatDateForWorksites()}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Update an account by id
 */
export const updateAccount = ({ body, locale }: { accountId: string; body: account.Account; locale: string }) => {
  return bossApi<account.Account>(`/account/v2/accounts`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    locale,
    body: body as unknown as Record<string, unknown>,
  });
};

/**
 * Update paintguide results in an account
 */
type UpdatePaintguideResultsBody = { accountnumber: string; url: string; description: string };
export const updatePaintguideResults = ({ body, locale }: { body: UpdatePaintguideResultsBody; locale: string }) => {
  return bossApi<UpdatePaintguideResultsBody>(`/account/v2/paintguideresults`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    locale,
    body: {
      ...body,
      creationdatetime: new Date().toISOString(),
    },
  });
};

/**
 * Fetch paintguide results from an account
 */
export const getPaintguideResults = ({ accountId, locale }: GetParameters) => {
  return bossApi<account.PaintguideResults>(`/account/v2/paintguideresults?accountId=${accountId}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Get all contacts of a account
 */
export const getContacts = async ({ accountId, locale }: GetParameters) => {
  return await bossApi<account.Contact[]>(`/account/v2/contacts?accountid=${accountId}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

const formatDate = (val: string | undefined) => {
  if (!val) {
    return;
  }
  const data = new Date(val);

  // Get the year, month, and day from the Date object
  const year = data.getFullYear();
  const month = String(data.getMonth() + 1).padStart(2, '0');
  const day = String(data.getDate()).padStart(2, '0');

  // Format the date as YYYY-MM-DD
  return `${year}-${month}-${day}`;
};

/**
 * Update a contact
 */
export const updateContact = async ({ contact, locale }: { contact: account.Contact; locale: string }) => {
  return bossApi<account.Contact>(`/account/v2/contacts`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    locale,
    body: contact as unknown as Record<string, unknown>,
  });
};

/**
 * Get all invoices
 */

export const getInvoices = async ({
  accountId,
  locale,
  fromDate,
  toDate,
  limit,
  offset,
  paid,
}: {
  accountId: string;
  locale: string;
  fromDate?: string;
  toDate?: string;
  limit?: number;
  offset?: number;
  paid?: boolean;
}) => {
  if (MOCK_ACCOUNT_API) {
    return Promise.resolve(invoicesMock as unknown as account.InvoiceResults);
  }

  const params = {
    paid,
    skip: offset,
    limit,
    fromDate: formatDate(fromDate),
    toDate: formatDate(toDate),
  };

  const url = buildUrlWithQuery(`/account/v2/accounts/${accountId}/invoices`, params);

  return await bossApi<account.InvoiceResults>(url, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Get orders
 */
export const getOrders = async ({
  accountId,
  locale,
  fromDate,
  toDate,
  limit,
  offset,
  orderId,
}: {
  accountId: string;
  locale: string;
  fromDate?: string;
  toDate?: string;
  limit?: number;
  offset?: number;
  orderId?: string;
}) => {
  const params = {
    limit: limit ?? 1000,
    skip: offset ?? 0,
    fromDate: formatDate(fromDate),
    toDate: formatDate(toDate),
    orderId,
  };

  const url = buildUrlWithQuery(`/account/v2/accounts/${accountId}/orders`, params);

  return await bossApi<{
    orders: account.Order[];
    count: number;
  }>(url, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Get inProcess orders
 */
export const getInProcessOrders = async ({
  accountId,
  locale,
  limit,
  offset,
  type,
}: {
  accountId: string;
  locale: string;
  fromDate?: string;
  toDate?: string;
  limit?: number;
  offset?: number;
  // type is either 1 (ToDeliverLines) or 2 (OpenOrderLines)
  type: 1 | 2;
}) => {
  const params = {
    limit: limit ?? 1000,
    skip: offset ?? 0,
    type,
  };

  const url = buildUrlWithQuery(`/account/v2/accounts/${accountId}/orders/inprocess`, params);

  return await bossApi<{
    orders: account.Order[];
    count: number;
  }>(url, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Fetch all advices for an account
 */
export const getAdvices = ({ accountId, locale }: GetParameters) => {
  return bossApi<account.Advices>(`/account/v2/accounts/${accountId}/advices`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Fetch account discount info by type
 */
export const getAccountDiscountInfo = ({
  accountId,
  type,
  locale,
}: {
  accountId: string;
  locale: string;
  type?: account.AccountDiscountInfoType;
}) => {
  const queryParameters = type ? `?accountDiscountInfoType=${type}` : '';

  return bossApi<account.AccountDiscountInfo[]>(`/account/v2/accounts/${accountId}/discountinfos${queryParameters}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Save a subscription for an account
 */

export const saveSubscription = async ({
  email,
  body,
  isLoggedIn,
  locale,
}: {
  email: string;
  body: account.SubscriptionBody;
  isLoggedIn?: boolean;
  locale: string;
}) => {
  const baseUrl = isLoggedIn ? '/account/v2/subscriptions' : '/guest/account/v2/subscriptions';

  const url = buildUrlWithQuery(baseUrl, { email });

  return bossApi<void>(url, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    locale,
    body: body as unknown as Record<string, unknown>,
  });
};
