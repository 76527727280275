import { QueryKey, UseMutationResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { useRouter } from 'next/router';
import { toast } from 'sonner';

import { IWishlist, IWishlistLine, IWishlists, TWishlistType } from '@boss/services/client';

import {
  createWishlist,
  createWishlistLine,
  createWishlistLines,
  deleteWishlist,
  deleteWishlistLine,
  fetchWishlists,
  updateWishlist,
  updateWishlistLine,
} from './connector';
import { useProfile } from '../../hooks';

import { wishlistKeys } from '.';

type Toast = {
  title: string;
  description?: string;
};

type Props = {
  toasts?: {
    error?: Toast;
    success?: Toast;
  };
};

interface WishlistMutationParams {
  wishlistLine: IWishlistLine;
  wishlistId: string;
  wishlistType: TWishlistType;
}

/**
 * useQuery implementation to fetch wishlist.
 *
 * @param {string} locale
 * @returns {Promise<IWishlists>}
 */
export const useWishlists = () => {
  const profile = useProfile();
  const accountId = profile.data?.extension_AccountId?.toString() || '';
  const { locale } = useRouter();

  return useQuery<IWishlists>({
    queryKey: wishlistKeys.wishlists(accountId),
    queryFn: async () => await fetchWishlists({ accountId: accountId || '', locale: locale as string }),
    enabled: !!accountId,
  });
};

/**
 * Custom React hook for creating a new wishlist using React Query's useMutation.
 * This hook is designed to handle the creation of a wishlist and manage the server state.
 *
 * @returns An object containing a mutate function to trigger the mutation and the status of the mutation.
 */
export const useCreateWishlist = ({ toasts }: Props) => {
  // Retrieve the current query client instance for managing server state.
  const queryClient = useQueryClient();

  // Fetch the current user's profile data.
  const profile = useProfile();

  // Extract the account ID from the user's profile, defaulting to an empty string if not found.
  const accountid: string = profile.data?.extension_AccountId?.toString() || '';

  // Define the query key for fetching and updating wishlist data.
  const queryKey: QueryKey = wishlistKeys.wishlists(accountid);

  const { locale } = useRouter();

  // Use the useMutation hook to handle the wishlist creation process.
  return useMutation<IWishlist, Error, IWishlist, { previousWishlistData: IWishlists | undefined }>({
    /**
     * Function to perform the actual mutation (API call) for creating a new wishlist.
     *
     * @param newWishlistData The new wishlist data to be sent to the server.
     * @returns The newly created wishlist object.
     */
    mutationFn: async (newWishlistData: IWishlist) => {
      return createWishlist({
        wishlist: {
          accountid,
          ...newWishlistData,
        },
        locale: locale as string,
      });
    },

    /**
     * Callback function executed upon successful creation of the wishlist.
     *
     * @param data The wishlist object returned from the server after successful creation.
     */
    onSuccess: async (data: IWishlist) => {
      // Store the current state of wishlist data before the mutation for potential rollback.
      const previousWishlistData: IWishlists | undefined = queryClient.getQueryData<IWishlists>(queryKey);

      // Cancel any outgoing refetches for this query to prevent race conditions.
      await queryClient.cancelQueries({ queryKey });

      // Optimistically update the wishlist data with the new wishlist.
      const updatedWishlistData = produce(previousWishlistData, draft => {
        if (draft) {
          if (Array.isArray(draft[data.type])) {
            (draft[data.type] as IWishlist[]).push(data);
          } else if (['sku', 'product'].includes(data.type)) {
            (draft[data.type] as IWishlist[]) = [data];
          } else {
            (draft[data.type] as IWishlist) = data;
          }
        }
      });

      // Update the wishlist data in the query client cache.
      queryClient.setQueryData<IWishlists>(queryKey, updatedWishlistData);

      const successToast = toasts?.success;

      if (successToast) {
        toast.success(successToast.title, { description: successToast.description });
      }
      // Return the previous state for potential rollback in case of an error.
      return { previousWishlistData };
    },
  });
};

/**
 * Custom React hook to create a new line in a wishlist.
 * It leverages React Query's useMutation for server state management.
 *
 * @returns An object with a mutate function to perform the mutation,
 * and the current status of that mutation (idle, loading, error, or success).
 */
export const useCreateWishlistLine = ({
  toasts,
}: Props): UseMutationResult<
  IWishlistLine,
  Error,
  WishlistMutationParams,
  { previousWishlistData: IWishlists | undefined }
> => {
  // Fetches the current query client instance for managing server state
  const queryClient = useQueryClient();

  // Retrieves the current user's profile data
  const profile = useProfile();

  // Extracts the account ID from the user's profile, defaults to an empty string if not found
  const accountId: string = profile.data?.extension_AccountId?.toString() || '';

  // Defines the unique key for querying wishlist data
  const queryKey: QueryKey = wishlistKeys.wishlists(accountId);

  /**
   * Updates the local wishlist data optimistically.
   *
   * @param wishlistData The current wishlist data.
   * @param wishlistType The type of wishlist to update.
   * @param wishlistId The ID of the wishlist to update.
   * @param wishlistLine The new wishlist line to add or update.
   * @param addLine Flag to determine if a line should be added or updated.
   * @returns Updated wishlist data with the new or modified line.
   */
  const updateWishlistData = (
    wishlistData: IWishlists | undefined,
    wishlistType: TWishlistType,
    wishlistId: string,
    wishlistLine: IWishlistLine,
    addLine: boolean,
  ) => {
    // Creates a new draft of the wishlist data to be updated
    const updatedWishlist = produce(wishlistData?.[wishlistType], draft => {
      if (draft) {
        // Finds the specific wishlist to update
        const wishlistToUpdate = Array.isArray(draft) ? draft.find(wishlist => wishlist.id === wishlistId) : draft;

        if (wishlistToUpdate?.lines) {
          if (addLine) {
            // Adds the new line to the wishlist
            wishlistToUpdate.lines.push(wishlistLine);
          } else {
            // Updates the existing line in the wishlist
            wishlistToUpdate.lines.push(wishlistLine);
            wishlistToUpdate.lines = wishlistToUpdate.lines.filter(
              line => line.typeid !== wishlistLine.typeid || (line.typeid === wishlistLine.typeid && line.id),
            );
          }
        }
      }
    });

    return { ...wishlistData, [wishlistType]: updatedWishlist };
  };

  const { locale } = useRouter();
  // Returns the mutation object using useMutation hook

  return useMutation<IWishlistLine, Error, WishlistMutationParams, { previousWishlistData: IWishlists | undefined }>({
    mutationFn: async ({ wishlistLine, wishlistId }) => {
      return createWishlistLine({
        wishlistLine,
        wishlistId,
        locale: locale as string,
      });
    },
    onMutate: async ({ wishlistType, wishlistId, wishlistLine }) => {
      // Before the mutation, store the current state for potential rollback
      const previousWishlistData: IWishlists | undefined = queryClient.getQueryData<IWishlists>(queryKey);

      // Cancel any outgoing refetches for this query
      await queryClient.cancelQueries({ queryKey });

      // Optimistically update to the new value
      const newWishlistData = updateWishlistData(previousWishlistData, wishlistType, wishlistId, wishlistLine, true);

      // Set the optimistic response in the cache
      queryClient.setQueryData(queryKey, newWishlistData);

      return { previousWishlistData };
    },
    onSuccess: async (data, { wishlistType, wishlistId }) => {
      // On success, update the query data with the actual response
      const previousWishlistData = queryClient.getQueryData<IWishlists>(queryKey);

      await queryClient.cancelQueries({ queryKey });

      const newWishlistData = updateWishlistData(previousWishlistData, wishlistType, wishlistId, data, false);

      queryClient.setQueryData(queryKey, newWishlistData);

      const successToast = toasts?.success;

      if (successToast) {
        toast.success(successToast.title, { description: successToast.description });
      }
    },
    onError: (error, variables, context) => {
      // On failure, roll back to the previous data
      queryClient.setQueryData<IWishlists>(queryKey, context?.previousWishlistData);
    },
  });
};

/**
 * useMutation implementation to delete a wishlist line.
 *
 * @returns {Object} An object with a mutate function and mutation status
 */
export const useDeleteWishlistLine = (options: Props = {}) => {
  const queryClient = useQueryClient();
  const profile = useProfile();
  const accountid = profile.data?.extension_AccountId?.toString() || '';
  const queryKey = wishlistKeys.wishlists(accountid);

  const { locale } = useRouter();

  return useMutation({
    mutationFn: async ({
      wishlistLineId,
      wishlistId,
    }: {
      wishlistLineId: string;
      wishlistId: string;
      wishlistType: TWishlistType;
      delay?: boolean;
    }) => {
      return deleteWishlistLine({
        wishlistLineId,
        wishlistId,
        locale: locale as string,
      });
    },
    onMutate: async data => {
      // Snapshot the previous value
      const previousWishlistData: IWishlists | undefined = queryClient.getQueryData(queryKey);

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // we add a timeout to show the delete animation
      setTimeout(
        () => {
          const wishListToUpdate = previousWishlistData?.[data.wishlistType];

          const updatedWishList = produce(wishListToUpdate, draft => {
            if (draft) {
              if (Array.isArray(draft)) {
                const indexWishlistToUpdate = draft.findIndex(wishlist => wishlist.id === data.wishlistId);

                draft[indexWishlistToUpdate].lines = draft[indexWishlistToUpdate].lines?.filter(
                  line => line.id !== data.wishlistLineId,
                );
              } else if (draft?.lines) {
                draft.lines = draft?.lines?.filter(line => line.id !== data.wishlistLineId);
              }
            }
          });

          const newWishlistData = {
            ...previousWishlistData,
            [data.wishlistType]: updatedWishList,
          };

          // Optimistically update to the new value
          queryClient.setQueryData(queryKey, newWishlistData);
        },
        data.delay ? 1000 : 0,
      );

      // Return a context object with the snapshotted value
      return { previousWishlistData };
    },
    onSuccess: () => {
      const successToast = options?.toasts?.success;

      if (successToast) {
        toast.success(successToast.title, { description: successToast.description });
      }
    },
    onError: (_, __, context) => {
      const errorToast = options?.toasts?.error;

      if (errorToast) {
        toast.error(errorToast.title, { description: errorToast.description });
      }
      setTimeout(() => {
        queryClient.setQueryData(queryKey, context?.previousWishlistData);
      }, 1500);
    },
  });
};

/**
 * useMutation implementation to delete a wishlist.
 *
 * @returns {Object} An object with a mutate function and mutation status
 */
export const useDeleteWishlist = () => {
  const queryClient = useQueryClient();
  const profile = useProfile();
  const accountid = profile.data?.extension_AccountId?.toString() || '';
  const queryKey = wishlistKeys.wishlists(accountid);
  const { locale } = useRouter();

  return useMutation({
    mutationFn: async ({ wishlistId, wishlistType }: { wishlistId: string; wishlistType: TWishlistType }) => {
      return deleteWishlist({
        wishlistId,
        locale: locale as string,
      });
    },
    onMutate: async data => {
      // Snapshot the previous value
      const previousWishlistData: IWishlists | undefined = queryClient.getQueryData(queryKey);
      const type = data.wishlistType;
      const id = data.wishlistId;

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      // Create a deep copy of the original wishlists object
      const newWishlists = { ...previousWishlistData };

      // Find the corresponding wishlist or wishlist array based on the type
      const wishlistOrArray = newWishlists[type];

      if (Array.isArray(wishlistOrArray)) {
        // If it's an array, filter out the wishlist with the specified ID
        (newWishlists[type] as IWishlist[]) = wishlistOrArray.filter(wishlist => wishlist.id !== id) as IWishlist[];
      } else if (wishlistOrArray && !Array.isArray(wishlistOrArray)) {
        // If it's a single wishlist, delete it if it matches the ID
        delete newWishlists[type];
      }

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, newWishlists);

      // Return a context object with the snapshotted value
      return { previousWishlistData };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousWishlistData);
    },
  });
};

/**
 * useMutation implementation to update a wishlist.
 *
 * @returns {Object} An object with a mutate function and mutation status
 */
export const useUpdateWishlist = ({ toasts }: Props) => {
  const queryClient = useQueryClient();
  const profile = useProfile();
  const accountid = profile.data?.extension_AccountId?.toString() || '';
  const queryKey = wishlistKeys.wishlists(accountid);
  const { locale } = useRouter();

  return useMutation({
    mutationFn: async (wishlist: IWishlist) => {
      return updateWishlist({
        wishlist,
        locale: locale as string,
      });
    },
    onMutate: async data => {
      // Snapshot the previous value
      const previousWishlistData: IWishlists | undefined = queryClient.getQueryData(queryKey);

      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey });

      let wishListToUpdate = previousWishlistData?.[data.type];

      if (Array.isArray(wishListToUpdate)) {
        const indexWishlistToUpdate = wishListToUpdate.findIndex(wishlist => wishlist.id === data.id);

        wishListToUpdate[indexWishlistToUpdate] = data;
      } else if (wishListToUpdate?.lines) {
        wishListToUpdate = data;
      }

      const newWishlistData = {
        ...previousWishlistData,
        [data.type]: wishListToUpdate,
        /*
         * since we update an array of wishlists, we need to add a timestamp
         * to trigger a re-render of the component
         * see: https://github.com/TanStack/query/issues/326
         */
        timestamp: new Date().getTime(),
      };

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, { ...newWishlistData });

      // Return a context object with the snapshotted value
      return { previousWishlistData };
    },
    onSuccess: () => {
      const successToast = toasts?.success;

      if (successToast) {
        toast.success(successToast.title, { description: successToast.description });
      }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousWishlistData);
    },
  });
};

/**
 * useMutation implementation to update a wishlist line.
 *
 * @returns {Object} An object with a mutate function and mutation status
 * */
export const useUpdateWishlistLine = ({ toasts }: Props) => {
  const queryClient = useQueryClient();
  const profile = useProfile();
  const accountid = profile.data?.extension_AccountId?.toString() || '';
  const queryKey = wishlistKeys.wishlists(accountid);

  /**
   * Optimistically updates the wishlist data in the cache.
   * @param {QueryKey} queryKey - The query key for the wishlist data.
   * @param {{wishlistType: TWishlistType, wishlistId: string, wishlistLine: IWishlistLine}} data - The data to update the wishlist.
   * @returns {{previousWishlistData: IWishlists | undefined} | null} An object containing the previous wishlist data or null if it doesn't exist.
   */
  const updateWishlistDataOptimistically = (
    queryKey: QueryKey,
    data: {
      wishlistType: TWishlistType;
      wishlistId: string;
      wishlistLine: IWishlistLine;
    },
  ): { previousWishlistData: IWishlists | undefined } | null => {
    const previousWishlistData: IWishlists | undefined = queryClient.getQueryData(queryKey);

    if (!previousWishlistData) {
      return null;
    }

    const updatedWishlistData = produceWishlistData(previousWishlistData, data);

    queryClient.cancelQueries({ queryKey });
    queryClient.setQueryData(queryKey, updatedWishlistData);

    return { previousWishlistData };
  };

  /**
   * Produces the updated wishlist data optimistically.
   * @param {IWishlists} previousData - The previous wishlist data.
   * @param {{wishlistType: TWishlistType, wishlistId: string, wishlistLine: IWishlistLine}} data - The data to update the wishlist.
   * @returns {IWishlists} The updated wishlist data.
   */
  const produceWishlistData = (
    previousData: IWishlists,
    data: {
      wishlistType: TWishlistType;
      wishlistId: string;
      wishlistLine: IWishlistLine;
    },
  ): IWishlists => {
    const { wishlistType, wishlistId, wishlistLine } = data;
    const wishListToUpdate = previousData[wishlistType];

    if (!wishListToUpdate) {
      return previousData;
    }

    const updatedWishList = produce(wishListToUpdate, draft => {
      if (Array.isArray(draft)) {
        const indexWishlistToUpdate = draft.findIndex(wishlist => wishlist.id === wishlistId);

        if (indexWishlistToUpdate !== -1) {
          const indexLineToUpdate = draft[indexWishlistToUpdate].lines?.findIndex(line => line.id === wishlistLine.id);

          if (indexLineToUpdate !== -1) {
            draft[indexWishlistToUpdate].lines[indexLineToUpdate] = wishlistLine;
          }
        }
      } else if (draft.lines) {
        const indexLineToUpdate = draft.lines.findIndex(line => line.id === wishlistLine.id);

        if (indexLineToUpdate !== -1) {
          draft.lines[indexLineToUpdate] = wishlistLine;
        }
      }
    });

    return { ...previousData, [wishlistType]: updatedWishList };
  };

  const { locale } = useRouter();

  return useMutation({
    mutationFn: async ({
      wishlistLine,
      wishlistId,
    }: {
      wishlistLine: IWishlistLine;
      wishlistId: string;
      wishlistType: TWishlistType;
    }) => {
      return updateWishlistLine({
        wishlistLine,
        wishlistId,
        locale: locale as string,
      });
    },
    onMutate: data => {
      return updateWishlistDataOptimistically(queryKey, data);
    },
    onSuccess: () => {
      const successToast = toasts?.success;

      if (successToast) {
        toast.success(successToast.title, { description: successToast.description });
      }
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousWishlistData);
    },
  });
};

/**
 * Create Wishlist Lines
 * Is not set to update optimistically, as it is not needed in it's current use case
 *
 */
export const useCreateWishlistLines = ({ toasts }: Props) => {
  const { locale } = useRouter();

  return useMutation({
    mutationFn: async ({ wishlistLines, wishlistId }: { wishlistLines: IWishlistLine[]; wishlistId: string }) => {
      return createWishlistLines({
        wishlistLines,
        wishlistId,
        locale: locale as string,
      });
    },
    onSuccess: () => {
      const successToast = toasts?.success;

      if (successToast) {
        toast.success(successToast.title, { description: successToast.description });
      }
    },
    onError: error => {
      const errorToast = toasts?.error;

      if (errorToast) {
        toast.error(errorToast.title, { description: errorToast.description });
      }
      console.error(error);
    },
  });
};
