import { faBasketShopping, faFileLines } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';

import { ProductPanelInstanceProps } from '..';
import { Button, Link, Presence, Price, Specifications } from '../..';
import ProductPanelFooterSkeleton from '../../ProductPanelFooterSkeleton';
const SecondaryProductPanel = ({
  className,
  translations,
  table,
  price,
  deliveryPromises,
  specifications,
  inStock,
  calculator,
  addToBasket,
  addToWishlist,
  openOrderForm,
  disclaimerLink,
  colorPicker,
  isLoading,
  alert,
  info,
  specialDecoAlert,
  showProductActions,
  alternativeActions,
  hasArticles,
  isLoggedIn,
  isBasketUpdateLoading,
  noArticlesAvailable,
}: ProductPanelInstanceProps) => {
  if (noArticlesAvailable) {
    return (
      <div className="shadow-m p- flex flex-col gap-5 bg-white p-5">
        <div className="text-red text-center text-xl font-medium">{translations.noArticlesAvailableTitle}</div>
      </div>
    );
  }

  return (
    <Presence
      className={twMerge('flex flex-col shadow-sm', className)}
      id="product-panel-footer-presence"
      isLoading={isLoading}
      loader={<ProductPanelFooterSkeleton className="flex flex-col gap-6 px-8 py-6" />}
      visible
    >
      {hasArticles && isLoggedIn && (
        <div className={twMerge('flex flex-col gap-8 bg-white px-8', showProductActions ? 'py-6' : 'pt-6')}>
          {showProductActions ? (
            <>
              {colorPicker}
              {calculator}
            </>
          ) : null}
          {table}
        </div>
      )}
      <div
        className={twMerge('bg-paint-coloredBackground flex flex-col gap-6 px-8', showProductActions ? 'py-6' : 'pt-0')}
      >
        {price && showProductActions && hasArticles && (
          <>
            {price.strikePrice && (
              <div className="flex items-center justify-between gap-2">
                <span>{translations.grossPriceLabel}</span>
                <Price className="[&>*]:small font-brandon line-through" size="medium" value={price.strikePrice} />
              </div>
            )}

            <div className="flex flex-col gap-2.5">
              {price.discountPercentage && (
                <div className="text-price-highlight flex items-center justify-between gap-2 font-bold">
                  <span>{translations.discountLabel}</span>
                  <span>- {price.discountPercentage}%</span>
                </div>
              )}

              <div className="flex items-center justify-between gap-2 rounded bg-white px-4 py-1">
                <span className="h4 font-bold">{translations.priceLabel}</span>
                <Price className="[&>*]:text-price-highlight [&>span]:font-brandon" size="large" value={price.value} />
              </div>
            </div>
          </>
        )}

        {specialDecoAlert}

        {hasArticles ? (
          <>
            {disclaimerLink && hasArticles && (
              <span className="caption italic">
                {translations.disclaimer ?? ''}{' '}
                {disclaimerLink && (
                  <Link className="caption italic underline" href={disclaimerLink}>
                    {translations.disclaimerLink}
                  </Link>
                )}
              </span>
            )}
            {showProductActions ? (
              <div className="flex flex-col gap-2">
                <Button
                  className="min-h-16 w-full justify-center px-6 py-4 text-center  text-lg font-bold"
                  disabled={!inStock || isBasketUpdateLoading}
                  icon={faBasketShopping}
                  iconPosition="right"
                  label={inStock ? translations.button.addToBasket : translations.button.outOfStock}
                  labelClassName="w-auto"
                  onClick={addToBasket}
                  type="primary"
                />
                {addToWishlist && (
                  <Button
                    className="min-h-16 border-1 border-blue-dark [&>*]:text-blue-dark w-full justify-center bg-white text-center font-bold"
                    icon={faFileLines}
                    iconPosition="right"
                    label={translations.button.addToWishlist}
                    labelClassName="w-auto"
                    onClick={addToWishlist}
                    type="primary"
                  />
                )}
              </div>
            ) : (
              <>{alternativeActions}</>
            )}

            {alert}

            {info}
          </>
        ) : (
          <>
            {isLoggedIn ? (
              <Button
                className="min-h-16 w-full justify-center text-center text-lg font-bold [&>*]:w-fit"
                label={translations.button.specialForm}
                onClick={openOrderForm}
                type="primary"
              />
            ) : (
              <>{alternativeActions}</>
            )}
          </>
        )}

        {showProductActions ? (
          <>
            {deliveryPromises}
            {specifications && (
              <>
                <div className="border-b-3 border-b-red-ghost w-full border-dotted text-4xl" />
                <Specifications border={false} className="gap-4 md:grid md:grid-cols-2" items={specifications} />
              </>
            )}
          </>
        ) : null}
      </div>
    </Presence>
  );
};

export default SecondaryProductPanel;
