import { SearchClient } from 'algoliasearch';
import { ReactNode } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

import { getAlgolia } from '@boss/algolia-client';
import { DEFAULT_PRODUCT_GRID_SIZE } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';

import { FACET_FILTERS_BASE } from '../../../constants';
import { getRouting } from '../../../utils';
import VirtualRefinements from '../VirtualRefinements';
import VirtualSearchBox from '../VirtualSearchBox';

type ServerSearchProps = {
  url?: string;
  children?: ReactNode;
  pageSize?: number;
  facetFilters?: string;
  indexName: string;
  searchClient?: SearchClient;
  query?: string;
  disableRouting?: boolean;
};

const Search = ({
  children,
  pageSize = DEFAULT_PRODUCT_GRID_SIZE,
  facetFilters = FACET_FILTERS_BASE,
  indexName,
  query,
  searchClient = getAlgolia({}).algoliaClient,
  disableRouting,
}: ServerSearchProps) => {
  const { query: routerQuery } = useRouter();
  const searchQuery =
    query ?? (routerQuery.q && Array.isArray(routerQuery.q) ? routerQuery.q.join(' ') : routerQuery.q) ?? '';

  const defaultFilter = 'NOT status:archived';
  const combinedFilters = facetFilters ? `${defaultFilter} AND (${facetFilters})` : defaultFilter;

  return (
    <InstantSearch
      future={{ preserveSharedStateOnUnmount: true }}
      indexName={indexName}
      key={indexName}
      routing={!disableRouting ? getRouting(indexName) : undefined}
      searchClient={searchClient}
    >
      <VirtualSearchBox indexName={indexName} query={searchQuery} />
      <VirtualRefinements />
      <Configure filters={combinedFilters} hitsPerPage={pageSize} />
      {children}
    </InstantSearch>
  );
};

export default Search;
