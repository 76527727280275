import {
  IconDefinition,
  faBars,
  faBoxOpen,
  faCalendar,
  faEnvelopeOpen,
  faFileCircleCheck,
  faFileInvoiceDollar,
  faGear,
  faHeart,
  faIdBadge,
  faList,
  faListCheck,
  faMessage,
  faPaintRoller,
  faPalette,
  faRightFromBracket,
  faTable,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useLockedBody } from 'usehooks-ts';

import { logout } from '@boss/auth';
import { useIsResponsive } from '@boss/hooks';
import { IconCard, Image, Link, Portal } from '@boss/ui';
import { TAccountPageType, getAccountPageSlugByType } from '@boss/utils';

import { accountNavigation } from '../../utils/options';

type Props = {
  locale: string;
  currentPage: string | undefined;
};

const iconMap: Record<TAccountPageType, IconDefinition> = {
  DASHBOARD: faTable,
  ORDERS: faBoxOpen,
  INVOICES: faFileInvoiceDollar,
  QUOTATION: faEnvelopeOpen,
  LIST: faList,
  DISCOUNT: faIdBadge,
  ADVICE: faFileCircleCheck,
  INFO: faGear,
  APPOINTMENTS: faCalendar,
  COLORS: faPalette,
  CONTACT: faMessage,
  PLANS: faListCheck,
  PROJECTS: faHeart,
  LOGOUT: faRightFromBracket,
  PAINTGUIDES: faPaintRoller,
  PRICE_LIST: faList,
};

type NavLinkProps = {
  icon: IconDefinition;
  label: string;
  href?: string;
  onClick?: () => void;
  active: boolean;
};

const NavLinkStyles = cva('width-full relative flex items-center py-2.5', {
  variants: {
    variant: {
      primary:
        'md:[&>span]:max-w-50 [&>span]:hover:font-bold [&>span]:before:block [&>span]:before:h-0 [&>span]:before:content-[attr(title)] [&>span]:before:font-bold [&>span]:before:overflow-hidden [&>.next-image]:opacity-0 [&:hover>.next-image]:opacity-100 [&>.next-image]:transition-[opacity_0.2s] [&>.next-image]:will-change-opacity [&>.next-image]:transform-gpu',
      secondary:
        'md:[&>span]:max-w-46 hover:text-white [&>.next-image]:opacity-0 [&:hover>.next-image]:opacity-100 [&>.next-image]:transition-[opacity_0.2s] [&>.next-image]:will-change-opacity [&>.next-image]:transform-gpu',
    },
    active: {
      primary: '[&>span]:font-bold [&>.next-image]:opacity-100',
      secondary: 'text-white [&>.next-image]:opacity-100',
      false: '',
    },
  },
});

const NavButtonStyles = cva('ml-auto flex items-center gap-2 text-[#132D88] md:mr-7 md:mt-10 lg:hidden', {
  variants: {
    variant: {
      primary: '-mt-10',
      secondary: '-mt-16.5',
    },
  },
});

const NavigationLink = ({ icon, label, href, onClick, active }: NavLinkProps) => {
  const variant = accountNavigation.navigationType;

  return (
    <Link className={NavLinkStyles({ variant, active: active ? variant : false })} href={href} onClick={onClick}>
      <FontAwesomeIcon className="z-10 w-5" icon={icon} />
      <span className="z-10 inline-block pl-2 pr-6">{label}</span>
      <Image
        alt="link background"
        className="next-image absolute z-0 -ml-5 block h-full object-fill opacity-0"
        height={100}
        src={variant === 'primary' ? '/stripes/stripe.png' : '/background_nav_link.png'}
        useNext
        width={100}
      />
    </Link>
  );
};

const AccountNav = ({ locale, currentPage }: Props) => {
  const [openPortal, setOpenPortal] = useState(false);
  const variant = accountNavigation.navigationType;
  const isMobile = useIsResponsive('lg');
  const router = useRouter();
  const { t } = useTranslation('account');

  useLockedBody(openPortal, 'root');

  const onClickMap: Partial<Record<TAccountPageType, () => void>> = {
    LOGOUT: () => logout('/'),
  };

  const getAccountPageOnClickByType = (pageType: TAccountPageType) => onClickMap[pageType];

  const mapLinks = (pageType: TAccountPageType) => (
    <NavigationLink
      active={pageType === currentPage}
      href={getAccountPageSlugByType(pageType, locale)}
      icon={iconMap[pageType]}
      key={pageType}
      label={t(`general.navigation.${pageType}`)}
      onClick={getAccountPageOnClickByType(pageType)}
    />
  );

  const mapMobileLinks = (pageType: TAccountPageType) => (
    <Link
      className="w-full"
      href={getAccountPageSlugByType(pageType, locale)}
      onClick={getAccountPageOnClickByType(pageType)}
    >
      <IconCard className="[&>span]:md:small" icon={iconMap[pageType]} title={t(`general.navigation.${pageType}`)} />
    </Link>
  );

  useEffect(() => {
    const handleRouteChange = () => setOpenPortal(false);

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    if (openPortal && !isMobile) {
      setOpenPortal(false);
    }
  }, [isMobile, openPortal]);

  return (
    <>
      <div className="md:w-72.25 hidden flex-col p-5 pt-10 lg:flex">
        <div className="mb-10">{accountNavigation.mainNav.map(mapLinks)}</div>
        {accountNavigation.atelierNav?.length && (
          <div className="mb-10">{accountNavigation.atelierNav?.map(mapLinks)}</div>
        )}
        {accountNavigation.personalNav?.length && <div>{accountNavigation.personalNav?.map(mapLinks)}</div>}
      </div>

      <button className={NavButtonStyles({ variant })} onClick={() => setOpenPortal(true)}>
        <FontAwesomeIcon icon={faBars} size="xl" />
      </button>

      {openPortal && (
        <Portal>
          <div className="z-nav fixed left-0 top-0 flex flex h-screen w-screen flex-col flex-col gap-6 overflow-y-auto bg-white p-4 lg:hidden">
            <div className="flex items-center justify-between">
              <h4 className="text-xl font-bold">{t('general.myAccount')}</h4>
              <button className="ml-auto flex items-center gap-2" onClick={() => setOpenPortal(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>

            <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
              {accountNavigation.mainNav.map(mapMobileLinks)}
            </div>
            {!!accountNavigation.atelierNav?.length && (
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
                  {accountNavigation.atelierNav.map(mapMobileLinks)}
                </div>
              </div>
            )}
            {!!accountNavigation.personalNav?.length && (
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
                  {accountNavigation.personalNav.map(mapMobileLinks)}
                </div>
              </div>
            )}
          </div>
        </Portal>
      )}
    </>
  );
};

export default AccountNav;
