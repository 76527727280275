import deepMerge from 'deepmerge';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { IAccount } from '@boss/services/client';
import { Alert, Button, Presence, SearchDropdown } from '@boss/ui';

import ContactInfoBlocks from './ContactInfoBlocks';
import { useContacts, useSaveSubscription, useUpdateAccount, useUpdateContact } from '../../../client-queries';
import { AccountSkeleton } from '../../../components';
import { FormValues } from '../../../components/DynamicForm';
import { usePointOfSale, useProfile } from '../../../hooks';
import { isB2b } from '../../../utils';

type Props = {
  account: IAccount;
  className?: string;
};

const AccountInfoPage = ({ account, className }: Props) => {
  const { t } = useTranslation('account');
  const { locale } = useRouter();
  const searchOption = t('info.option');
  const searchLabel = t('info.searchLabel');
  const { stores, isLoading: storesLoading } = usePointOfSale(3);
  const { data: contacts, isLoading: contactsLoading } = useContacts(locale as string);
  const { data: profile, status: profileStatus } = useProfile();

  const { mutate: updateAccount, isError: updateAccountError, isLoading: updateAccountLoading } = useUpdateAccount();
  const { mutate: updateContact, isError: updateContactError, isLoading: updateContactLoading } = useUpdateContact();
  const { mutate: saveSubscription, isLoading: saveSubscriptionLoading } = useSaveSubscription();

  const profileLoading = profileStatus !== 'idle';

  const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(account?.preference?.onlinecommunication);
  const [subscribedToMagazine, setSubscribedToMagazine] = useState(account?.preference?.offlinecommunication);

  //Beware! different id is needed depending on b2c/b2b
  const contactInfo = contacts?.find(
    contact => contact.id === (isB2b ? profile?.extension_ContactPersonId : profile?.extension_AccountId),
  );

  const toggleNewsletterSubscription = () => {
    if (contactInfo?.email) {
      saveSubscription(
        {
          email: contactInfo?.email,
          subscribeToNewsletter: !subscribedToNewsletter,
          subscribeToMagazine: subscribedToMagazine,
          successMessage: t(
            !subscribedToNewsletter ? 'toast.saveSubscription.success.title' : 'toast.deleteSubscription.success.title',
            {
              ns: 'common',
            },
          ) as string,
        },
        {
          onSuccess: () => setSubscribedToNewsletter(!subscribedToNewsletter),
        },
      );
    }
  };

  const toggleMagazineSubscription = () => {
    if (contactInfo?.email) {
      saveSubscription(
        {
          email: contactInfo?.email,
          subscribeToMagazine: !subscribedToMagazine,
          subscribeToNewsletter: subscribedToNewsletter,
          successMessage: t(
            !subscribedToMagazine ? 'toast.saveSubscription.success.title' : 'toast.deleteSubscription.success.title',
            {
              ns: 'common',
            },
          ) as string,
        },
        {
          onSuccess: () => setSubscribedToMagazine(!subscribedToMagazine),
        },
      );
    }
  };

  const updateAccountInfo = (id: string, values: FormValues) => {
    let fieldsToUpdate = {};

    if (id === 'info') {
      fieldsToUpdate = {
        ...contactInfo,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        mobilephonenumber: values.mobilephonenumber,
        officephonenumber: values.officephonenumber,
      };

      if (contactInfo) {
        updateContact({
          id: contactInfo?.id,
          firstname: values.firstname?.toString(),
          lastname: values.lastname?.toString(),
          email: values.email?.toString(),
          mobilephonenumber: values.mobilephonenumber?.toString(),
          officephonenumber: values.phonenumber?.toString(),
        });
      }
      return;
    }

    if (id === 'deliveryAddr' || id === 'invoiceAddr') {
      const addressType = id === 'invoiceAddr' ? 'invoice' : 'delivery';

      const accountAdjustmentObject = values.name
        ? {
            name: values.name,
          }
        : {};

      fieldsToUpdate = {
        accountinfo: {
          ...account.accountinfo,
          ...accountAdjustmentObject,
        },
        addresses: [
          ...account.addresses.filter(address => address.type !== addressType),
          {
            type: addressType,
            street: values.street,
            streetnumber: values.streetnumber,
            zipcode: values.zipcode,
            postbox: values.bus,
            city: values.city,
            country: values.country,
          },
        ],
        billinginfo: {
          ...account.billinginfo,
          vatnumber: values.vatnumber || account.billinginfo?.vatnumber,
        },
      };
    }

    if (id === 'preferenceStore') {
      fieldsToUpdate = {
        preference: {
          ...account.preference,
          shop: values.shop,
        },
      };
    }

    const overwriteMerge = (_: [], sourceArray: []) => sourceArray;

    updateAccount(deepMerge(account, fieldsToUpdate, { arrayMerge: overwriteMerge }));
  };

  return (
    <Presence
      id="account-page"
      isLoading={storesLoading || contactsLoading || profileLoading}
      loader={<AccountSkeleton />}
      visible
    >
      {stores && (
        <div className={className}>
          <h1 className="mb-3">{t('info.personal')}</h1>
          {!updateAccountLoading && !updateContactLoading && (updateAccountError || updateContactError) && (
            <Alert className="my-2" type="error">
              {t('info.updateError')}
            </Alert>
          )}
          <ContactInfoBlocks account={account} contactInfo={contactInfo} onUpdate={updateAccountInfo} />
          <h4 className="mt-15 mb-3">{t('info.favorite')}</h4>
          <SearchDropdown
            onChange={id => updateAccountInfo('preferenceStore', { shop: id })}
            options={stores.map(store => ({ value: store.id, label: store.name }))}
            searchLabel={searchLabel}
            selectLabel={searchOption}
            value={account.preference?.shop}
          />
          <h4 className="mt-15 mb-3">
            {t('info.coloraMagazine')}: {subscribedToMagazine ? t('info.subscribed') : t('info.notSubscribed')}
          </h4>
          <Button
            disabled={saveSubscriptionLoading}
            label={subscribedToMagazine ? t('info.unsubscribe') : t('info.subscribe')}
            onClick={toggleMagazineSubscription}
            type="link"
          />
          {contactInfo?.email && (
            <>
              <h4 className="mt-15 mb-3">
                {t('info.newsletter')}: {subscribedToNewsletter ? t('info.subscribed') : t('info.notSubscribed')}
              </h4>
              <Button
                disabled={saveSubscriptionLoading}
                label={subscribedToNewsletter ? t('info.unsubscribe') : t('info.subscribe')}
                onClick={toggleNewsletterSubscription}
                type="link"
              />
            </>
          )}
        </div>
      )}
    </Presence>
  );
};

export default AccountInfoPage;
