import { format, formatRFC3339, setHours, setMinutes, setSeconds } from 'date-fns';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import * as Yup from 'yup';

import { useRouter } from '@boss/hooks';
import { IProduct } from '@boss/services';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';
import { Alert } from '@boss/ui';

import { useAccount, useContacts } from '../../client-queries';
import { AMOUNT_OF_DELIVERY_DATES_TO_SHOW, DEFAULT_DATE_FORMAT, INFO_TEXTAREA_ROWS } from '../../constants';
import { FormType, useFormField, useFormSubmit, usePointOfSale, useProfile } from '../../hooks';
import { buildFormFields, getXAmountOfBusinessDays } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const FORM_FIELD_KEYS = [
  'deliverymethod',
  'deliverydate',
  'clientnumber',
  'name',
  'phonenumber',
  'email',
  'colorastore',
  'bossdepot',
  'deliverySiteAddress',
  'orderreference',
  'orderinfo',
  'termsandconditions',
] as const;

type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];

type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type Props = {
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
  type?: FormType;
  product?: IProduct;
};

const DELIVERY_OPTION_KEYS = [
  'deliveryPermanentAddress',
  'pickupBossPaints',
  'pickupColora',
  'deliverySiteAddress',
] as const;

type DeliveryOptionKey = (typeof DELIVERY_OPTION_KEYS)[number];

const excludedDeliveryFieldKeysMap: Record<DeliveryOptionKey, string[]> = {
  deliveryPermanentAddress: ['colorastore', 'bossdepot', 'deliverySiteAddress'],
  pickupBossPaints: ['colorastore', 'deliveryColora', 'deliverySiteAddress'],
  pickupColora: ['bossdepot', 'deliverySiteAddress'],
  deliverySiteAddress: ['colorastore', 'bossdepot', 'pickupColora'],
};

const SpecialOrderForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type = 'special-order',
  product,
}: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, isError } = useFormSubmit();
  const { t } = useTranslation('forms');
  const {
    clientnumber,
    name,
    street,
    streetnumber,
    bus,
    zipcode,
    city,
    country,
    phonenumber,
    email,
    termsandconditions,
    orderreference,
  } = useFormField();
  const { locale } = useRouter();
  const { data: contacts } = useContacts(locale);
  const { stores } = usePointOfSale();
  const { data: account } = useAccount(locale);

  const coloraStores = stores
    .filter(store => store.storetype === 'Colora')
    .map(store => ({ value: store.id, label: store.name }));

  const depotStores = stores
    .filter(store => store.storetype === 'Depot')
    .map(store => ({ value: store.id, label: store.name }));

  const { data: profile } = useProfile();
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const deliveryMethodLabels: Record<string, string> = t('select.specialOrderDeliveryMethodOptions', {
    returnObjects: true,
  });
  const deliveryMethodOptions = DELIVERY_OPTION_KEYS.map(key => ({ value: key, label: deliveryMethodLabels[key] }));

  const today = new Date();
  const businessDays = getXAmountOfBusinessDays(today, AMOUNT_OF_DELIVERY_DATES_TO_SHOW);
  const dateOptions = businessDays.map(date => {
    return {
      label: format(date, DEFAULT_DATE_FORMAT),
      value: formatRFC3339(setHours(setMinutes(setSeconds(date, 0), 0), 12)),
    };
  });

  const shouldShowField = (fieldName: string) => {
    const excludedDeliveryKeys = excludedDeliveryFieldKeysMap[deliveryMethod];

    return !excludedDeliveryKeys?.includes(fieldName);
  };

  const customRequiredFieldValidation = (fieldName: string) => {
    return Yup.string().test(
      'required',
      t('errors.required', { value: t(`fields.${fieldName}`) }) ?? '',
      function (value) {
        const shouldShow = shouldShowField(fieldName);

        return !shouldShow || (shouldShow && !!value);
      },
    );
  };

  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryOptionKey>(deliveryMethodOptions[0].value);

  const baseFields: FormField[] = [
    {
      name: 'deliverymethod',
      type: 'select',
      options: deliveryMethodOptions,
      initialValue: deliveryMethodOptions[0].value,
    },
    {
      name: 'deliverydate',
      type: 'select',
      options: dateOptions,
      initialValue: dateOptions[0].value,
    },
    clientnumber,
    name,
    phonenumber,
    email,
    {
      name: 'colorastore',
      type: 'select',
      options: coloraStores,
      initialValue: account?.preference.shop ?? coloraStores[0]?.value,
      validation: customRequiredFieldValidation('colorastore'),
    },
    {
      name: 'bossdepot',
      type: 'select',
      options: depotStores,
      initialValue: account?.preference.shop ?? depotStores[0]?.value,
      validation: customRequiredFieldValidation('bossdepot'),
    },
    {
      name: 'deliverySiteAddress',
      type: 'address',
      required: false,
      fields: [street, streetnumber, bus, zipcode, city, country],
      colStyle: 'md:col-span-6',
    },
    orderreference,
    {
      name: 'orderinfo',
      label: t('fields.yourOrder') ?? '',
      type: 'textarea',
      initialValue:
        t('defaultValues.yourOrder', {
          cat: product?.categories[0]?.name ?? '',
          name: product?.name,
          articleNumber: product?.id,
          description: product?.description,
        }) ?? '',
      rows: INFO_TEXTAREA_ROWS,
    },
    termsandconditions,
  ];

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    const newDeliveryMethod = formik.values.deliverymethod;

    if (newDeliveryMethod && newDeliveryMethod !== deliveryMethod) {
      setDeliveryMethod(newDeliveryMethod);
    }
  };

  const handleSubmit = (vals: FormValues) => {
    const deliveryMethod = vals.deliverymethod;

    let address = {
      street: '',
      streetnumber: '',
      bus: '',
      zipcode: '',
      city: '',
      country: '',
    };

    if (deliveryMethod === 'deliverySiteAddress') {
      address = {
        street: vals.street as string,
        streetnumber: vals.streetnumber as string,
        bus: vals.bus as string,
        zipcode: vals.zipcode as string,
        city: vals.city as string,
        country: vals.country as string,
      };
    } else if (deliveryMethod === 'deliveryPermanentAddress') {
      const invoiceAddress = account?.addresses.find(address => address.type === 'invoice');

      const deliveryAddress = account?.addresses.find(address => address.type === 'delivery') ?? invoiceAddress;

      address = {
        street: deliveryAddress?.street as string,
        streetnumber: deliveryAddress?.streetnumber as string,
        bus: deliveryAddress?.postbox as string,
        zipcode: deliveryAddress?.zipcode as string,
        city: deliveryAddress?.city as string,
        country: deliveryAddress?.country as string,
      };
    }

    const coloraStore = ['pickupColora', 'deliveryColora'].includes(deliveryMethod as string) ? vals.colorastore : '';
    const bossDepot = ['pickupBossPaints', 'deliveryBossPaints'].includes(deliveryMethod as string)
      ? vals.bossdepot
      : '';

    return onSubmit(type, {
      ...vals,
      ...address,
      storeid: coloraStore || bossDepot,
      preferenceDate: vals.deliverydate,
      firstname: contact?.firstname ?? '',
      lastname: contact?.lastname ?? '',
    });
  };

  return (
    <>
      <DynamicForm
        className={className}
        fields={buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite)}
        id={type}
        isSubmitting={isSubmitting}
        onFormValuesChange={handleFormValuesChange}
        onSubmit={handleSubmit}
        shouldShowField={shouldShowField}
        variant="light"
      />
      {isSuccess && (
        <Alert className="mt-5" iconSize="xl" title={t('success.title')} type="confirm">
          {t('success.subtitle')}
        </Alert>
      )}
      {isError && (
        <Alert className="mt-8" iconSize="xl" title={t('errors.submitTitle')} type="error">
          {t('errors.submitSubtitle')}
        </Alert>
      )}
    </>
  );
};

export default SpecialOrderForm;
