import { useTranslation } from 'next-i18next';
import { useContext } from 'react';

import { trackEvents } from '@boss/constants/shared';
import { FormFields } from '@boss/types/b2b-b2c';

import { useSubmitForm } from '../../client-queries';
import { FormValues } from '../../components/DynamicForm';
import { PagePropsContext } from '../../containers/googleTagManager';
import { useEventTracker } from '../../hooks';

type BossPaintsFormType = FormFields['bossPaintsFormType'];
type ColoraFormType = FormFields['coloraFormType'];
export type FormType = NonNullable<Exclude<BossPaintsFormType, 'typeform'> | ColoraFormType>;

type FormFieldKey =
  | 'city'
  | 'clientnumber'
  | 'companyname'
  | 'country'
  | 'deliverydate'
  | 'deliverymethod'
  | 'email'
  | 'firstname'
  | 'lastname'
  | 'name'
  | 'newsletter'
  | 'orderinfo'
  | 'orderreference'
  | 'orderid'
  | 'phonenumber'
  | 'question'
  | 'salutation'
  | 'street'
  | 'streetnumber'
  | 'termsandconditions'
  | 'zipcode'
  | 'bus'
  | 'storeid'
  | 'colorastore'
  | 'bossdepot'
  | 'contactpersonid'
  | 'incidenttype'
  | 'extrainfo'
  | 'preferenceDate'
  | 'vatnumber'
  | 'numberOfParticipants'
  | 'message';

type MailApiFormType =
  | 'ColorSupport'
  | 'DecoDesign'
  | 'Events'
  | 'TechnicalAdvice'
  | 'ColorAdvice'
  | 'EquipmentRental'
  | 'EquipmentRepair'
  | 'SampleRental'
  | 'Productsondemand'
  | 'ShopSpecificQuestion'
  | 'GeneralQuestion'
  | 'FindPainter'
  | 'WorkshopQuestion'
  | 'ColoraMagazine'
  | 'ColoraNewsletter'
  | 'PaintGuide'
  | 'MakeAppointment'
  | 'GeneralContact'
  | 'MachineryService'
  | 'PostPurchaseService'
  | 'Return'
  | 'SiteAssistance'
  | 'Training'
  | 'MachineRental'
  | 'MachineRepair'
  | 'Register'
  | 'clienttype'
  | 'RegistrationTraining'
  | 'RegistrationTrainingVisitor';

type MailApiFormFieldKey =
  | 'type'
  | 'deliverymode'
  | 'storeid'
  | 'contactpersonid'
  | 'firstname'
  | 'lastname'
  | 'street'
  | 'streetnumber'
  | 'bus'
  | 'zipcode'
  | 'city'
  | 'country'
  | 'email'
  | 'phone'
  | 'orderinfo'
  | 'orderreference'
  | 'orderid'
  | 'servicemessage'
  | 'subscribetonewsletter'
  | 'subscribetomagazine'
  | 'incidenttype'
  | 'companyname'
  | 'deliverymethod'
  | 'deliverydate'
  | 'vatnumber'
  | 'clienttype'
  | 'accountnumber'
  | 'numberofparticipants'
  | 'accountname';
type ServiceApiFormFieldKey =
  | 'type'
  | 'incidenttype'
  | 'contactpersonid'
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'message'
  | 'deliverymethod'
  | 'deliverywarehouseid'
  | 'address';

type Address = {
  street: string;
  streetnumber: string;
  postbox: string;
  zipcode: string;
  city: string;
  country: string;
};

type FormBody = Partial<Record<MailApiFormFieldKey | ServiceApiFormFieldKey, string | Address | number>>;

/**
 * Mail API Confluence page: https://xploregroup.atlassian.net/wiki/spaces/BOSS/pages/8342765718/WIP+Mail+API;
 * Forms Confluence page: https://xploregroup.atlassian.net/wiki/spaces/BOSS/pages/7798161742/Forms
 *
 * The following form types have been added but arn't present on the mail API Confluence page:
 * PaintGuide, MakeAppointment, GeneralContact, MachineryService, PostPurchaseService, Return, SiteAssistance, Training
 *
 * The following form types are present on the mail API Confluence page but not on the forms Confluence page:
 * EquipmentRental, EquipmentRepair
 **/
const formTypeMap: Record<FormType, MailApiFormType> = {
  'color-advice': 'ColorAdvice',
  'general-question': 'GeneralQuestion',
  magazine: 'ColoraMagazine',
  newsletter: 'ColoraNewsletter',
  'search-painter': 'FindPainter',
  'technical-advice': 'TechnicalAdvice',
  'color-support-lab': 'ColorSupport',
  'custom-deco': 'DecoDesign',
  'samples-service': 'SampleRental',
  'special-order': 'Productsondemand',
  'general-contact': 'GeneralContact',
  'post-purchase-service': 'PostPurchaseService',
  return: 'Return',
  'site-assistance': 'SiteAssistance',
  'machine-rental': 'MachineRental',
  'machine-repair': 'MachineRepair',
  'skedify-color-advice': 'ColorAdvice',
  'skedify-make-appointment': 'MakeAppointment',
  'user-registration': 'Register',
  'education-form': 'RegistrationTraining',
  'education-form-visitor': 'RegistrationTrainingVisitor',
};

/**
 * Added fields: bus, storeid, contactpersonid
 * Missing fields: clienttype, deliverydate, deliverymethod, name, orderinfo, orderreference, salutation, termsandconditions, vatnumber, clientnumber
 **/
const formFieldKeyMap: Partial<Record<FormFieldKey, MailApiFormFieldKey>> = {
  city: 'city',
  country: 'country',
  orderinfo: 'orderinfo',
  email: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  newsletter: 'subscribetonewsletter',
  phonenumber: 'phone',
  street: 'street',
  streetnumber: 'streetnumber',
  zipcode: 'zipcode',
  bus: 'bus',
  storeid: 'storeid',
  contactpersonid: 'contactpersonid',
  incidenttype: 'incidenttype',
  companyname: 'accountname',
  preferenceDate: 'deliverydate',
  orderreference: 'orderreference',
  vatnumber: 'vatnumber',
  clientnumber: 'accountnumber',
  numberOfParticipants: 'numberofparticipants',
  deliverymethod: 'deliverymode',
  message: 'servicemessage',
  orderid: 'orderid',
};

const subscriptions: MailApiFormFieldKey[] = ['subscribetonewsletter', 'subscribetomagazine'];

/**
 * Form Object mapper that returns an object with all API form fields present, even when there's no value given
 * This because the Mail API expects those fields to be present at all times
 **/
const mapFormObject = (type: FormType, formValues: FormValues): FormBody => {
  const mappedFormType = formTypeMap[type];

  return Object.entries(formFieldKeyMap).reduce(
    (mappedBody: FormBody, [formKey, apiKey]) => {
      const userFormValue = formValues[formKey] ?? null;

      const mappedFormValue = subscriptions.includes(apiKey) ? !!userFormValue : userFormValue;

      return { ...mappedBody, [apiKey]: mappedFormValue };
    },
    { type: mappedFormType },
  );
};

/**
 * useForm hook which exports generic form functions
 */

type Options = {
  noToast?: boolean;
};

export const useFormSubmit = (options?: Options) => {
  const { t } = useTranslation('forms');

  const successToast = {
    title: t('success.title'),
    description: t('success.subscribeSubtitle'),
  };
  const errorToast = {
    title: t('errors.submitTitle'),
    description: t('errors.submitSubtitle'),
  };

  const {
    mutate: submitForm,
    isLoading: isSubmitting,
    isSuccess,
    reset,
    isError,
  } = useSubmitForm({
    ...(!options?.noToast && {
      toasts: { success: successToast, error: errorToast },
    }),
  });

  const { trackCustomEvent } = useEventTracker();
  const pageProps = useContext(PagePropsContext);

  const onSubmit = (type: FormType, vals: FormValues) => {
    const formObject = mapFormObject(type, vals);

    submitForm(formObject, {
      onSuccess: () => {
        trackCustomEvent(trackEvents.FORM_SUBMITTED, {
          formName: type,
          pageInfo: pageProps,
        });
      },
    });
  };

  return { onSubmit, isSubmitting, isSuccess, reset, isError };
};
