/* eslint-disable */
const Password = () => {
  return (
    <>
      <div className="heading">
        <h1 role="heading">Gebruikersgegevens</h1>
      </div>
      <div className="buttons"></div>
      <div className="intro">
        <p>Geef de volgende gegevens op.</p>
      </div>
      <form id="attributeVerification">
        <div aria-hidden="true" className="error pageLevel" id="requiredFieldMissing" style={{ display: 'none' }}>
          Er ontbreekt een vereist veld. Vul alle vereiste velden in en probeer het opnieuw.
        </div>
        <div aria-hidden="true" className="error pageLevel" id="fieldIncorrect" style={{ display: 'none' }}>
          Een of meer velden zijn onjuist ingevuld. Controleer deze items en probeer het opnieuw.
        </div>
        <div aria-hidden="true" className="error pageLevel" id="captchaUnresolved" style={{ display: 'none' }}>
          Captcha-vraag is niet opgelost. Los het op om door te gaan.
        </div>
        <div
          aria-hidden="true"
          className="error pageLevel"
          id="claimVerificationServerError"
          style={{ display: 'none' }}
        ></div>
        <div className="attr" id="attributeList">
          <ul>
            <li className="VerificationControl emailVerificationControl_li">
              <div className="attrEntry">
                <label className="" htmlFor="emailVerificationControl" id="emailVerificationControl_label"></label>
                <div className="error itemLevel" role="alert"></div>
                <div className="verificationControlContent" id="emailVerificationControl">
                  <div className="verificationInfoText">
                    <div
                      aria-hidden="true"
                      aria-label="Verificatie is vereist. Klik op de knop Verzenden."
                      id="emailVerificationControl_info_message"
                      role="alert"
                      style={{ display: 'none' }}
                    >
                      Verificatie is vereist. Klik op de knop Verzenden.
                    </div>
                  </div>
                  <div className="verificationSuccessText">
                    <div
                      aria-hidden="true"
                      aria-label=""
                      id="emailVerificationControl_success_message"
                      role="alert"
                      style={{ display: 'none' }}
                    ></div>
                  </div>
                  <div className="verificationErrorText error">
                    <div
                      aria-hidden="true"
                      aria-label=""
                      id="emailVerificationControl_error_message"
                      role="alert"
                      style={{ display: 'none' }}
                    ></div>
                  </div>
                  <ul>
                    <li aria-hidden="false" className="TextBox email_li email" style={{ display: 'inline' }}>
                      <div className="attrEntry">
                        <label className="required" htmlFor="email" id="email_label">
                          E-mailadres
                        </label>
                        <div className="error itemLevel" role="alert"></div>
                        <input
                          aria-required="true"
                          className="textInput"
                          id="email"
                          placeholder="E-mailadres"
                          title="Het e-mailadres waarop u bereikbaar bent."
                          type="text"
                        />
                        <a
                          className="helpLink tiny"
                          data-help="Het e-mailadres waarop u bereikbaar bent."
                          href="javascript:void(0)"
                        >
                          Wat is dit?
                        </a>
                      </div>
                    </li>
                    <li
                      aria-hidden="true"
                      className="TextBox emailVerificationCode_li VerificationCode"
                      style={{ display: 'none' }}
                    >
                      <div className="attrEntry">
                        <label className="required" htmlFor="emailVerificationCode" id="emailVerificationCode_label">
                          Verificatiecode
                        </label>
                        <div className="error itemLevel" role="alert"></div>
                        <input
                          aria-required="true"
                          autoComplete="off"
                          className="textInput"
                          id="emailVerificationCode"
                          placeholder="Verificatiecode"
                          title="Verificatiecode ontvangen in het e-mailbericht."
                          type="text"
                        />
                        <a
                          className="helpLink tiny"
                          data-help="Verificatiecode ontvangen in het e-mailbericht."
                          href="javascript:void(0)"
                        >
                          Wat is dit?
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div aria-hidden="true" className="working" style={{ display: 'none' }}></div>
                  <div className="buttons">
                    <button
                      aria-hidden="false"
                      aria-label="Verificatiecode verzenden"
                      className="sendCode"
                      id="emailVerificationControl_but_send_code"
                      style={{ display: 'inline' }}
                      type="button"
                    >
                      Verificatiecode verzenden
                    </button>
                    <button
                      aria-hidden="true"
                      aria-label="Code verifiëren"
                      className="verifyCode"
                      id="emailVerificationControl_but_verify_code"
                      style={{ display: 'none' }}
                      type="button"
                    >
                      Code verifiëren
                    </button>
                    <button
                      aria-hidden="true"
                      aria-label="Nieuwe code verzenden"
                      className="sendNewCode"
                      id="emailVerificationControl_but_send_new_code"
                      style={{ display: 'none' }}
                      type="button"
                    >
                      Nieuwe code verzenden
                    </button>
                    <button
                      aria-hidden="true"
                      aria-label="E-mailadres wijzigen"
                      className="changeClaims"
                      id="emailVerificationControl_but_change_claims"
                      style={{ display: 'none' }}
                      type="button"
                    >
                      E-mailadres wijzigen
                    </button>
                  </div>
                </div>
                <a className="helpLink tiny" href="javascript:void(0)">
                  Wat is dit?
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons">
          <button aria-disabled="true" aria-label="Doorgaan" form="attributeVerification" id="continue" type="submit">
            Doorgaan
          </button>
          <button aria-label="Annuleren" id="cancel">
            Annuleren
          </button>
        </div>

        <div aria-labelledby="verifying_blurb" aria-live="assertive" className="verifying-modal">
          <div id="verifying_blurb"></div>
        </div>
      </form>
    </>
  );
};

export default Password;
