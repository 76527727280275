import * as bossClient from '@boss/boss-client';
import { IArticlePrice } from '@boss/types/b2b-b2c';

export const parseNumber = (val?: string | number) => {
  if (!val) {
    return 0;
  }

  const num = Number(Number(val).toFixed(2));

  if (typeof num === 'number') {
    return num;
  }

  return 0;
};

export const parseQuantity = (quantity?: number) => {
  if (!quantity) {
    return;
  }

  return quantity / 1000;
};

export const parsePrices = (prices: bossClient.types.article.ArticlePrice[], channel?: string): IArticlePrice => {
  const price = prices[0] ?? {};

  if (channel === 'colora') {
    return {
      price: parseNumber(price.retailprice),
      discountPrice: parseNumber(price.discountedretailprice),
    };
  }

  // b2b by default
  return {
    price: parseNumber(price.wholesaleprice),
    discountPrice: parseNumber(price.personalizedwholesaleprice),
    privatePrice: parseNumber(price.retailpriceexclvat),
  };
};
