export const CUSTOM_CALC = 'custom.calculator';
export const CUSTOM_COLOR = 'custom.color';
export const CUSTOM_COLOR_BASE = 'custom.colorBase';
export const RESULTS = 'end';

export const Q_PREFIX = 'question';

export const B2B_QUESTIONS = {
  q1: 'questions.Preperation_VAK_1.propertyValueId',
  q2: 'questions.Preperation_VAK_2.propertyValueId',
  q3: 'questions.Preperation_VAK_3.propertyValueId',
  q4: 'questions.Preperation_VAK_4.propertyValueId',
  q5: 'questions.Preperation_VAK_5.propertyValueId',
  q6: 'questions.Finish_VAK_1.propertyValueId',
  q7: 'questions.Finish_VAK_2.propertyValueId',
  q8: 'questions.Finish_VAK_3.propertyValueId',
  end: RESULTS,
};

export const B2C_QUESTIONS = {
  q1: 'questions.Preperation_PAR_1.propertyValueId',
  q2: 'questions.Preperation_PAR_2.propertyValueId',
  q3: 'questions.Preperation_PAR_3.propertyValueId',
  q4: 'questions.Preperation_PAR_4.propertyValueId',
  q5: 'questions.Finish_PAR_1.propertyValueId',
  q6: 'questions.Finish_PAR_2.propertyValueId',
  q7: 'questions.Finish_PAR_3.propertyValueId',
  sqm: CUSTOM_CALC,
  color: CUSTOM_COLOR,
  end: RESULTS,
};

export const ENRICHED_QUESTIONS_MAP_B2C = {
  Preperation_PAR_1: B2C_QUESTIONS.q1,
  Preperation_PAR_2: B2C_QUESTIONS.q2,
  Preperation_PAR_3: B2C_QUESTIONS.q3,
  Preperation_PAR_4: B2C_QUESTIONS.q4,
  Finish_PAR_1: B2C_QUESTIONS.q5,
  Finish_PAR_2: B2C_QUESTIONS.q6,
  Finish_PAR_3: B2C_QUESTIONS.q7,
  Calculator: CUSTOM_CALC,
  Color: CUSTOM_COLOR,
  PAR_End: RESULTS,
};

export const ENRICHED_QUESTIONS_MAP_B2B = {
  Preperation_VAK_1: B2B_QUESTIONS.q1,
  Preperation_VAK_2: B2B_QUESTIONS.q2,
  Preperation_VAK_3: B2B_QUESTIONS.q3,
  Preperation_VAK_4: B2B_QUESTIONS.q4,
  Preperation_VAK_5: B2B_QUESTIONS.q5,
  Finish_VAK_1: B2B_QUESTIONS.q6,
  Finish_VAK_2: B2B_QUESTIONS.q7,
  Finish_VAK_3: B2B_QUESTIONS.q8,
  VAK_End: RESULTS,
};

export const B2C_ID_ORDER = [
  'Preperation_PAR_1',
  'Preperation_PAR_2',
  'Preperation_PAR_3',
  'Preperation_PAR_4',
  'Finish_PAR_1',
  'Finish_PAR_2',
  'Finish_PAR_3',
  'CUSTOM_CALC',
  'CUSTOM_COLOR',
  'RESULTS',
];
