import { BasicVariant } from '@boss/types/b2b-b2c';
import { faBoxesPacking, faRefresh, faTruckFast } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';

import { OrderProduct, Translations } from '..';
import { Button, Image, Orb } from '../..';
import { InfoLabel } from '../../OrderPreview/microComponents';

export interface ProductProps {
  products: OrderProduct[];
  variant: BasicVariant;
  translations: Pick<
    Translations,
    | 'reorder'
    | 'writereview'
    | 'amount'
    | 'trackandtrace'
    | 'status'
    | 'deliveredQuantity'
    | 'remainSalesPhysical'
    | 'articleRef'
  >;
}

const OrderDetailProductsStyles = cva('bg-white', {
  variants: {
    variant: {
      primary: 'shadow-l px-5',
      secondary: 'gap-5 flex flex-col',
    },
  },
});

const ProductActionStyles = cva('w-full md:w-100 flex ', {
  variants: {
    variant: {
      primary: 'flex-col items-end pt-4',
      secondary: 'justify-end justify-self-end py-4',
    },
  },
});

const ProductCardStyles = cva('flex flex-col md:flex-row w-full py-5', {
  variants: {
    variant: {
      primary: 'border-b-1 border-gray last-of-type:border-none',
      secondary: 'shadow-l px-5',
    },
  },
});

const OrderDetailProducts = ({ products, variant, translations }: ProductProps) => {
  if (!products || products.length === 0) {
    return null;
  }

  return (
    <div className={OrderDetailProductsStyles({ variant })}>
      {products.map(
        ({
          colorTypeGroups,
          id,
          status,
          name,
          colorName,
          colorCode,
          remainSalesPhysical,
          deliveredQuantity,
          rgb,
          image,
          quantity,
          trackAndTrace,
          deliveryInformation,
          slug,
          myMx,
        }) => (
          <div className={ProductCardStyles({ variant })} data-testid="product-order-card" key={id}>
            {variant === 'primary' && (
              <>
                <div className="w-40 md:w-20">
                  {image && (
                    <Image
                      alt={name}
                      className="h-20 w-20 object-contain"
                      height={80}
                      src={image.url}
                      useNext
                      width={320}
                    />
                  )}
                </div>
                <div className="flex w-full flex-col md:flex-row md:justify-between">
                  <div className="ml-0 ml-5 w-full py-5">
                    {rgb && myMx ? (
                      <div className="flex content-start justify-start">
                        <InfoLabel className="mr-3" label={name} value={colorName} />
                        <Orb className="shadow-m h-4 w-4" style={{ backgroundColor: rgb }} />
                      </div>
                    ) : (
                      <span className="text-xs md:text-sm">{name}</span>
                    )}
                    <InfoLabel label={translations.amount} value={quantity} />
                  </div>
                  <div className="w-40 py-5">
                    <InfoLabel label="Aantal" value={quantity} />
                  </div>
                </div>
              </>
            )}
            {variant === 'secondary' && (
              <div className="grid w-full grid-cols-1 gap-3 md:grid-cols-2 md:gap-0">
                <div className="flex flex-col gap-3">
                  <span>
                    <strong>
                      {name}
                      <span className="text-sm">
                        {(colorName || colorCode) && myMx && colorTypeGroups.length > 0
                          ? ` (${[colorName, colorCode].filter(Boolean).join(' - ')})`
                          : ''}
                      </span>
                    </strong>
                  </span>
                  <InfoLabel label={translations?.articleRef} value={id} />
                  <InfoLabel label={translations?.amount} value={quantity} />
                </div>
                <div className="flex flex-col gap-3">
                  <InfoLabel label={translations?.status} value={status} />
                  <InfoLabel label={translations?.deliveredQuantity} value={deliveredQuantity} />
                  <InfoLabel label={translations?.remainSalesPhysical} value={remainSalesPhysical} />
                </div>
              </div>
            )}
            <div className={ProductActionStyles({ variant })}>
              {slug && (
                <Button
                  href={slug}
                  icon={variant === 'primary' ? faRefresh : null}
                  iconPosition="left"
                  label={translations.reorder}
                  type="primary"
                />
              )}
              {deliveryInformation && variant === 'primary' && (
                <h5 className="mt-4 font-bold">
                  {deliveryInformation.icon && (
                    <FontAwesomeIcon
                      className="mr-1"
                      icon={deliveryInformation.icon === 'onTheMove' ? faTruckFast : faBoxesPacking}
                    />
                  )}
                  {deliveryInformation.text}
                </h5>
              )}
              {trackAndTrace && variant === 'primary' && (
                <div>
                  <Button href={trackAndTrace} label={translations.trackandtrace} type="link" />
                </div>
              )}
            </div>
          </div>
        ),
      )}
    </div>
  );
};

export default OrderDetailProducts;
