import { useTranslation } from 'next-i18next';

import { colorPageSlugs, inspirationPageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { InspirationImageFields } from '@boss/types/b2b-b2c';
import { Button, InspirationCard as Card, Link, PaintDrop } from '@boss/ui';

import { useColorByColorId } from '../../client-queries';

type Props = {
  entry: InspirationImageFields;
};

type OrbProps = {
  colorId: string;
  slug: string;
};

const InspirationCardColorOrb = ({ colorId, slug }: OrbProps) => {
  const { data: fetchedColor, isLoading: colorLoading } = useColorByColorId(colorId);

  const { locale } = useRouter();

  if (colorLoading) {
    return (
      <div className="animate-pulse">
        {/* #e5e7eb is the equivalent of gray-200 */}
        <PaintDrop className="h-15 w-full" colorCode="#e5e7eb" />
      </div>
    );
  }

  if (fetchedColor && !colorLoading) {
    return (
      <Link className="flex justify-center" href={`${colorPageSlugs[locale]}/${slug}`}>
        <PaintDrop className="w-15 h-15" colorCode={fetchedColor?.rgb} />
      </Link>
    );
  }
  return null;
};

const InspirationCard = ({ entry }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const shownColors = entry.usedColors
    ? entry.usedColors.length > 5
      ? entry.usedColors.slice(0, 5)
      : entry.usedColors
    : [];

  return (
    <div>
      <Card
        actions={
          <div className="px-4">
            <Button
              className="border-gray-dark w-full text-center"
              href={`${inspirationPageSlugs[locale]}/${entry.slug}`}
              label={t('inspirationCard.view')}
              type="secondary"
            />
          </div>
        }
        className="min-h-82.5"
        colors={shownColors?.map(color => {
          return <InspirationCardColorOrb colorId={color.colorId} key={`${entry.id}-${color.id}`} slug={color.slug} />;
        })}
        image={entry.image.image.file.url}
        url={`${inspirationPageSlugs[locale]}/${entry.slug}`}
      />
    </div>
  );
};

export default InspirationCard;
