import { faCheck, faSwatchbook } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { ColorPickerInstanceProps } from '..';
import { Button, PaintDrop } from '../../';

const PrimaryColorPicker = ({
  className,
  translations,
  selectedColor,
  onChangeGroupClick,

  colorType,
}: ColorPickerInstanceProps) => {
  const colorLabel = selectedColor?.name ? `${selectedColor.code} - ${selectedColor.name}` : selectedColor?.code;

  return (
    <div className={twMerge('flex flex-col gap-3 md:mb-4', className)}>
      {selectedColor && (
        <div className="flex items-center gap-2">
          <span className="font-medium">{translations.chosenColor}:</span>
        </div>
      )}
      <div className="items-left flex flex-col gap-4">
        <div className="relative flex items-center justify-start gap-3">
          <div className="relative">
            <PaintDrop colorCode={selectedColor?.rgb} colorType={colorType} />
            <FontAwesomeIcon
              className="text-gray-dark absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
              icon={faCheck}
            />
          </div>
          {selectedColor && <span className="small text-gray-dark">{colorLabel}</span>}
        </div>
        <Button
          icon={faSwatchbook}
          label={translations.changeColor}
          onClick={() => onChangeGroupClick()}
          type="secondary"
        />
      </div>
    </div>
  );
};

export default PrimaryColorPicker;
