import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { useAccount, useContacts, useIncidentTypes } from '../../client-queries';
import { FormType, useFormField, usePointOfSale, useProfile, useServiceRequest } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

/**
 * Form fields that will be used.
 * Every field, even the conditional ones, should be included in this list.
 *
 */
const FORM_FIELD_KEYS = [
  'clientnumber',
  'companyname',
  'firstname',
  'lastname',
  'incidenttype',
  'preferenceDate',
  'deliverymethod',
  'colorastore',
  'bossdepot',
  'samplesaddresses',
  'invoiceaddress',
  'accountaddress',
  'new',
  'message',
  'termsandconditions',
];

/**
 * Delivery options that will be used.
 *
 * @type {readonly ["colorastore", "bossdepot", "samplesaddresses"]}
 */
const DELIVERY_OPTION_KEYS = ['colorastore', 'bossdepot', 'samplesaddresses'] as const;
/**
 * Delivery address options that will be used.
 *
 * @type {readonly ["invoice", "address", "new"]}
 */
const DELIVERY_ADDRESS_OPTION_KEYS = ['invoice', 'address', 'new'] as const;

/**
 * Definition of the types used in the form.
 * FormFieldKey is a union of all the keys in FORM_FIELD_KEYS.
 * FieldOverwrite is a record with the keys of FORM_FIELD_KEYS and the values are partial FormField objects.
 * DeliveryOptionKey is a union of all the keys in DELIVERY_OPTION_KEYS.
 * DeliveryAddressOptionKey is a union of all the keys in DELIVERY_ADDRESS_OPTION_KEYS.
 */
type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};
type DeliveryOptionKey = (typeof DELIVERY_OPTION_KEYS)[number];
type DeliveryAddressOptionKey = (typeof DELIVERY_ADDRESS_OPTION_KEYS)[number];

/**
 * Map of the excluded fields for each delivery option.
 * The keys are the DeliveryOptionKey and the values are an array of FormFieldKey.
 */
const excludedDeliveryFieldKeysMap: Record<DeliveryOptionKey, string[]> = {
  colorastore: ['bossdepot', 'samplesaddresses', 'invoiceaddress', 'accountaddress', 'new'],
  bossdepot: ['colorastore', 'samplesaddresses', 'invoiceaddress', 'accountaddress', 'new'],
  samplesaddresses: ['colorastore', 'bossdepot'],
};

/**
 * Map of the excluded fields for each delivery address option.
 * The keys are the DeliveryAddressOptionKey and the values are an array of FormFieldKey.
 */
const excludedAddressFieldKeysMap: Record<DeliveryAddressOptionKey, string[]> = {
  invoice: ['accountaddress', 'new'],
  address: ['invoiceaddress', 'new'],
  new: ['accountaddress', 'invoiceaddress'],
};

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

const B2BSamplesRequestForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type,
}: Props) => {
  const { onSubmit, isSubmitting, isSuccess, reset } = useServiceRequest();
  const { t } = useTranslation('forms');
  const { locale } = useRouter();
  const { stores } = usePointOfSale();
  const { data: account } = useAccount(locale);
  const { data: contacts } = useContacts(locale);

  const { data: incidentTypes } = useIncidentTypes('Sample Rental');
  const { data: profile } = useProfile();
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const { accountinfo, addresses } = account || {};
  const {
    clientnumber,
    termsandconditions,
    companyname,
    firstname,
    lastname,
    street,
    streetnumber,
    bus,
    city,
    zipcode,
    country,
  } = useFormField();

  /**
   * Mapped addresses of the account.
   */
  const allAccountAddresses =
    addresses?.map((key, index) => ({
      value: index.toString(),
      label: `${key.street} ${key.streetnumber}, ${key.zipcode} ${key.country}`,
    })) ?? [];

  /**
   * Mapped and filtered addresses for invoices.
   */
  const invoiceAddresses =
    addresses
      ?.filter(address => address.type === 'invoice')
      .map((key, index) => ({
        value: index.toString(),
        label: `${key.street} ${key.streetnumber}, ${key.zipcode} ${key.country}`,
      })) ?? [];

  const coloraStores = stores
    .filter(store => store.storetype === 'Colora')
    .map(store => ({ value: store.id, label: store.name }));

  const depotStores = stores
    .filter(store => store.storetype === 'Depot')
    .map(store => ({ value: store.id, label: store.name }));

  const deliveryMethodOptions = DELIVERY_OPTION_KEYS.map(key => ({
    value: key,
    label: t(`select.sampleRental.${key}`),
  }));

  const deliveryAddressOptions = DELIVERY_ADDRESS_OPTION_KEYS.map(key => ({
    value: key,
    label: t(`select.addressDeliveryOptions.${key}`),
  }));

  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryOptionKey>(deliveryMethodOptions[0].value);
  const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddressOptionKey>(deliveryAddressOptions[0].value);

  const today = new Date();

  const incidentOptions =
    incidentTypes?.map(incidentType => ({
      value: incidentType.id,
      label: incidentType.name,
    })) ?? [];

  const shouldShowField = (fieldName: FormFieldKey) => {
    const excludedDeliveryKeys = excludedDeliveryFieldKeysMap[deliveryMethod];
    const excludedAddressKeys = excludedAddressFieldKeysMap[deliveryAddress];

    return !(excludedDeliveryKeys?.includes(fieldName) || excludedAddressKeys?.includes(fieldName));
  };

  const customRequiredFieldValidation = (fieldName: string) => {
    return Yup.string().test(
      'required',
      t('errors.required', { value: t(`fields.${fieldName}`) }) ?? '',
      function (value) {
        const shouldShow = shouldShowField(fieldName);

        return !shouldShow || (shouldShow && !!value);
      },
    );
  };

  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...companyname,
      disabled: true,
    },
    {
      ...firstname,
      disabled: true,
      initialValue: contact?.firstname ?? accountinfo?.firstname,
    },
    {
      ...lastname,
      disabled: true,
      initialValue: contact?.lastname ?? accountinfo?.lastname,
    },
    {
      name: 'incidenttype',
      label: t('fields.colorSample'),
      type: 'select',
      disclaimer: t('disclaimers.sampleincidenttype'),
      options: incidentOptions,
    },
    {
      name: 'deliverymethod',
      type: 'select',
      options: deliveryMethodOptions,
      initialValue: deliveryMethodOptions[0].value,
    },
    {
      name: 'colorastore',
      type: 'select',
      options: coloraStores,
      initialValue: account?.preference.shop ?? coloraStores[0]?.value,
      validation: customRequiredFieldValidation('colorastore'),
    },
    {
      name: 'bossdepot',
      type: 'select',
      options: depotStores,
      initialValue: account?.preference.shop ?? depotStores[0]?.value,
      validation: customRequiredFieldValidation('bossdepot'),
    },
    {
      name: 'samplesaddresses',
      type: 'select',
      options: deliveryAddressOptions,
      initialValue: deliveryAddressOptions[0].value,
    },
    {
      name: 'invoiceaddress',
      type: 'select',
      options: invoiceAddresses,
      required: false,
      validation: customRequiredFieldValidation('invoiceaddress'),
    },
    {
      name: 'accountaddress',
      type: 'select',
      options: allAccountAddresses,
      required: false,
      validation: customRequiredFieldValidation('accountaddress'),
    },
    {
      name: 'new',
      type: 'address',
      required: false,
      fields: [street, streetnumber, bus, zipcode, city, country],
      colStyle: 'md:col-span-6',
    },
    {
      name: 'preferenceDate',
      type: 'date',
      initialValue: today.toISOString().split('T')[0] + 'T12:00:00',
      disclaimer: t('disclaimers.preferenceDate'),
    },
    {
      name: 'message',
      label: t('fields.samplesandcolors'),
      type: 'textarea',
      required: true,
    },
    termsandconditions,
  ];

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    const newDeliveryMethod = formik.values.deliverymethod;
    const newDeliveryAddress = formik.values.samplesaddresses;

    if (newDeliveryMethod && newDeliveryMethod !== deliveryMethod) {
      setDeliveryMethod(newDeliveryMethod);
    }

    if (newDeliveryAddress && newDeliveryAddress !== deliveryAddress) {
      setDeliveryAddress(newDeliveryAddress);
    }
  };

  const formFields = buildFormFields(
    baseFields,
    initialFieldsToShow ?? FORM_FIELD_KEYS,
    additionalFields,
    fieldsOverwrite,
  );

  const handleSubmit = (vals: FormValues) => {
    const address: Record<string, string> = {
      street: '',
      streetnumber: '',
      zipcode: '',
      city: '',
      country: '',
      bus: '',
    };

    let deliveryWarehouseId = '';

    if (vals.deliverymethod === 'samplesaddresses') {
      let selectedAddress: Record<string, string> | undefined = {};
      const invoiceAddresses = addresses?.filter(addr => addr.type === 'invoice');

      switch (vals.samplesaddresses) {
        case 'new':
          selectedAddress = vals as unknown as Record<string, string>;
          break;
        case 'invoice':
          selectedAddress = invoiceAddresses?.[Number(vals.invoiceaddress)] as Record<string, string>;
          break;
        case 'address':
          selectedAddress = addresses?.[Number(vals.accountaddress)] as Record<string, string>;
          break;
      }

      if (selectedAddress && typeof selectedAddress === 'object') {
        Object.keys(address).forEach(key => {
          address[key] = (selectedAddress?.[key] as string) || '';
        });
      }
    } else {
      deliveryWarehouseId =
        vals.deliverymethod === 'bossdepot' ? (vals.bossdepot as string) : (vals.colorastore as string);
    }

    const formObject = {
      ...vals,
      ...address,
      email: contact?.email ?? '',
      phonenumber: contact?.mobilephonenumber ?? '',
      deliverywarehouseid: deliveryWarehouseId,
    };

    onSubmit(type, formObject);
  };

  return (
    <DynamicForm
      buttonProps={{
        label: t('buttons.submitQuestion') ?? '',
      }}
      className={className}
      fields={formFields}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onFormValuesChange={handleFormValuesChange}
      onSubmit={handleSubmit}
      shouldShowField={shouldShowField}
      variant="light"
    />
  );
};

export default B2BSamplesRequestForm;
