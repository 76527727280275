import { faCheck, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { CardProps } from '..';
import { CardLabel, Counter, Image, Link, Orb, Price, Skeleton } from '../..';

export interface Props extends CardProps {
  onSelect?: () => void;
  selected?: boolean;
}

const PricingBlock = ({
  loading = false,
  className,
  salesPrice,
  netPrice,
  discountInfo,
  translations,
}: {
  loading?: boolean;
  className?: string;
  salesPrice: number;
  netPrice: number;
  discount?: number;
  discountInfo?: string;
  translations?: {
    discount: string;
  };
}) =>
  loading ? (
    <Skeleton className={twMerge('rounded-brand min-w-20 h-5', className)} skeletonClassName="bg-gray-200" />
  ) : (
    <Price
      className={className}
      discountInfo={discountInfo}
      strikePrice={netPrice !== salesPrice ? salesPrice : undefined}
      translations={translations}
      value={netPrice}
    />
  );

const CardWrapperStyling = cva('flex w-full flex-col p-2 md:p-5', {
  variants: {
    type: {
      default: 'flex-row justify-between',
      extended: 'gap-1',
    },
  },
});

const PrimaryArticleCard = ({
  article,
  onRemove: handleRemove,
  translations,
  quantity,
  onUpdateQuantity: handleUpdateQuantity,
  onColorSelect: handleSelectColor,
  color,
  slug,
  imageSrc,
  price,
  title,
  type,
  className,
  isUpdating,
  discount,
  discountInfo,
  labels,
}: CardProps) => (
  <div className={twMerge(CardWrapperStyling({ type }), className)}>
    <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
      <div className="flex flex-col gap-2">
        <div className="flex w-full items-center gap-4 md:gap-8">
          {imageSrc && (
            <Link anchorClassName="w-15 md:h-25 h-20 md:w-20" href={slug}>
              <Image alt={article.name} className="object-contain" height={100} src={imageSrc} width={80} />
            </Link>
          )}
          <div className="flex w-full flex-col gap-3">
            <Link className="flex flex-col" href={slug}>
              <strong>{title ?? article.product?.name ?? article.name}</strong>
            </Link>
            {color?.name && (
              <span className="flex flex-wrap items-end gap-1 md:flex-col md:items-start">
                <div className="hidden h-fit md:block">{translations.color}:</div>
                <div className="flex items-center gap-3 text-left">
                  <Orb className="shadow-m h-6 w-6 items-center justify-center" style={{ backgroundColor: color.rgb }}>
                    <FontAwesomeIcon icon={faCheck} size="xs" />
                  </Orb>
                  {handleSelectColor ? (
                    <button className="text-left underline" onClick={handleSelectColor}>
                      {color.name}
                    </button>
                  ) : (
                    <span>{color.name}</span>
                  )}
                </div>
              </span>
            )}

            {!!price?.salesPrice && (
              <PricingBlock
                className="text-left md:hidden"
                discount={discount}
                discountInfo={discountInfo}
                loading={isUpdating}
                netPrice={price.netInclVat}
                salesPrice={price.salesPrice}
                translations={translations}
              />
            )}
          </div>
        </div>
        {!!labels?.length && (
          <div className="flex flex-row gap-2">
            {labels?.map(label => (
              <CardLabel key={label.children?.toString()} round="right" {...label} />
            ))}
          </div>
        )}
      </div>
      <div className="flex w-full flex-col items-end gap-5">
        {(handleUpdateQuantity || handleRemove) && (
          <div className="ml-auto flex w-full items-center justify-end gap-9 md:ml-0 md:gap-4">
            {handleUpdateQuantity && <Counter min={1} onChange={handleUpdateQuantity} value={quantity ?? 1} />}
            {handleRemove && (
              <button
                onClick={e => {
                  e.stopPropagation();
                  handleRemove();
                }}
              >
                <FontAwesomeIcon className="text-red-light" icon={faTrashCan} size="lg" />
              </button>
            )}
          </div>
        )}
        {price && (
          <PricingBlock
            className="hidden self-end text-right md:block"
            discount={discount}
            discountInfo={discountInfo}
            loading={isUpdating}
            netPrice={price.netInclVat}
            salesPrice={price.salesPrice}
            translations={translations}
          />
        )}
      </div>
    </div>
  </div>
);

export default PrimaryArticleCard;
