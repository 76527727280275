import { faCalendar, faLocationDot, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { IEvent } from '@boss/services';
import { formatDate, formatTimeRange } from '@boss/utils';

const EventOverviewCard = ({ event }: { event: IEvent }) => {
  const { t } = useTranslation('checkout');
  const { locale } = useRouter();

  const timeRange = formatTimeRange(event?.startTime, event?.endTime, locale as string);

  return (
    <div className="flex flex-col gap-4 rounded-lg p-6 shadow-lg">
      <h4 className="text-blue text-xl font-bold">
        {t('workshop.workshopTitle')}: {event?.title}
      </h4>
      <div className="grid grid-cols-[auto_1fr] gap-4">
        <div className="flex items-center gap-2">
          <FontAwesomeIcon className="text-lg" icon={faCalendar} />
          <span className="hidden font-semibold md:inline">{t('workshop.date')}</span>
        </div>
        <span>
          {event?.date && (
            <>
              {new Date(event.date).toLocaleDateString(locale, { weekday: 'short' })}. {formatDate(event.date)}
              {timeRange && ` (${timeRange})`}
            </>
          )}
        </span>
        <div className="flex items-center gap-2">
          <FontAwesomeIcon className="text-lg" icon={faLocationDot} />
          <span className="hidden font-semibold md:inline">{t('workshop.location')}</span>
        </div>
        <span>{event?.location}</span>
        <div className="flex items-center gap-2">
          <FontAwesomeIcon className="text-lg" icon={faUser} />
          <span className="hidden font-semibold md:inline">{t('workshop.numberOfParticipants')}</span>
        </div>
        <span>{event?.numberOfParticipants}</span>
      </div>
    </div>
  );
};

export default EventOverviewCard;
