export const formatDistance = (distance: number) => {
  return `${distance.toFixed(1).replace(/\./g, ',')}km`;
};

/**
 * Stringifies a number, ads the prefix 0 for single digit numbers
 * number 9 -> string 09 / number 57 -> string 57
 */
export const timeToString = (n: number) => (n < 10 ? '0' : '') + n;

/**
 * Capitalises the first char of a string
 */
export const toSentenceCase = (s: string) => {
  const stringArr = s.split('');

  stringArr[0] = s[0].toUpperCase();
  return stringArr.join('');
};

/**
 *  Formats a date string to a local date string
 * @param dateString
 * @param locale
 * @returns
 */
export const formatLocalDate = (dateString: string, locale: string) => {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat(locale, {
    weekday: 'long', // "maandag"
    day: 'numeric', // "17"
    month: 'long', // "januari"
  }).format(date);
};

/**
 * Formats a date string to a local date string
 * @param dateString
 * @param locale
 * @returns formatted date string in the format "17/01/2022"
 */
export const formatDate = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  const _date = typeof date === 'string' ? new Date(date) : date;
  const day = _date.getDate().toString().padStart(2, '0');
  const month = (_date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
  const year = _date.getFullYear();

  return `${day}/${month}/${year}`;
};

/**
 * Formats a date string to a local time string
 * @param dateString
 * @param locale
 * @returns formatted date string in the format "17:30"
 */
export const getTimeFromDatestring = (dateString: string): string => {
  const date = new Date(dateString);
  // Get hours and minutes from the date in local time
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // Format hours and minutes to ensure they are always two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};

export const formatTimeRange = (fromDate: string, toDate: string, locale: string) => {
  const from = new Date(fromDate);
  const to = new Date(toDate);

  return `${from.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
  })} - ${to.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
};
