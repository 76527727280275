import { DeepPartial } from '@boss/types/b2b-b2c';

import { basket } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };
const HEADERS = {
  'Content-Type': 'application/json',
};

/**
 * Fetch basket for the logged in user
 */
export const getBasket = ({ accountId, locale }: { accountId?: string; locale: string }) => {
  const queryParam = accountId ? `?accountId=${accountId}` : '';

  return bossApi<basket.Basket>(`/basket/v2/baskets${queryParam}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Fetch basket by basket id
 */
export const getBasketById = (basketId: string, locale: string) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Update the basket payment status to pending
 */
export const updateBasketPaymentStatus = (basketId: string, body: DeepPartial<basket.Basket>, locale: string) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    body: body,
    locale,
  });
};

/**
 * Adds a basket line for the logged in user
 */
export const addBasketLines = (basketId: string, basketLines: DeepPartial<basket.BasketLine>[], locale: string) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}/lines`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    body: basketLines as unknown as Record<string, unknown>,
    locale,
  });
};

/**
 * Deletes a basket line for the logged in user
 */
export const deleteBasketLine = ({
  basketId,
  basketLineId,
  locale,
}: {
  basketId: string;
  basketLineId: string;
  locale: string;
}) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}/lines/${basketLineId}`, {
    ...DEFAULT_OPTIONS,
    method: 'DELETE',
    locale,
  });
};

/**
 * Updates a basket line for the logged in user
 */
export const updateBasketLine = ({
  basketId,
  basketLine,
  locale,
}: {
  basketId: string;
  basketLine: DeepPartial<basket.BasketLine>;
  locale: string;
}) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}/lines/${basketLine.id}`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    body: basketLine as unknown as Record<string, unknown>,
    locale,
  });
};

/**
 * Updates the basket for the logged in user
 */
export const updateBasket = <T>({
  basketId,
  basket,
  locale,
}: {
  basketId: string;
  basket: DeepPartial<T>;
  locale: string;
}) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    body: basket as unknown as Record<string, unknown>,
    locale,
  });
};

/**
 * Patch the basket for the logged in user
 */
export const patchBasket = ({
  basketId,
  patchLines,
  locale,
}: {
  basketId: string;
  patchLines: basket.BasketPatchLine[];
  locale: string;
}) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, {
    ...DEFAULT_OPTIONS,
    method: 'PATCH',
    headers: HEADERS,
    body: patchLines as unknown as Record<string, unknown>,
    locale,
  });
};

/**
 * Fetch develiry dates for the basket
 */
export const getDeliveryDates = ({
  modeOfDelivery,
  storeId,
  address,
  locale,
}: {
  modeOfDelivery: number;
  storeId?: string;
  address?: basket.BasketDeliveryAddress;
  locale: string;
}) => {
  const queryParam = storeId
    ? `?deliveryPointId=${storeId}&deliveryMethod=${modeOfDelivery}`
    : `?deliveryMethod=${modeOfDelivery}`;

  return bossApi<basket.DeliveryDates>(`/basket/v2/deliverydates${queryParam}`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    ...(address && { body: address as unknown as Record<string, unknown> }),
    locale,
  });
};

/**
 * Add a voucher to the basket
 */
export const addVoucherToBasket = ({
  basketId,
  voucher,
  locale,
}: {
  basketId: string;
  voucher: { type: string; reference: string; webcode?: string };
  locale: string;
}) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}/vouchers`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    body: voucher as unknown as Record<string, unknown>,
    locale,
  });
};

/**
 * Delete a voucher from the basket
 */
export const deleteVoucherFromBasket = ({
  basketId,
  voucherId,
  locale,
}: {
  basketId: string;
  voucherId: string;
  locale: string;
}) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}/vouchers/${voucherId}`, {
    ...DEFAULT_OPTIONS,
    method: 'DELETE',
    headers: HEADERS,
    locale,
  });
};
