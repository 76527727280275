import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { PAYMENT_TYPE } from '@boss/services/client';
import {
  AddressBlock,
  Alert,
  Button,
  PricingCard,
  ProductOverviewCard,
  ShoppingCartTotalPrice,
  Skeleton,
} from '@boss/ui';
import { formatPrice } from '@boss/utils';

import { useAccount, useCreatePaymentRequest, useGetBasket, useGetEventById } from '../../client-queries';
import { EventOverviewCard } from '../../components';
import { ORDER_ID_PARAMETER } from '../../constants/checkout';
import { buildAddressString } from '../checkoutPage/orderOverview';

const WorkshopCheckoutPage = () => {
  const { t } = useTranslation('checkout');
  const { locale, query } = useRouter();

  const basketId = query[ORDER_ID_PARAMETER] as string;
  const workshopId = query.workshopId as string;
  const paymentFailed = query.paymentFailed === 'true';

  const { data: account, isLoading: accountLoading } = useAccount(locale as string);
  const { data: basket, isLoading: basketLoading } = useGetBasket(basketId ?? 0, !!basketId);
  const { data: event, isLoading: eventLoading } = useGetEventById(workshopId ?? '');

  const { mutateAsync: createPaymentRequest } = useCreatePaymentRequest();

  const invoiceAddress = account?.addresses.find(adr => adr.type === 'invoice');

  const isLoading = accountLoading || basketLoading;

  const handlePaymentRequest = async () => {
    const paymentInfo = await createPaymentRequest({
      type: PAYMENT_TYPE.cashWorkshop,
      locale: locale as string,
      referenceId: basketId,
      amount: (basket?.pricesummary.amounttopay ?? 0) * 100,
      accountId: basket?.account.accountnumber,
      description: `Workshop - ${event?.title}`,
    });

    if (paymentInfo?.paymentUrl) {
      window.location.href = paymentInfo?.paymentUrl;
    }
  };

  return (
    <div className="my-3">
      <div className="flex flex-col gap-4">
        {paymentFailed && <Alert type="error">{t('errors.payment.title', { ns: 'basket' })}</Alert>}
        <h2>{t('overviewPageTitle')}</h2>
        <div className="grid gap-3 lg:grid-cols-6">
          <ProductOverviewCard className="lg:col-span-4">
            <h3 className="text-blue h4">{t('orderOverview.title')}</h3>

            <div className="flex gap-5">
              {accountLoading && (
                <div className="my-5 flex flex-col gap-2">
                  <h5 className="text-blue font-bold">{t('orderOverview.invoiceAddress')}</h5>
                  <Skeleton className="w-50 h-40" />
                </div>
              )}
              {invoiceAddress && (
                <AddressBlock title={t('orderOverview.invoiceAddress') ?? ''}>
                  <div className="flex flex-col">{buildAddressString(invoiceAddress, 'invoice')}</div>
                </AddressBlock>
              )}
            </div>
            <h3 className="text-blue h4 mb-4">{t('workshop.workshopDetailsTitle')}</h3>
            {eventLoading || !event ? <Skeleton className="h-40 w-full" /> : <EventOverviewCard event={event} />}
          </ProductOverviewCard>
          <div className="flex flex-col gap-3 lg:col-span-2">
            <PricingCard title="">
              <ShoppingCartTotalPrice
                className="mb-10"
                infoText={t('inclCosts', { ns: 'basket' })}
                isPriceLoading={basketLoading}
                price={formatPrice(basket?.pricesummary.amounttopay ?? 0, locale as string)}
                totalText={t('totalWithVAT', { ns: 'basket' })}
              />
            </PricingCard>
            <Button
              className="w-full py-3 text-center"
              disabled={isLoading}
              icon={faArrowRight}
              iconPosition="right"
              label={t('summary.payAndContinue', { ns: 'checkout' })}
              onClick={handlePaymentRequest}
              type="primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopCheckoutPage;
