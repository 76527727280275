import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useIsResponsive } from '@boss/hooks';
import { IAccountOrder } from '@boss/services/client';
import { OrderCard } from '@boss/ui';
import { getAccountPageSlugByType } from '@boss/utils';

import { useOrders } from '../../../client-queries';
import { isB2b, orderCardVariant } from '../../../utils';

type Props = {
  locale: string;
  className?: string;
};

const OrderOverviewStyle = cva('flex flex-col gap-10 pb-6', {
  variants: {
    b2b: {
      true: 'bg-brown text-white px-6 py-6',
    },
  },
});

const mapOrders = (orders: IAccountOrder[]) =>
  orders.map((order, orderIndex) => ({
    id: (orderIndex + 1).toString(),
    description: order.salesStatus,
    title: `${order.distributionDescription} ${order.deliveryLocation}`,
    typeOfOrder: order.online ? 'online' : 'store',
    status: 'ready' as const,
    products:
      order?.lines?.map((line, lineIndex) => ({
        id: (lineIndex + 1).toString(),
        name: line.name,
        ...(line.imageUrl && { image: { url: line.imageUrl } }),
      })) ?? [],
  }));

const Orders = ({ locale, className }: Props) => {
  const { t } = useTranslation('account');
  const isMobile = useIsResponsive('xl');
  const { data: orders } = useOrders(locale as string, {
    limit: 3,
  });

  const mappedOrders = mapOrders(orders?.orders || []);

  return (
    <div
      className={twMerge(
        OrderOverviewStyle({
          b2b: isB2b,
        }),
        className,
      )}
    >
      <h1>{t('dashboard.myLastOrders')}</h1>
      <div className="grid grid-cols-2 gap-2 md:gap-7 xl:grid-cols-3">
        {mappedOrders?.slice(0, isMobile ? 2 : 3).map(order => (
          <OrderCard
            key={order.id}
            variant={orderCardVariant}
            {...order}
            translations={{
              products: t('dashboard.product', { count: order?.products?.length }),
            }}
          />
        ))}
      </div>
      <Link className="underline" href={getAccountPageSlugByType('ORDERS', locale as string)}>
        {t('dashboard.viewAllOrders')} <FontAwesomeIcon icon={faChevronRight} size="xs" />
      </Link>
    </div>
  );
};

export default Orders;
