import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { useFormField, useFormSubmit, useLogin } from '../../hooks';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const areMatchingFieldsValid = (formik: ReturnType<typeof useFormik>, keyA: string, keyB: string) =>
  formik.touched?.[keyA] &&
  formik.touched?.[keyB] &&
  formik.values?.[keyA]?.toLowerCase() !== formik.values?.[keyB]?.toLowerCase() &&
  !formik.errors?.[keyA] &&
  !formik.errors?.[keyB];

// eslint-disable-next-line sonarjs/cognitive-complexity
const RegisterForm = () => {
  const { t } = useTranslation('forms');
  const { handleRegister } = useLogin();
  const { asPath } = useRouter();

  const {
    firstname,
    lastname,
    email,
    termsandconditions,
    street,
    streetnumber,
    bus,
    zipcode,
    city,
    country,
    phonenumber,
  } = useFormField({
    enableInitialValues: false,
  });

  const { onSubmit: handleSubmit, isSuccess } = useFormSubmit({ noToast: true });

  const additionalEmailProps = {
    placeholder: t('placeholders.email') ?? '',
  };

  const colStyle = 'col-span-3';

  const fields: FormField[] = [
    {
      colStyle,
      name: 'customerType',
      type: 'select',
      label: t('fields.customerType') ?? '',
      errorLabel: t('errors.customerType') ?? '',
      options: [
        { value: 'professional', label: t('select.customerType.professional') ?? '' },
        { value: 'private', label: t('select.customerType.private') ?? '' },
        { value: 'other', label: t('select.customerType.other') ?? '' },
      ],
      otherPlaceholder: t('placeholders.customerTypeOther') ?? '',
      placeholder: t('placeholders.select'),
    },
    {
      colStyle,
      name: 'companyname',
      type: 'input',
      label: t('fields.companyname') ?? '',
      placeholder: t('fields.companyname') ?? '',
      errorLabel: t('errors.companyname') ?? '',
    },
    {
      colStyle,
      name: 'vatnumber',
      type: 'input',
      label: t('fields.vatnumber') ?? '',
      placeholder: t('placeholders.vatnumber') ?? '',
      errorLabel: t('errors.vatnumber') ?? '',
    },
    { ...street, colStyle: 'col-start-1 col-end-4', placeholder: t('fields.street') ?? '' },
    { ...streetnumber, colStyle: 'col-span-2 lg:col-span-1', placeholder: t('fields.streetnumber') ?? '' },
    { ...bus, colStyle: 'col-span-1', placeholder: t('fields.bus') ?? '' },
    { ...zipcode, placeholder: t('fields.zipcode') ?? '' },
    { ...city, colStyle: 'col-span-2 lg:col-span-3', placeholder: t('fields.city') ?? '' },
    { ...country, colStyle },
    { ...firstname, colStyle, placeholder: t('placeholders.firstname') ?? '' },
    { ...lastname, colStyle, placeholder: t('placeholders.lastname') ?? '' },
    { ...email, colStyle, ...additionalEmailProps },
    {
      ...email,
      colStyle,
      ...additionalEmailProps,
      label: t('fields.emailConfirm') ?? '',
      name: 'emailConfirm',
      errorLabel: t('errors.emailConfirm') ?? '',
    },
    {
      ...phonenumber,
      colStyle,
      placeholder: t('placeholders.phonenumber') ?? '',
      errorLabel: t('errors.phonenumber') ?? '',
    },
    {
      name: 'referrer',
      colStyle,
      type: 'select',
      label: t('fields.referrer') ?? '',
      errorLabel: t('errors.referrer') ?? '',
      otherPlaceholder: t('placeholders.referrerOther') ?? '',
      options: [
        { value: 'word-of-mouth', label: t('select.referrer.wordOfMouth') ?? '' },
        { value: 'search-engine', label: t('select.referrer.searchEngine') ?? '' },
        { value: 'social-media', label: t('select.referrer.socialMedia') ?? '' },
        { value: 'advertisement', label: t('select.referrer.advertisement') ?? '' },
        { value: 'referral-schilderzoektschilder', label: t('select.referrer.referralSchilderzoektschilder') ?? '' },
        { value: 'referral-other-website', label: t('select.referrer.referralOtherWebsite') ?? '' },
        { value: 'newsletter', label: t('select.referrer.newsletter') ?? '' },
        { value: 'other', label: t('select.referrer.other') ?? '' },
      ],
    },
    { ...termsandconditions, colStyle },
  ];

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    if (areMatchingFieldsValid(formik, 'emailConfirm', 'email')) {
      formik.setFieldError('emailConfirm', 'error'); // Original field error has priority so the error value here doesn't matter
    }

    if (areMatchingFieldsValid(formik, 'passwordConfirm', 'password')) {
      formik.setFieldError('passwordConfirm', 'error'); // Original field error has priority so the error value here doesn't matter
    }
  };

  const onSubmit = async (vals: FormValues) => {
    const customerType = vals.customerType === 'other' ? `other: ${vals['customerType-custom']}` : vals.customerType;
    const message = vals.referrer === 'other' ? `other: ${vals['referrer-custom']}` : vals.referrer;

    const values = {
      ...vals,
      message: `customerType: ${customerType}, referrer: ${message}`,
    };

    handleSubmit('user-registration', values);
  };

  return (
    <DynamicForm
      buttonProps={{
        className: 'h-12 w-full justify-center text-center md:w-auto [&>*]:w-auto md:self-start',
        label: t('buttons.register') ?? '',
        iconPosition: 'right',
        icon: faArrowRight,
        onClick: () => handleRegister(asPath),
      }}
      columnsWrapperClassName="content-end grid-cols-2"
      errorSubtitle={t('errors.submitSubtitle') ?? ''}
      errorTitle={t('errors.submitTitle') ?? ''}
      fields={fields}
      formFieldsWrapperClassName="grid gap-5 grid-cols-3 md:grid-cols-3 lg:grid-cols-6 content-center"
      id="user-registration"
      isSuccess={isSuccess}
      onFormValuesChange={handleFormValuesChange}
      onSubmit={onSubmit}
      options={{ validateOnChange: true }}
    />
  );
};

export default RegisterForm;
