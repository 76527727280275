export { default as AdviceForm } from './AdviceForm';
export { default as BasicInfoForm } from './BasicInfoForm';
export { default as EducationForm } from './EducationForm';
export { default as HelpForm } from './HelpForm';
export { default as LoginForm } from './LoginForm';
export { default as MachineForm } from './MachineForm';
export { default as B2CRegisterForm } from './B2CRegisterForm';
export { default as B2BRegisterForm } from './B2BRegisterForm';
export { default as SpecialOrderForm } from './SpecialOrderForm';
export { default as VRForm } from './VRForm';
export { default as WorkshopForm } from './WorkshopForm';
