import { useRouter } from 'next/router';
import { Trans, useTranslation } from 'next-i18next';

import { AddressBlock, Button, ProductOverviewCard } from '@boss/ui';

import { useAccount } from '../../../client-queries';
import { DeliveryMethod, DeliveryOptions, PickupMethod } from '../../../hooks';
interface Props {
  deliveryMethod: PickupMethod | DeliveryMethod;
  onChangeDeliveryMethod: () => void;
  deliveryOptions: DeliveryOptions;
  nextButton: React.ReactNode;
}

export const buildAddressString = (address: Record<string, string>, id: string) => {
  const addressKeys: (keyof Record<string, string>)[][] = [
    ['street', 'streetnumber', 'postbox'],
    ['zipcode', 'city'],
    ['country'],
  ];

  return addressKeys.map((addressLine, index) => (
    <span key={`${id}-${index}`}>
      {addressLine.map(key => address?.[key] ?? address?.[`${id}-${key}`] ?? '')?.join(' ')}
    </span>
  ));
};

const PickupOverview = ({
  deliveryMethod,
  deliveryOptions,
  onChangeDeliveryMethod: handleChangeDeliveryMethod,
  nextButton,
}: Props) => {
  const { t } = useTranslation('checkout');
  const { locale } = useRouter();
  const { data: account } = useAccount(locale as string);

  const isPickup = deliveryMethod.type === 'pickup';

  const invoiceAddress = deliveryOptions?.invoice?.address ?? account?.addresses.find(adr => adr.type === 'invoice');
  const deliveryAddress = deliveryOptions?.sameAddressAsBilling ? invoiceAddress : deliveryOptions?.address;

  return (
    <div className="flex flex-col gap-3">
      <h2>{t('overviewPageTitle')}</h2>
      <div className="col-span-4 hidden justify-end md:flex">{nextButton}</div>
      <ProductOverviewCard className="flex flex-col gap-5 text-left" title="">
        <h3 className="text-blue h4">{t('orderOverview.title')}</h3>

        <div className="flex gap-5">
          {invoiceAddress && (
            <AddressBlock title={t('orderOverview.invoiceAddress') ?? ''}>
              <div className="flex flex-col">{buildAddressString(invoiceAddress, 'invoice')}</div>
            </AddressBlock>
          )}
          {deliveryAddress && !isPickup && (
            <AddressBlock title={t('orderOverview.deliveryAddress') ?? ''}>
              <div className="flex flex-col">{buildAddressString(deliveryAddress, 'delivery')}</div>
            </AddressBlock>
          )}
        </div>

        {isPickup ? (
          <h3 className="text-blue h4">
            {t('orderOverview.pickupTitle', {
              storeName: deliveryMethod.clickAndCollectStore?.name,
            })}
          </h3>
        ) : (
          <>
            <h3 className="text-blue h4">{t('orderOverview.deliveryTitle')}</h3>
            <p>
              <Trans i18nKey="checkout:orderOverview.deliveryInfo">
                Binnen <strong>2 a 3 werkdagen</strong> op bovenstaand adres bezorgd.
              </Trans>
            </p>
          </>
        )}
        <Button label={t('orderOverview.changeDeliveryMethod')} onClick={handleChangeDeliveryMethod} type="link" />
      </ProductOverviewCard>
    </div>
  );
};

export default PickupOverview;
