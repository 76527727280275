import { useMemo, useState } from 'react';

import { IEvent } from '@boss/services';

import { isB2b } from '../../utils';

interface FilterOptions {
  fromDate: Date | null;
  toDate: Date | null;
  type: string | null;
  location: string | null;
  language: string | null;
  locationId: string | null;
}

interface InitialFilters {
  type?: string | null;
  locationId?: string | null;
}

export const useEventFilter = (events: IEvent[], initialFilters?: InitialFilters) => {
  const [filters, setFilters] = useState<FilterOptions>({
    fromDate: null,
    toDate: null,
    location: null,
    type: initialFilters?.type ?? null,
    locationId: initialFilters?.locationId ?? null,
    language: null,
  });

  const matchesFilters = (event: IEvent) => {
    const eventDate = new Date(event.date);

    if (filters.fromDate && eventDate < filters.fromDate) {
      return false;
    }
    if (filters.toDate && eventDate > filters.toDate) {
      return false;
    }
    if (filters.location && event.location?.toLowerCase() !== filters.location.toLowerCase()) {
      return false;
    }
    if (filters.locationId && event.locationId !== filters.locationId) {
      return false;
    }
    if (filters.language && event.language?.toLowerCase() !== filters.language.toLowerCase()) {
      return false;
    }

    return !(filters.type && event.type?.toLowerCase() !== filters.type.toLowerCase());
  };

  const filteredEvents = useMemo(() => {
    return events.filter(matchesFilters).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, filters]);

  const updateFilters = (newFilters: Partial<FilterOptions>) => {
    setFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
  };

  const clearFilters = () => {
    setFilters({
      fromDate: null,
      toDate: null,
      type: null,
      location: null,
      language: null,
      locationId: null,
    });
  };

  const allEventTypes = useMemo(() => {
    return isB2b ? Array.from(new Set(events.filter(event => event.type).map(event => event.type))) : [];
  }, [events]);

  const allEventLocations = useMemo(() => {
    return Array.from(new Set(events.filter(event => event.location).map(event => event.location)));
  }, [events]);

  const availableLanguages = useMemo(() => {
    return Array.from(new Set(events.filter(event => event.language).map(event => event.language)));
  }, [events]);

  return {
    filteredEvents,
    updateFilters,
    filters,
    allEventTypes,
    allEventLocations,
    clearFilters,
    availableLanguages,
  };
};
