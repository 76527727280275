import { BasicVariant } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';

import OrderDetailProducts from './OrderDetailProducts';
import OrderInformation from './OrderInformation';
import { Button } from '..';
import { InfoLabel, Warning } from '../OrderPreview/microComponents';

export type OrderProduct = {
  id: string;
  colorTypeGroups: string[];
  quantity: number;
  image?: { url: string };
  name: string;
  colorCode?: string;
  colorName?: string;
  trackAndTrace?: string;
  deliveryInformation?: {
    icon?: string;
    text: string;
  };
  rgb?: string;
  status: string;
  secondaryUnit: string;
  secondaryQuantity: number;
  remainSalesPhysical: number;
  deliveredQuantity: number;
  slug: string;
  myMx?: boolean;
};

export type Address = {
  street: string;
  number: number;
  postalCode: string;
  city: string;
  country: string;
};

export type OrderAddress = {
  label: string;
  address: Address;
};

export type OrderDetail = {
  label: string;
  value: string | 'onTheMove' | 'ready';
};

export type Translations = {
  date: string;
  orderNumber: string;
  products: string;
  status: string;
  trackandtrace: string;
  orderinformation: string;
  downloadinvoice: string;
  sameaddress: string;
  invoiceaddress: string;
  deliveryaddress: string;
  download: string;
  detailPage: string;
  warning: string;
  readMore: string;
  reorder: string;
  writereview: string;
  amount: string;
  articleRef: string;
  deliveredQuantity: string;
  remainSalesPhysical: string;
};

export interface Props {
  title: string;
  subtitle?: string;
  downloadInvoice?: string;
  orderName?: string;
  details: OrderDetail[];
  products: OrderProduct[];
  orderAddress: OrderAddress[];
  variant: BasicVariant;
  translations: Translations;
  warningMessage?: string;
  warningLink?: string;
  writeReview: () => void;
}

const InfoStyles = cva('mb-3 flex justify-between md:justify-start items-center', {
  variants: {
    variant: {
      primary: '',
      secondary: 'mt-5',
    },
  },
});

const OrderDetail = ({
  title,
  subtitle,
  downloadInvoice,
  details,
  products,
  orderAddress,
  orderName,
  variant,
  translations,
  warningMessage,
  warningLink,
}: Props) => {
  return (
    <div>
      <h3 className="mb-3">{title}</h3>
      {subtitle && variant === 'secondary' && <h2>{subtitle}</h2>}
      <div className={InfoStyles({ variant })}>
        {details.map(({ label, value }, index) => (
          <InfoLabel className="md:mr-4 last-of-type:md:mr-0" key={index} label={label} value={value} />
        ))}
      </div>
      {warningMessage && (
        <Warning className="mt-2" link={warningLink} translations={translations} warning={warningMessage} />
      )}
      {downloadInvoice && (
        <Button className="mb-5" href={downloadInvoice} label={translations.downloadinvoice} type="link" />
      )}
      <OrderDetailProducts products={products} translations={translations} variant={variant} />
      <div className="mt-5">
        <h4 className="mb-10">{translations.orderinformation}</h4>
        <OrderInformation address={orderAddress} name={orderName} />
      </div>
    </div>
  );
};

export default OrderDetail;
