import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button, Modal } from '@boss/ui';

import useLogin from '../../hooks/use-login/use-login';

interface LoginWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LoginWarningModal: React.FC<LoginWarningModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation('common');
  const { asPath } = useRouter();
  const { handleLogin } = useLogin();

  const login = () => {
    handleLogin(asPath);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal onClose={onClose}>
      <h3>{t('loginModal.title')}</h3>
      <div className="mt-10 flex gap-4">
        <Button label={t('loginModal.login')} onClick={login} type="primary" />
      </div>
    </Modal>
  );
};

export default LoginWarningModal;
