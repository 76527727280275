import * as Sentry from '@sentry/nextjs';

import { withSession } from '@boss/auth';
import {
  IBasket,
  IBasketDeliveryAddress,
  IBasketLine,
  IBasketPatchLine,
  IBasketUpdateB2C,
  basket as basketService,
} from '@boss/services/client';
import { DeepPartial } from '@boss/types/b2b-b2c';

import { isB2b } from '../../utils';

/**
 * Function to fetch the current user's basket
 *
 * @async
 * @returns {Promise}
 */
const getBasket = withSession(
  async ({ accountId, locale }: { accountId?: string; locale: string }) => {
    try {
      return await basketService.getBasket({ accountId, locale });
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Fetch basket',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Function to fetch basket by basket id
 *
 * @async
 * @returns {Promise}
 */
const getBasketById = withSession(
  async ({ basketId, locale }: { basketId: string; locale: string }) => {
    try {
      return await basketService.getBasketById({ basketId, locale });
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Fetch basket by basket id',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to add a new basket line
 *
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {IBasketLine} basketLine - New basket line that needs to be added
 * @returns {Promise<IBasket>} The new basket
 */
const addBasketLines = withSession(
  async ({
    basketId,
    newBasketLines,
    locale,
  }: {
    basketId: string;
    newBasketLines: DeepPartial<IBasketLine>[];
    locale: string;
  }) => {
    try {
      return await basketService.addBasketLines(basketId, newBasketLines, locale);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Create wishlist',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to delete a new basket line
 *
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {string} basketLineId - Id of the basket line that needs to be deleted
 * @returns {Promise<IBasket>} The new basket
 */
const deleteBasketLine = withSession(
  async ({ basketId, basketLineId, locale }: { basketId: string; basketLineId: string; locale: string }) => {
    try {
      return await basketService.deleteBasketLine({ basketId, basketLineId, locale });
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Delete basket line',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to update a basket line
 *
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {IBasketLine} basketLine - Updated basket line
 * @returns {Promise<IBasket>} The new basket
 */
const updateBasketLine = withSession(
  async ({
    basketId,
    basketLine,
    locale,
  }: {
    basketId: string;
    basketLine: DeepPartial<IBasketLine>;
    locale: string;
  }) => {
    try {
      return await basketService.updateBasketLine(basketId, basketLine, locale);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Update basket line',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to update the b2b basket
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {IBasket} basket - Updated basket
 * @returns {Promise<IBasket>} The new basket
 */
const updateBasketB2B = withSession(
  async (basketId: string, basket: DeepPartial<IBasket>, locale: string) => {
    try {
      return await basketService.updateBasketB2B(basketId, basket, locale);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Update basket',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to update the b2c basket
 */
const updateBasketB2C = withSession(
  async ({ basketId, basket, locale }: { basketId: string; basket: DeepPartial<IBasketUpdateB2C>; locale: string }) => {
    try {
      return await basketService.updateBasketB2C(basketId, basket, locale);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Update basket',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to path the basket
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {IBasketPatchLine[]} patchLines - Patch lines
 * @returns {Promise<IBasket>} The new basket
 * */
const patchBasket = withSession(
  async (basketId: string, patchLines: IBasketPatchLine[], locale: string) => {
    try {
      return await basketService.patchBasket(basketId, patchLines, locale);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Patch basket',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Function to fetch delivery dates for the basket
 * @async withSession
 * @param {string} accountId - Id of the current active basket
 * @param {number} modeOfDelivery - Mode of delivery
 */
const getDeliveryDates = withSession(
  async ({
    modeOfDelivery,
    storeId,
    address,
    locale,
  }: {
    modeOfDelivery: number;
    storeId?: string;
    address?: IBasketDeliveryAddress;
    locale: string;
  }) => {
    try {
      return await basketService.getDeliveryDates({ modeOfDelivery, storeId, address, locale });
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Fetch delivery dates',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
);

/**
 * Client mutation to add a voucher to the basket
 */
const addVoucherToBasket = withSession(
  async ({
    basketId,
    voucher,
    locale,
  }: {
    basketId: string;
    voucher: { type: string; reference: string; webcode?: string };
    locale: string;
  }) => {
    try {
      return await basketService.addVoucherToBasket(basketId, voucher, locale);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Add voucher to basket',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to delete a voucher from the basket
 */
const deleteVoucherFromBasket = withSession(
  async ({ basketId, voucherId, locale }: { basketId: string; voucherId: string; locale: string }) =>
    await basketService.deleteVoucherFromBasket(basketId, voucherId, locale),
  {
    alwaysRun: !isB2b,
  },
);

export {
  addBasketLines,
  deleteBasketLine,
  getBasket,
  getBasketById,
  updateBasketLine,
  getDeliveryDates,
  updateBasketB2B,
  updateBasketB2C,
  patchBasket,
  addVoucherToBasket,
  deleteVoucherFromBasket,
};
