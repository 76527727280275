import { twMerge } from 'tailwind-merge';

import { Image } from '..';
import Link from '../Link';

type Props = {
  className?: string;
  src: string;
  alt?: string;
  href?: string;
};

const Logo = ({ src, alt, className, href = '/' }: Props) => (
  <Link aria-label="logo-link" className={twMerge('w-25.25 md:w-37.75 relative block', className)} href={href}>
    <Image alt={alt} className="object-contain" src={src} width={300} />
  </Link>
);

export default Logo;
