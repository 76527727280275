import { quotation } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };

/**
 * Fetch all quotations for an account
 */
export const getQuotations = (accountId: string, locale: string) => {
  return bossApi<quotation.Quotations>(`/quotation/v2/quotations/${accountId}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Fetch PDF Link for an quotation
 */
export const getQuotationFileLink = (quotationId: string, locale: string) => {
  return bossApi<quotation.QuotationFileLink>(`/quotation/v2/quotations/${quotationId}/pdf?language=${locale}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};
