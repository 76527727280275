import { twMerge } from 'tailwind-merge';

import InputField, { InputFieldProps } from '../InputField';
import SquaredUnit from '../SquaredUnit';

export type Props = Omit<InputFieldProps, 'value'> & {
  unit?: string;
  value: number | string;
  integerOnly?: boolean;
};

const InputUnit = ({
  className,
  step = '0.01',
  inputClassName,
  unit,
  type = 'number',
  value,
  integerOnly,
  ...props
}: Props) => (
  <InputField
    className={twMerge('relative', className)}
    inputClassName={twMerge('pr-10', inputClassName)}
    integerOnly
    step={step}
    type={type}
    value={Number(value).toString()} // Removes leading zeros
    {...props}
  >
    {unit && (
      <span className={twMerge('absolute right-5 top-1/2 -translate-y-1/2')}>
        {unit === 'square-meter' ? <SquaredUnit elevatedValue={2} unit="m" /> : unit}
      </span>
    )}
  </InputField>
);

export default InputUnit;
