import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { useContacts, useIncidentTypes, useWorkSitesDetail } from '../../client-queries';
import { FormType, useFormField, useProfile, useServiceRequest } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const FORM_FIELD_KEYS = [
  'clientnumber',
  'companyname',
  'kvknumber',
  'firstname',
  'lastname',
  'incidenttype',
  'preferenceDate',
  'new',
  'worksite',
  'deliverydate',
  'address',
  'message',
  'termsandconditions',
] as const;

type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  className?: string;
};

const SiteAssistanceForm = ({ fieldsToShow: initialFieldsToShow, className, type }: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useServiceRequest();
  const { t } = useTranslation('forms');
  const chooseTranslation = t('select.choose');

  const { locale } = useRouter();
  const { data: incidentTypes } = useIncidentTypes('DAS');
  const baseValues = {
    deliverymethod: 'worksite',
  };

  type FormValueTypes = Record<string, string | undefined>;

  const [values, setValues] = useState<FormValueTypes>(baseValues);

  const { clientnumber, companyname, firstname, lastname, termsandconditions, address } = useFormField();
  const { data: worksites } = useWorkSitesDetail(locale);
  const { data: contacts } = useContacts(locale);

  const { data: profile } = useProfile();
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const worksiteOptions =
    worksites?.map((worksite, index) => ({
      value: index.toString(),
      label: `${worksite.street} ${worksite.streetnumber}, ${worksite.zipcode} ${worksite.city}`,
    })) ?? [];

  worksiteOptions.unshift({ value: '', label: chooseTranslation });

  const incidentOptions =
    incidentTypes?.map(incidentType => ({
      value: incidentType.id,
      label: incidentType.name,
    })) ?? [];

  incidentOptions.unshift({ value: '', label: chooseTranslation });

  const today = new Date();

  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...companyname,
      disabled: true,
    },
    {
      ...firstname,
      initialValue: contact?.firstname ?? '',
      disabled: true,
    },
    {
      ...lastname,
      initialValue: contact?.lastname ?? '',
      disabled: true,
    },
    {
      name: 'incidenttype',
      type: 'select',
      label: t('fields.assistancetype'),
      options: incidentOptions,
      initialValue: values?.['incidenttype'],
    },
    address,
    {
      name: 'preferenceDate',
      type: 'date',
      initialValue: values?.['preferenceDate'] ?? today.toString(),
      disclaimer: t('disclaimers.preferenceDate'),
    },
    {
      name: 'message',
      type: 'textarea',
      label: t('fields.questionHelp'),
      disclaimer: t('disclaimers.colorAdvice'),
      initialValue: values?.['message'],
    },
    termsandconditions,
  ];

  const getFieldsToShow = () => {
    return fieldsToShow ?? FORM_FIELD_KEYS;
  };

  const compareObject = (
    object: Record<FormFieldKey, string | undefined>,
    values: Record<FormFieldKey, string | undefined>,
  ) => (Object.keys(object) as FormFieldKey[]).some(key => object[key] !== values[key]);

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    const setterObject: Record<string, string | undefined> = {};

    if (Object.keys(setterObject).length === 0 || compareObject(setterObject, values)) {
      return;
    }
    setValues(setterObject);
  };

  const handleSubmit = (vals: FormValues) => {
    const submitObject = {
      ...values,
      ...vals,
      email: contact?.email ?? '',
      phonenumber: contact?.mobilephonenumber ?? '',
    };

    setValues(baseValues);

    onSubmit(type, submitObject);
  };

  return (
    <DynamicForm
      buttonProps={{
        label: t('buttons.submitQuestion') ?? '',
      }}
      className={className}
      fields={buildFormFields(baseFields, getFieldsToShow())}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onFormValuesChange={handleFormValuesChange}
      onSubmit={handleSubmit}
      variant="light"
    />
  );
};

export default SiteAssistanceForm;
