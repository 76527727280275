import type { Store } from '../../types';
import bossApi from '../../utils/fetch-api';
import { mapStore } from '../../utils/mappers';

export const getStore = async (storeName: string, locale: string) => {
  let store = await bossApi<Store>(`/store/v2/stores/${storeName}`, {
    m2m: true,
    authScope: process.env.BOSS_API_AUTH_SCOPE_STORE,
    locale,
  });

  if (store) {
    store = mapStore(store);
  }

  return store;
};

export default getStore;
