import { DEFAULT_ANIMATE_ANIMATION, DEFAULT_EXIT_ANIMATION, DEFAULT_TRANSITION_TIMING } from '@boss/constants/b2b-b2c';
import { AnimatePresence, AnimatePresenceProps, LazyMotion, Transition, domAnimation, motion } from 'framer-motion';
import { ReactNode } from 'react';

export interface PresenceInterface {
  children: ReactNode;
  visible?: boolean;
  transition?: Transition;
  initial?: object;
  animate?: object;
  className?: string;
  isLoading?: boolean;
  loader?: ReactNode;
  id: string;
  exit?: object;
  fallback?: ReactNode;
  mode?: AnimatePresenceProps['mode'];
  layoutId?: string;
}

const Presence = ({
  className,
  visible,
  children,
  transition = DEFAULT_TRANSITION_TIMING,
  isLoading,
  loader,
  id,
  animate = DEFAULT_ANIMATE_ANIMATION,
  exit = DEFAULT_EXIT_ANIMATION,
  initial,
  fallback,
  mode,
  layoutId,
}: PresenceInterface) => {
  if (isLoading && loader) {
    return <>{loader}</>;
  }

  return (
    <AnimatePresence mode={mode}>
      {visible ? (
        <LazyMotion features={domAnimation}>
          <motion.div
            animate={animate}
            className={className}
            exit={exit}
            id={id}
            initial={initial ?? exit}
            key={id}
            layoutId={layoutId}
            transition={transition}
          >
            {children}
          </motion.div>
        </LazyMotion>
      ) : (
        fallback
      )}
    </AnimatePresence>
  );
};

export default Presence;
