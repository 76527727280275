import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';
import { Presence } from '@boss/ui';

import { useContacts } from '../../client-queries';
import { FormType, useFormField, useFormSubmit, useProfile } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

export const FORM_FIELD_KEYS = [
  'clientnumber',
  'companyname',
  'firstname',
  'lastname',
  'email',
  'preferenceDate',
  'servicemessage',
  'message',
  'termsandconditions',
];

type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

const B2BDecoDesignForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type,
}: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useFormSubmit();
  const { locale } = useRouter();
  const { data: contacts } = useContacts(locale);
  const { data: profile } = useProfile();
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const { t } = useTranslation('forms');

  const { clientnumber, companyname, firstname, lastname, email, message, termsandconditions } = useFormField();

  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...companyname,
      disabled: true,
    },
    {
      ...firstname,
      initialValue: contact?.firstname ?? '',
      disabled: true,
    },
    {
      ...lastname,
      initialValue: contact?.lastname ?? '',
      disabled: true,
    },
    {
      ...email,
      initialValue: contact?.email ?? '',
      disabled: true,
    },
    {
      name: 'preferenceDate',
      label: t('fields.preferredDate') ?? '',
      type: 'date',
      initialValue: new Date().toString(),
      required: true,
    },
    {
      name: 'servicemessage',
      label: t('fields.deco') ?? '',
      type: 'textarea',
      required: true,
    },
    {
      ...message,
      required: false,
      label: t('fields.extrainfo') ?? '',
    },
    termsandconditions,
  ];

  const getFieldsToShow = () => {
    return fieldsToShow ?? FORM_FIELD_KEYS;
  };

  const handleSubmit = (vals: FormValues) => {
    const formObject = {
      firstname: vals.firstname,
      lastname: vals.lastname,
      contactpersonid: contact?.id ?? '',
      email: vals.email,
      type: 'DecoDesign',
      preferenceDate: vals.preferenceDate,
      message: vals.servicemessage + ' | ' + vals.message,
    };

    onSubmit(type, formObject);
  };

  return (
    <Presence className={twMerge('flex flex-col gap-4', className)} id="help-form-presence" visible>
      <DynamicForm
        buttonProps={{
          label: t('buttons.submitQuestion') ?? '',
        }}
        fields={buildFormFields(baseFields, getFieldsToShow(), additionalFields, fieldsOverwrite)}
        id={type}
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        onCloseAlert={reset}
        onSubmit={handleSubmit}
        variant="light"
      />
    </Presence>
  );
};

export default B2BDecoDesignForm;
