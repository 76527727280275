/**
 * A module for fetching data from the BOSS API.
 * @module boss-api
 */

import { Nullable } from '@boss/types/b2b-b2c';
import nodefetch, { RequestInit } from 'node-fetch';

import { getApiCredentials } from './api-credentials';
import ApiException from './api-exception';
import getBaseUrl from './get-base-url';
import responseHandler from './response-handler';
import { mapLocale } from '../mappers';
import requestToken from '../requestToken';

/**
 * Options for making API requests.
 * @typedef {Object} ApiOptions
 * @property {'GET' | 'POST' | 'PUT' | 'DELETE'} [method='GET'] - The HTTP method.
 * @property {Object.<string, string>} [headers={}] - The request headers.
 * @property {Nullable<Object.<string, unknown>>} [body=null] - The request body.
 * @property {Nullable<boolean>} [m2m=null] - Whether it's a machine-to-machine request.
 * @property {Nullable<string>} [authScope=null] - The authorization scope.
 * @property {Nullable<string>} [locale=null] - The locale for the request.
 * @property {Nullable<string>} [token=null] - The authentication token.
 */
interface ApiOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers?: Record<string, string>;
  body?: Nullable<Record<string, unknown>> | unknown[];
  m2m?: Nullable<boolean>;
  authScope?: Nullable<string>;
  locale: Nullable<string>;
}

/**
 * Fetches data from the BOSS API.
 * @function
 * @async
 * @template T
 * @param {string} path - The API endpoint path.
 * @param {ApiOptions} [options={}] - The request options.
 * @param {boolean} [retry=false] - Whether to retry the request in case of a 401 response.
 * @throws {ApiException} Throws an error if the request fails.
 * @returns {Promise<Nullable<T>>} A promise that resolves to the API response data or null.
 */
const fetchAPI = async <T>(
  path: string,
  options: ApiOptions = {
    method: 'GET',
    headers: {},
    body: null,
    m2m: true,
    authScope: '',
    locale: null,
  },
  retry = false,
): Promise<Nullable<T>> => {
  try {
    // NOTE: A nullable promise makes no sense
    // TODO: Dangerous URI concatenation, fix
    const url = `${getBaseUrl()}${path}`;

    const { method, headers = {}, body, m2m, authScope, locale } = options;

    const mappedLocale = locale ? mapLocale(locale) : '';

    const requestOptions: RequestInit = {
      method: method ?? 'GET',
      headers: {
        ...headers,
        ...(mappedLocale && { 'Accept-Language': mappedLocale.toLowerCase() }),
      },
    };

    // NOTE: Technically false positives could occur when defining
    // window and document
    if (m2m && typeof window !== 'undefined' && typeof document !== 'undefined') {
      throw new ApiException('Unauthorized request', 401, url);
    }

    // Only add an authorization token when M2M connection.
    // Tokens should never be passed from the client side.
    if (m2m) {
      if (!authScope) {
        throw new Error('When a client_credentials flow is used, a scope is required.');
      }

      const token = await requestToken(authScope, getApiCredentials(locale ?? ''));

      requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    if (body) {
      requestOptions.body = JSON.stringify(body);
    }

    const response = await nodefetch(url, requestOptions);

    if (response.status === 401 && m2m && !retry) {
      // If the response status is 401,
      // Request a new token and retry the request
      return fetchAPI<T>(path, options, true);
    }

    return responseHandler<T>(response, url);
  } catch (error) {
    console.error(`FAILED API REQUEST for ${path}` + error);
    throw new ApiException('FAILED API REQUEST:', 500, path);
  }
};

export default fetchAPI;
