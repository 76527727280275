import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { useGeoLocation, useRouter } from '@boss/hooks';
import { IStore } from '@boss/services';
import { IWishlist } from '@boss/services/client';
import { BasicVariant, IAddressSuggestion, IArticle, Image } from '@boss/types/b2b-b2c';
import { Container, Overlay, Repeat, ShopCard, Skeleton, StockProductCard } from '@boss/ui';
import { calculatePriceByQuantity, formatPrice } from '@boss/utils';

import StockProductCardWrapper from './ProductWrapper';
import { StoreFinder } from '../..';
import { Coordinates, Sku, useOrderStoresByProx, usePointOfSale, usePrice, useStock } from '../../../hooks';
import { getRemarks } from '../../../utils';
import { storeFinderVariant } from '../../../utils/options';
import { ArticleIdsObject } from '../../ArticlesTable';

type Props = {
  onClose: () => void;
  selectedArticleIdsObject: ArticleIdsObject;
  articles: IArticle[] | undefined | null;
  variant: BasicVariant;
  productImage?: Image;
  saveStore: (store: IStore) => void;
  unavailableProducts?: string[];
  onRemoveProduct?: (lineId: string) => void;
  basketLoading?: boolean;
  wishlists?: IWishlist[];
  selectedStore?: string;
  onUpdateWishlist?: ({ wishlistId, lineId }: { wishlistId: string; lineId: string }) => void;
  onCreateWishlist?: ({ description, lineId }: { description: string; lineId: string }) => void;
  hideStoreSelection?: boolean;
};

enum stockStatus {
  AVAILABLE = 'available',
  LOW_STOCK = 'limited',
  NOT_AVAILABLE = 'outOfStock',
}

/**
 * Will generate the object to send to the Stock API.
 * It needs a list of storeIds (filteredIds) and a list of SKUs depending on the selected articles
 * @date 25-9-2023 - 10:13:40
 *
 */
const generateStockObject = (
  articleIdsObject: ArticleIdsObject,
  allArticles: IArticle[] | undefined | null,
  storeList: IStore[] | null,
) => {
  const SKUs: Sku[] = [];
  let filteredIds: string[] = [];

  if (storeList && allArticles) {
    filteredIds = storeList?.map(e => {
      return e.coloradepotid;
    });

    if (Object.keys(articleIdsObject).length > 0) {
      Object.entries(articleIdsObject).forEach(([articleId, selectedArticleObject]) => {
        const foundArticle = allArticles?.find(x => x.id === articleId);

        if (foundArticle) {
          SKUs.push({
            id: foundArticle.id,
            skuId: selectedArticleObject.stockproductnumber ?? foundArticle.stockproductnumber,
            ...selectedArticleObject,
          });
        }
      });
    }
  }
  return { SKUs: SKUs, storeIds: filteredIds };
};

const StockModal = ({
  onClose,
  selectedArticleIdsObject,
  articles,
  variant,
  productImage,
  saveStore,
  unavailableProducts,
  onRemoveProduct: handleRemoveProduct,
  basketLoading = false,
  wishlists,
  selectedStore,
  onUpdateWishlist: handleUpdateWishlist,
  onCreateWishlist: handleCreateWishlist,
  hideStoreSelection,
}: Props) => {
  const { t } = useTranslation(['stores', 'routes', 'product']);
  const [manualSearch, setManualSearch] = useState(false);
  const { stores } = usePointOfSale();
  const { sortStoresByLocationId, filteredStores, sortStoresByCoord } = useOrderStoresByProx(stores);

  const sortedStoreList = filteredStores.slice(0, filteredStores.length > 5 ? 5 : filteredStores.length);
  const stockObject = generateStockObject(selectedArticleIdsObject, articles, sortedStoreList);
  const { latitude, longitude, fetchGeolocation, loading: isWaitingForGeolocation } = useGeoLocation();
  const { stock, isLoading } = useStock({
    warehouseIds: stockObject.storeIds,
    skus: stockObject.SKUs,
    disableStockFetch: hideStoreSelection,
  });

  const { locale } = useRouter();
  const { showPrice } = usePrice();

  const onSearch = (suggestion: IAddressSuggestion) => {
    setManualSearch(true);
    sortStoresByLocationId(suggestion);
  };

  const handleSortByCurrentLocation = (c: Coordinates) => {
    sortStoresByCoord(c);
    setManualSearch(false);
  };

  useEffect(() => {
    fetchGeolocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (longitude && latitude && !manualSearch) {
      sortStoresByCoord({ longitude, latitude });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longitude, latitude, manualSearch]);

  const handleSelectStore = (store: IStore) => {
    saveStore(store);
  };

  return (
    <Overlay isVisible onClose={onClose}>
      <h2 className="max-w-4/5">{t('stockModal.title', { ns: 'product' })}</h2>
      <div className="py-5" data-testid="stock-products">
        {basketLoading ? (
          <Repeat amount={articles?.length ?? 5}>
            <Skeleton className="border-gray border-b-1 h-30 w-full pb-4 pl-8 pt-4 last-of-type:border-b-0" />
          </Repeat>
        ) : (
          // eslint-disable-next-line sonarjs/cognitive-complexity
          Object.entries(selectedArticleIdsObject).map(([articleId, articleValues]) => {
            const article = articles?.find(x => x.id === articleId);
            const { quantity, unit, productImageSrc, lineId, stockproductnumber } = articleValues;
            const unitTranslation = t(`unit.${unit}${quantity > 1 ? 'Plural' : ''}`, { ns: 'product' }) ?? '';

            if (!article) {
              return null;
            }

            return (
              <StockProductCardWrapper
                inStock={!unavailableProducts?.includes(stockproductnumber ?? '')}
                key={articleId}
                name={article.name}
                onAddToWishlist={
                  handleUpdateWishlist
                    ? (wishlistId: string) =>
                        handleUpdateWishlist({
                          lineId: lineId ?? '',
                          wishlistId,
                        })
                    : undefined
                }
                onCreateWishlist={
                  handleCreateWishlist
                    ? (description: string) =>
                        handleCreateWishlist({
                          description,
                          lineId: lineId ?? '',
                        })
                    : undefined
                }
                onRemoveProduct={handleRemoveProduct && lineId ? () => handleRemoveProduct(lineId) : undefined}
                selectedStore={selectedStore}
                wishlists={wishlists}
              >
                <StockProductCard
                  className="my-3 p-4"
                  price={
                    showPrice
                      ? formatPrice(calculatePriceByQuantity(article?.pricing.price, quantity), locale)
                      : undefined
                  }
                  productImage={{
                    alt: productImage?.image?.title ?? article?.name,
                    src: productImageSrc ?? productImage?.src,
                  }}
                  title={`${quantity} ${unitTranslation} ${article?.name}`}
                  variant={variant}
                />
              </StockProductCardWrapper>
            );
          })
        )}
      </div>
      {!hideStoreSelection && (
        <>
          <StoreFinder
            compact
            onSearch={onSearch}
            sortByCurrentLocation={c => handleSortByCurrentLocation(c)}
            translations={t('storeFinder', { returnObjects: true })}
            variant={storeFinderVariant}
          />
          <Container className="my-4 px-0 xl:px-6" data-testid="stock-store-results">
            {isWaitingForGeolocation || isLoading || !sortedStoreList || !Object.keys(stock).length ? (
              <Repeat amount={3}>
                <Skeleton className="border-gray border-b-1 h-30 w-full pb-4 pl-8 pt-4 last-of-type:border-b-0" />
              </Repeat>
            ) : (
              <>
                {!manualSearch && (
                  <p>
                    {t('results.amountOfResults', {
                      ns: 'stores',
                      amount: sortedStoreList?.length,
                    })}
                  </p>
                )}
                {sortedStoreList?.map(store => (
                  <ShopCard
                    key={store.id}
                    selectStore={handleSelectStore}
                    stockLoading={isLoading}
                    stockRemarks={getRemarks(store, t)}
                    stockStatus={stockStatus[stock[store.coloradepotid]]}
                    store={store}
                    translations={t('storeFinder', { ns: 'stores', returnObjects: true })}
                  />
                ))}
              </>
            )}
          </Container>
        </>
      )}
    </Overlay>
  );
};

export default StockModal;
