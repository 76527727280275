import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fuse from 'fuse.js';
import { ChangeEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from 'usehooks-ts';

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  label?: ReactNode;
  searchLabel?: string;
  value?: string | null;
  onChange: (value: string) => void;
  selectLabel?: string;
  className?: string;
  dropdownClassName?: string;
  error?: boolean;
}

export const SearchDropdown = ({
  options,
  label,
  searchLabel,
  value,
  onChange: handleChange,
  selectLabel,
  className,
  dropdownClassName,
  error,
}: Props) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(wrapperRef, () => setIsOpen(false));

  const fuse = useMemo(() => {
    const _options = {
      keys: ['label'],
    };

    const index = Fuse.createIndex(_options.keys, options);

    return new Fuse(options, _options, index);
  }, [options]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const results = fuse.search(searchTerm).map(result => result.item);

      setFilteredOptions(searchTerm ? results : options);
    }, 100);

    return () => clearTimeout(timer);
  }, [searchTerm, options, fuse]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectOption = (option: Option) => {
    handleChange(option.value);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleOnEnter = (e: React.KeyboardEvent<HTMLLIElement>, option: Option) => {
    if (e.key !== 'Enter') {
      return;
    }

    handleSelectOption(option);
  };

  return (
    <div className={twMerge('relative md:flex', className)} ref={wrapperRef}>
      <div
        className={twMerge(
          'rounded-30 border-1 min-w-48 border-generic-gray z-0 flex cursor-pointer items-center justify-between p-2.5 pl-4',
          dropdownClassName,
          error && 'border-error',
        )}
        data-testid="stores-dropdown"
        onClick={toggleDropdown}
      >
        <span className="mr-3">
          {value ? options.find(option => option.value === value)?.label : label ?? selectLabel ?? 'Select an option'}
        </span>
        <FontAwesomeIcon
          className={`transform transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`}
          icon={faChevronDown}
          size="sm"
        />
      </div>
      {isOpen && (
        <div className="max-h-75 border-1 border-generic-gray absolute left-0 top-full z-50 w-full overflow-y-auto bg-white md:w-auto">
          {options.length > 1 && (
            <input
              className="w-full bg-white p-2 italic"
              onChange={handleInputChange}
              placeholder={searchLabel ?? 'Search...'}
              type="text"
              value={searchTerm}
            />
          )}
          <ul className="max-h-60 overflow-y-auto">
            {filteredOptions.map(option => (
              <li
                className="cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100"
                key={option.value}
                onClick={() => handleSelectOption(option)}
                onKeyDown={e => handleOnEnter(e, option)}
                tabIndex={0}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
