import { useQuery } from '@tanstack/react-query';

import { useRouter } from '@boss/hooks';
import { IStore } from '@boss/services';

import { fetchAllPointsOfSales, fetchAllStores, fetchStore } from './connector';
import { useAccount } from '../account';

import { storeKeys } from '.';

/**
 * useQuery implementation to fetch all stores. Returns a Promise of StoreResponse
 * @date 7-9-2023 - 08:45:02
 *
 * @returns {Promise<StoreResponse>}
 */
const useStores = () => {
  const { locale } = useRouter();

  return useQuery<IStore[]>(storeKeys.allStores(locale), async () => await fetchAllStores(locale));
};

const usePointsOfSales = (deliverymethod: number) => {
  const { locale } = useRouter();

  return useQuery<IStore[]>(
    storeKeys.AllPointsOfSale(locale, deliverymethod),
    async () => await fetchAllPointsOfSales(locale, deliverymethod),
  );
};

/**
 * Client side fetch request that fetches the userAccount peferred shop id, calls for the full shop and retuns a useQuery object
 *
 * @returns UseQueryResult<IStore, unknown>
 * @param locale
 */
const useAccountStore = (locale: string) => {
  const { data: account } = useAccount(locale);
  const id = account?.preference.shop || '';

  return useQuery<IStore>(storeKeys.accountStore(id), async () => await fetchStore(id, locale), { enabled: !!id });
};

export { useAccountStore, usePointsOfSales, useStores };
