import * as bossClient from '@boss/boss-client';

import { IColorGroup } from './types';

export const getColorGroups = async () => {
  const colorGroups = await bossClient.colorClient.getColorGroups();

  const mappedColorGroups: IColorGroup[] = [];

  colorGroups?.forEach(colorGroup => {
    // Since all color groups have a sortorder, use that value to order the list
    mappedColorGroups[colorGroup.sortorder] = mapColorGroup(colorGroup);
  });

  return mappedColorGroups?.filter(group => !!group); // Exclude skipped sortorder values
};

const mapColorGroup = (colorGroup: bossClient.types.color.ColorGroup): IColorGroup => ({
  id: colorGroup.colorgroupid,
  code: colorGroup.colorgroupid,
  name: colorGroup.colorgroupname,
  rgb: colorGroup.rgb,
  sortOrder: colorGroup.sortorder,
});

export const mapColor = (bcolor: bossClient.types.color.Color | bossClient.types.color.ColorDetail) => {
  let color: bossClient.types.color.Color;

  if ('color' in bcolor) {
    color = bcolor.color;
  } else {
    color = bcolor;
  }

  return {
    id: color.colorid,
    slug: color.slug,
    rgb: color.rgb,
    trending: !!bcolor.tags?.includes('Trend'),
    name: color.colorname,
    code: color.colorcode,
    groups: color.colorgroup?.map(group => mapColorGroup(group)) || null,
    inspirations: color.inspirations ?? null,
  };
};
