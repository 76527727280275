export const PAYMENT_TYPE = {
  cash: 1,
  invoice: 2,
  cashWorkshop: 3,
} as const;

export type PAYMENT_TYPE_UNION = (typeof PAYMENT_TYPE)[keyof typeof PAYMENT_TYPE];

export interface IPaymentConfig {
  status: 'active' | 'inactive';
  paymentMethods: IPaymentMethod[];
}

export interface IPaymentMethod {
  id: string;
  name: string;
  description: string;
  image: string;
}

export interface IPaymentRequest {
  type: PAYMENT_TYPE_UNION;
  paymentUrl?: string;
  payId?: string;
}

export type Status = 'pending' | 'success' | 'failed';

export interface IPaymentStatus {
  status: Status;
  orderId?: string;
  reference?: string;
}
