import { ArticleCard } from '@boss/ui';

import { useAccountPriceInfoByProductId } from '../../client-queries';
import { useProductMap } from '../../hooks';

const ArticleCardWrapper = ({ ...props }: React.ComponentProps<typeof ArticleCard>) => {
  const { data: accountPriceInfo } = useAccountPriceInfoByProductId(props.article.productId);
  const { mapProductLabels } = useProductMap();

  const labels = mapProductLabels(accountPriceInfo?.labels ?? []);

  return <ArticleCard {...props} labels={labels} />;
};

export default ArticleCardWrapper;
