import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';

import { ProductCardProps } from '..';
import { Button, CardLabel, FavoriteButton, Image, Link, Price, Skeleton, Specifications, Tooltip } from '../..';

const ProductCardSecondary = ({
  cartLabel = 'Bestel',
  className,
  description,
  handleAddToCart,
  handleAddToWishlist,
  imageSrc,
  inWishlist,
  labels,
  price,
  slug,
  specifications,
  testid = 'product-card',
  title,
  translations,
  cartIcon,
  hideAddToCartAction,
  handleRemove,
  priceIsLoading,
  trackInfo,
}: ProductCardProps) => (
  <div
    className={twMerge('shadow-m relative flex h-full flex-col gap-6 rounded bg-white p-5', className)}
    data-testid={testid}
  >
    <Link className="relative flex flex-col justify-between gap-6" href={slug} trackInfo={trackInfo}>
      {imageSrc && (
        <div className="w-full">
          <Image className="h-25 object-contain sm:h-40" height={200} src={imageSrc} useNext width={200} />
        </div>
      )}

      <div className="flex h-full flex-col gap-2">
        <div className="flex flex-col">
          <h3 className="overflow-hidden text-ellipsis">{title}</h3>
          {description && <span>{description}</span>}
        </div>

        {!!specifications?.length && (
          <div className="pb-3">
            <Specifications className="self-center" id={`${slug}-${title}`} items={specifications} />
          </div>
        )}
      </div>
    </Link>
    {!!labels?.length && (
      <div className="absolute right-0 flex flex-col items-end gap-1">
        {labels.map((label, index) => (
          <CardLabel className="w-fit" key={`${title}-label-${index}`} round="left" {...label} />
        ))}
      </div>
    )}
    <div className="mt-auto flex flex-wrap justify-between gap-5">
      {priceIsLoading && <Skeleton className="h-12 w-20" />}
      {!!price && (
        <div>
          {translations?.disclaimer ? (
            <Tooltip
              buttonClassName="top-2.5"
              content={<span className="small italic">{translations.disclaimer}</span>}
            >
              <Price prefix translations={translations} value={price} />
            </Tooltip>
          ) : (
            <div>
              <Price prefix translations={translations} value={price} />
            </div>
          )}
          {translations?.unitPrice && <div className="text-sm text-gray-500">{translations.unitPrice}</div>}
        </div>
      )}
      <div className="ml-auto flex items-center gap-2 self-end">
        {handleAddToWishlist && <FavoriteButton isFavorite={inWishlist} onClick={handleAddToWishlist} />}
        {(slug || !!handleAddToCart) && !hideAddToCartAction && (
          <Button
            className="h-8 w-fit py-0 text-base"
            href={handleAddToCart ? undefined : slug}
            icon={cartIcon}
            iconPosition="right"
            label={cartLabel}
            onClick={handleAddToCart}
            trackInfo={trackInfo}
            type="primary"
          />
        )}
        {handleRemove && (
          <Button
            className="text-red ml-auto h-8 w-fit	py-0 text-base"
            icon={faTrashCan}
            iconPosition="right"
            onClick={handleRemove}
            type="secondary"
          />
        )}
      </div>
    </div>
  </div>
);

export default ProductCardSecondary;
