import { ImageParameterOptions, ImageProps } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import NextImage from 'next/image';
import { twMerge } from 'tailwind-merge';

const buildParameters = (options: ImageParameterOptions) => {
  const map: Record<keyof Omit<ImageParameterOptions, 'breakpoints'>, string> = {
    width: 'w',
    height: 'h',
    format: 'fm',
    quality: 'q',
    focalpoint: 'f',
    resizingBehavior: 'fit',
  };

  return Object.entries(options)
    .filter(([key, value]) => key in map && value !== undefined)
    .map(([key, value]) => `${map[key as keyof typeof map]}=${value}&`)
    .join('')
    .replace(/&+$/, '')
    .toLowerCase();
};

const _buildSource = (url: string, options: ImageParameterOptions) => {
  const { breakpoints, width, format } = options;

  return {
    type: `image/${format?.toLowerCase()}`,
    srcSet: breakpoints
      ?.map(breakpoint => {
        const parameters = buildParameters({
          ...options,
          width: width || breakpoint,
        });

        return `${url}?${parameters} ${breakpoint}w`;
      })
      .join(', '),
  };
};

export const ImageStyles = cva('w-full h-full object-cover', {
  variants: {
    round: {
      all: 'rounded-30',
      tr: 'rounded-tr-30',
      br: 'rounded-br-30',
      bl: 'rounded-bl-30',
      tl: 'rounded-tl-30',
    },
    square: {
      all: 'rounded-none',
      tr: 'rounded-tr-none',
      br: 'rounded-br-none',
      bl: 'rounded-bl-none',
      tl: 'rounded-tl-none',
    },
  },
});

const ContentfulImage = ({
  image,
  focalpoint = 'center',
  className,
  alt,
  formats = ['WEBP', 'JPG'],
  breakpoints = [640, 768, 1024, 1280, 1536],
  quality = 85,
  width,
  height,
  resizingBehavior,
  round,
  square,
  useNext, // for non-Contentful images
  fill,
  src,
  style,
  wrapperClassName,
  contain,
}: ImageProps) => {
  const altText = alt || image?.title || image?.description;

  const imageStylesProps = {
    round,
    square,
  };

  const imageUrl = src || image?.file?.url;

  const url = `${imageUrl}?${buildParameters({
    quality,
    width,
    height,
    focalpoint,
    resizingBehavior,
  })}`;

  if (useNext && imageUrl) {
    return (
      <NextImage
        alt={altText ?? 'Alt image'}
        className={twMerge(ImageStyles({ ...imageStylesProps }), className)}
        fill={fill}
        height={height}
        objectFit={contain ? 'contain' : 'cover'}
        src={imageUrl}
        style={style}
        width={width}
      />
    );
  }

  if (!imageUrl) {
    return <div>{altText || 'Image not valid'}</div>;
  }

  const sources = formats.map(format =>
    _buildSource(imageUrl, {
      quality,
      format,
      focalpoint,
      breakpoints,
      width,
      height,
      resizingBehavior,
    }),
  );

  return (
    <div className={twMerge('relative h-full w-full', wrapperClassName)}>
      <picture className={twMerge(ImageStyles({ ...imageStylesProps }), className)}>
        {sources.map(source => (
          <source
            key={`${source.type}-${image?.file.fileName ?? alt}`}
            media={source.type}
            srcSet={source.srcSet}
            type={source.type}
          />
        ))}
        <img
          alt={altText}
          className={twMerge(ImageStyles({ ...imageStylesProps }), className)}
          height={height}
          onContextMenu={e => e.preventDefault()}
          src={url}
          style={{
            objectPosition: focalpoint,
            ...(contain ? { objectFit: 'contain' } : {}),
            ...(style || {}),
          }}
          width={width}
        />
      </picture>
    </div>
  );
};

export default ContentfulImage;
