interface Location {
  distance?: number;
  latitude?: number;
  longitude?: number;
}

export type Coordinates = { latitude: number; longitude: number };

/**
 * Calculate the distance between two coordinates on earth as the crow flies
 * The calculation is done with the Haversine formula
 *
 * @returns distance in km
 */
const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const radius = 6371; // Earth's radius in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return radius * c;
};

/**
 * Sorts an array of locations from closest to furthest starting from the target coordinates.
 * Locations without coordinates are placed at the end of the list.
 */
const sortByClosestLocation = <T extends Location>(arr: T[], targetCoordinates: Coordinates): T[] => {
  const locationsWithDistance = arr.map(obj => {
    const { latitude, longitude } = obj;
    const { latitude: lat, longitude: lng } = targetCoordinates;

    // If either latitude or longitude is missing, set distance as undefined
    const distance =
      latitude !== undefined && longitude !== undefined ? calculateDistance(latitude, longitude, lat, lng) : undefined;

    return { ...obj, distance } as T;
  });

  locationsWithDistance.sort((a, b) => {
    // Move locations without coordinates (distance undefined) to the end
    if (a.distance === undefined && b.distance === undefined) {
      return 0;
    }
    if (a.distance === undefined) {
      return 1;
    }
    if (b.distance === undefined) {
      return -1;
    }
    return a.distance - b.distance; // Sort by distance
  });

  return locationsWithDistance;
};

export { calculateDistance, sortByClosestLocation };
