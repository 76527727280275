import * as Sentry from '@sentry/nextjs';
/**
 * Client query to fetch all stores, needs a locale, will return a promise of StoreResponse
 * @date 7-9-2023 - 08:42:51
 *
 * @async
 * @param {string} locale
 * @returns {Promise<StoreResponse>}
 */
const fetchAllStores = async (locale: string) => {
  try {
    const stores = await fetch(`/api/stores?locale=${locale}`);

    return await stores.json();
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch stores',
      },
    });
  }
};

const fetchAllPointsOfSales = async (locale: string, deliverymethod: number) => {
  try {
    const stores = await fetch(`/api/stores/pointsofsale?locale=${locale}&deliverymethod=${deliverymethod}`);

    return await stores.json();
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch Points of sale',
      },
    });
  }
};

/**
 * Client side request to fetch a single store from Next API
 *
 * @param id - string
 * returns  Promise<any>
 */
const fetchStore = async (id: string, locale: string) => {
  try {
    const store = await fetch(`/api/stores/${id}?locale=${locale}`);

    return await store.json();
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch store from client side',
      },
    });
  }
};

export { fetchAllStores, fetchAllPointsOfSales, fetchStore };
