export const TRANSPARENT_ATTRIBUTE_KEY = 'Transparant graad';
export const TRANSPARENT_ATTRIBUTE_VALUE = 'transpar';
export const SURFACE_ATTRIBUTE_KEY = 'Ondergrond';
export const SURFACE_ATTRIBUTE_VALUE_NL = 'hout';
export const SURFACE_ATTRIBUTE_VALUE_FR = 'bois';
export enum COLOR_TYPE {
  MYMX = 'mymx',
  PREMIXED = 'premixed',
  BASE = 'base',
}
export enum COLOR_TRANSLATION {
  MYMX = 'standardColor',
  PREMIXED = 'color',
  BASE = 'base',
}

export enum INVERSED_COLOR_TYPE {
  'mymx' = COLOR_TYPE.MYMX,
  'premixed' = COLOR_TYPE.PREMIXED,
  'base' = COLOR_TYPE.BASE,
}

export enum CONSUMPTION_UNIT {
  LITER = 'm²/l',
  KILO = 'm²/kg',
}
