import { WorkerOrderType } from '@boss/services/client';

export * from './queries';

export const incidentTypeKeys = {
  allIncidentTypes: (workerOrderType: WorkerOrderType, locale: string) => [
    'ALL INCIDENT TYPES',
    workerOrderType,
    locale,
  ],
};
