const HEADERS = {
  'Content-Type': 'application/json',
};

/**
 * Client mutation to submit a form
 *
 * @async
 * @param {Record<string, string>} formData The form data
 * @returns {Promise}
 */
const submitForm = async (formData: Record<string, unknown>, locale: string) => {
  const response = await fetch('/api/form', {
    method: 'POST',
    body: JSON.stringify(formData),
    headers: {
      ...HEADERS,
      'Accept-Language': locale,
    },
  });

  if (response.status === 200) {
    return response;
  }

  throw new Error(`${response.status}: ${response.statusText}`);
};

export { submitForm };
