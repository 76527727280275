import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { storePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';
import { Link, Note, Presence } from '@boss/ui';

import { useContacts } from '../../client-queries';
import { COLORA_LINK } from '../../constants';
import { FormType, useFormField, useFormSubmit, useProfile } from '../../hooks';
import { buildFormFields, isB2b } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

export const FORM_FIELD_KEYS = [
  'clienttype',
  'companyname',
  'kvknumber',
  'phonenumber',
  'email',
  'question',
  'termsandconditions',
];

const CLIENT_TYPES = ['professionalClient', 'professionalNonClient', 'private'];

type ClientType = (typeof CLIENT_TYPES)[number];
type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type UserOption = {
  value: ClientType;
  label: string;
};

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

const HelpForm = ({ fieldsToShow: initialFieldsToShow, fieldsOverwrite, additionalFields, className, type }: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useFormSubmit();

  const { locale } = useRouter();
  const { data: profile, isLoggedIn } = useProfile();
  const { data: contacts } = useContacts(locale);
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const { t } = useTranslation('forms');

  const { email, phonenumber, termsandconditions, companyname, kvknumber, question } = useFormField();

  const userOptions: UserOption[] = [
    {
      value: 'professionalClient',
      label: t('select.clientTypeOptions.professionalClient'),
    },
    {
      value: 'professionalNonClient',
      label: t('select.clientTypeOptions.professionalNonClient'),
    },
    {
      value: 'private',
      label: t('select.clientTypeOptions.private'),
    },
  ];

  const [clientType, setClientType] = useState(userOptions[0].value);
  const isPrivate = clientType === 'private';

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    const newClientType = CLIENT_TYPES.find(type => type === formik.values.clienttype);

    if (newClientType && clientType !== newClientType) {
      setClientType(newClientType);
    }
  };

  let baseFields: FormField[] = [
    companyname,
    kvknumber,
    phonenumber,
    email,
    {
      ...question,
      label: t('fields.questionHelp'),
    },
    termsandconditions,
  ];

  // when logged in, we will fill in client type in advance by account
  if (!isLoggedIn || !isB2b) {
    baseFields = [
      {
        name: 'clienttype',
        type: 'select',
        options: userOptions,
        initialValue: userOptions[0].value,
      },
      ...baseFields,
    ];
  }

  const getFieldsToShow = () => {
    const fields = fieldsToShow ?? FORM_FIELD_KEYS;

    if (clientType === 'private') {
      return ['clienttype'];
    }

    return fields;
  };

  const handleSubmit = (vals: FormValues) => {
    const email = vals.email ?? contact?.email ?? '';

    onSubmit(type, { ...vals, email });
  };

  return (
    <Presence className={twMerge('flex flex-col gap-4', className)} id="help-form-presence" visible>
      <DynamicForm
        buttonProps={{
          label: t('buttons.submitQuestion') ?? '',
        }}
        fields={buildFormFields(baseFields, getFieldsToShow(), additionalFields, fieldsOverwrite)}
        id={type}
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        onCloseAlert={reset}
        onFormValuesChange={handleFormValuesChange}
        onSubmit={handleSubmit}
        showSubmitButton={!isPrivate}
        variant="light"
      />
      {isPrivate && (
        <div className="grid gap-5 md:grid-cols-2">
          <Note>
            <span>{t('note.private.1')}</span>{' '}
            <Link className="underline" href={COLORA_LINK}>
              {t('note.private.2')}
            </Link>{' '}
            <span>{t('note.private.3')}</span>{' '}
            <Link className="underline" href={storePageSlugs[locale]}>
              {t('note.private.4')}
            </Link>
          </Note>
        </div>
      )}
    </Presence>
  );
};

export default HelpForm;
