import { useEffect, useState } from 'react';

import { IStore } from '@boss/services';
import { IAddressSuggestion } from '@boss/types/b2b-b2c';
import { shopIsOpen, sortByClosestLocation } from '@boss/utils';

import { useAddressById } from '../../client-queries';

export type Coordinates = { latitude: number; longitude: number };

const sortByOpenStatus = (stores: IStore[]): IStore[] => {
  return [...stores.filter(store => shopIsOpen(store)), ...stores.filter(store => !shopIsOpen(store))];
};

/**
 * Custom hook for sorting the stores by distance, either by given coordinates
 * or by searching for coordinates with the BOSS API
 *
 * @returns object with search functions and react state
 */
export const useOrderStoresByProx = (stores: IStore[] = []) => {
  const [shouldSortByOpenStatus, setShouldSortByOpenStatus] = useState(false);
  const [isCurrentLocationSearch, setIsCurrentLocationSearch] = useState(false);
  const [location, setLocation] = useState('');

  const [filteredStores, setFilteredStores] = useState<IStore[]>([]);
  const [placeId, setPlaceId] = useState('');

  // Sort the stores with a fixed given coordinate
  const sortStoresByCoord = (c: Coordinates) => {
    if (stores?.length) {
      const sortedStores = sortByClosestLocation(stores, c);

      setFilteredStores(shouldSortByOpenStatus ? sortByOpenStatus(sortedStores) : sortedStores);
      setIsCurrentLocationSearch(true);
    }
  };

  const sortStoresByLocationId = (suggestion: IAddressSuggestion) => {
    setPlaceId(suggestion.placeid);
    setLocation(suggestion.description);
  };

  const resetStoreOrder = () => {
    setPlaceId('');
    setLocation('');
    setFilteredStores(stores);
    setIsCurrentLocationSearch(false);
  };

  // Fetch data from BOSS
  const { data: address, isLoading } = useAddressById(placeId);

  // Awaits the boss api for the lat/long values and then sort the store arr
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (stores?.length) {
      if (address) {
        const sortedStores = sortByClosestLocation(stores, address);

        setFilteredStores(shouldSortByOpenStatus ? sortByOpenStatus(sortedStores) : sortedStores);
      } else {
        setFilteredStores(stores);
      }
      setIsCurrentLocationSearch(false);
    }
  }, [stores, isLoading, address, shouldSortByOpenStatus]);

  useEffect(() => {
    if (shouldSortByOpenStatus) {
      setFilteredStores(currentStores => sortByOpenStatus(currentStores));
    }
  }, [shouldSortByOpenStatus]);

  return {
    location,
    isCurrentLocationSearch,
    sortStoresByLocationId,
    filteredStores,
    sortByOpenStatus,
    resetStoreOrder,
    sortStoresByCoord,
    setSortByOpenStatus: setShouldSortByOpenStatus,
  };
};

export default useOrderStoresByProx;
