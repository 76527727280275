import { IProduct } from '@boss/services';
import { ISearchProduct } from '@boss/types/b2b-b2c';

import {
  SURFACE_ATTRIBUTE_KEY,
  SURFACE_ATTRIBUTE_VALUE_FR,
  SURFACE_ATTRIBUTE_VALUE_NL,
  TRANSPARENT_ATTRIBUTE_KEY,
  TRANSPARENT_ATTRIBUTE_VALUE,
} from '../../constants';

type NullableStringArr = string[] | undefined | null;

const validateTransparency = (transparentAtt: NullableStringArr, surfaceAtt: NullableStringArr) =>
  !!(
    transparentAtt?.some(field => field.toLowerCase().startsWith(TRANSPARENT_ATTRIBUTE_VALUE)) &&
    surfaceAtt?.some(field => [SURFACE_ATTRIBUTE_VALUE_NL, SURFACE_ATTRIBUTE_VALUE_FR].includes(field.toLowerCase()))
  );

export const getProductColorType = (product: IProduct | ISearchProduct): 'transparant' | 'default' => {
  let isTransparent = false;

  if ('attr_transparency' in product && 'attr_surface' in product) {
    isTransparent = validateTransparency(product['attr_transparency'], product['attr_surface']);
  } else {
    isTransparent = validateTransparency(
      product.attributes?.[TRANSPARENT_ATTRIBUTE_KEY],
      product?.attributes?.[SURFACE_ATTRIBUTE_KEY],
    );
  }

  return isTransparent ? 'transparant' : 'default';
};
