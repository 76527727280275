import { useEffect, useState } from 'react';

import { Counter, InputField } from '../';

const CounterList = ({
  onChange,
  error,
  placeholder,
  listTitle,
}: {
  onChange: ({ count, items }: { count: number; items: string[] }) => void;
  error?: string;
  placeholder?: string;
  listTitle?: string;
}) => {
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [items, setItems] = useState<string[]>([]);

  useEffect(() => {
    let newItems = [...items];

    if (items.length > numberOfItems) {
      // Remove extra items
      newItems = items.slice(0, numberOfItems);
    } else if (items.length < numberOfItems) {
      // Add empty strings for new items
      newItems = [...items, ...Array(numberOfItems - items.length).fill('')];
    }

    setItems(newItems);
    onChange({ count: numberOfItems, items: newItems });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfItems]);

  useEffect(() => {
    onChange({ count: numberOfItems, items });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const handleInputChange = (index: number, value: string) => {
    setItems(prev => {
      const newItems = [...prev];

      newItems[index] = value;

      return newItems;
    });
  };

  return (
    <div className={`${error ? 'border-1 border-red-500' : ''}`}>
      <Counter onChange={setNumberOfItems} value={numberOfItems} />
      {listTitle && numberOfItems > 0 && <div className="mt-2">{listTitle}</div>}
      <div className="mt-2 flex flex-col gap-2">
        {Array.from({ length: numberOfItems }).map((_, index) => (
          <InputField
            key={index}
            name={`input-${index}`}
            onChange={event => handleInputChange(index, event.target.value)}
            placeholder={placeholder}
            value={items[index]}
          />
        ))}
      </div>
    </div>
  );
};

export default CounterList;
