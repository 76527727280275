import { faFilePdf, faFileWord } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouter } from '@boss/hooks';
import { IColorGroup, IStore } from '@boss/services';
import { ISearchPaintSystem, ISearchProduct } from '@boss/types/b2b-b2c';
import { Accordion, Link, Presence } from '@boss/ui';
import { downloadFile } from '@boss/utils';

import { useAccount, useAccountStore, useSearchProductsById } from '../../client-queries';
import { PaintguideResultsSkeleton, PaintguideResultsWrapper } from '../../components';

interface Result extends ISearchPaintSystem {
  supportProduct: ISearchProduct | undefined | null;
  finishProduct: ISearchProduct | undefined | null;
}

type Results = Result[];

type Props = {
  paintsystems: ISearchPaintSystem[];
  colorGroups: IColorGroup[] | null;
};

const DocGridItem = ({ children }: { children: ReactNode }) => <span className="text-xs sm:text-base">{children}</span>;

const PaintGuideResults = ({ paintsystems, colorGroups }: Props) => {
  const { t } = useTranslation('paintguide');

  const { locale } = useRouter();
  const [savedStore, setSavedStore] = useState<IStore>();
  const idMapRef = useRef<string[]>([]);
  const [results, setResults] = useState<Results>([]);
  const { data: products, isLoading } = useSearchProductsById(idMapRef.current, !!idMapRef.current.length);
  const { data: account } = useAccount(locale);

  const { data: accountStore } = useAccountStore(locale);

  useEffect(() => {
    if (accountStore) {
      setSavedStore(accountStore);
    }
  }, [accountStore]);

  useEffect(() => {
    for (const system of paintsystems) {
      const pushtoRefArr = (ids: string[]) => {
        if (ids[0]) {
          idMapRef.current.push(ids[0]);
        }
      };

      pushtoRefArr(system['support-product-id']);
      pushtoRefArr(system['finish-product-id']);
    }
  }, [paintsystems]);

  useEffect(() => {
    const _results: Results = [];

    for (const system of paintsystems) {
      _results.push({
        ...system,
        finishProduct: products?.find(({ id }) => system['finish-product-id'][0] === id),
        supportProduct: products?.find(({ id }) => system['support-product-id'][0] === id),
      });
    }
    setResults(_results);
  }, [products, paintsystems]);

  return (
    <Presence
      id="paintguide_b2b_results"
      isLoading={isLoading}
      loader={<PaintguideResultsSkeleton />}
      visible={!!results?.length}
    >
      <h2 className="mb-20 font-normal">{t('result')}</h2>
      {results?.map(({ id, finishProduct, supportProduct, name, publications }, i) => (
        <Accordion
          isOpen={results.length === 1}
          key={id}
          progressiveRender
          title={
            <h4 className="font-bold">
              {i + 1}. {name}
            </h4>
          }
          variant="secondary"
        >
          {(finishProduct || supportProduct) && (
            <div className="mb-20" key={id}>
              <PaintguideResultsWrapper
                colorGroups={colorGroups}
                finishProduct={finishProduct ?? undefined}
                showBaseColors={!!account?.accountinfo?.mixingmachine}
                store={savedStore}
                supportProduct={supportProduct}
              />
            </div>
          )}
          {publications && (
            <div className="mb-20 flex flex-col space-y-4">
              <h4 className="mb-3 font-bold">{t('publications')}</h4>
              {publications.map(({ resourcelink, publicationname, doctype, brand, language, type }) => (
                <Link
                  className="border-t-1 border-t-solid border-t-gray grid grid-cols-4 pt-4"
                  key={publicationname}
                  onClick={() => downloadFile(resourcelink, publicationname)}
                >
                  <DocGridItem>{t(doctype)}</DocGridItem>
                  <DocGridItem>{brand ? t('brand') : t('neutral')}</DocGridItem>
                  <DocGridItem>{language === 'nl-be' ? t('nl-be') : t('fr-be')}</DocGridItem>
                  <FontAwesomeIcon icon={type === 'PDF' ? faFilePdf : faFileWord} />
                </Link>
              ))}
            </div>
          )}
        </Accordion>
      ))}
    </Presence>
  );
};

export default PaintGuideResults;
