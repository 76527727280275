import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { cva } from 'class-variance-authority';
import { ComponentProps, Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';

import { SCREENS, trackEvents } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { IColorGroup, IPaintsystemOption } from '@boss/services';
import { ISearchPaintSystem } from '@boss/types/b2b-b2c';
import { Button, OptionCard, Presence, SurfaceCalculator } from '@boss/ui';

import PaintguideColorPicker from './colorpicker';
import PaintGuideResults from './results';
import { useProductById } from '../../client-queries';
import { InfoBox, MailPaintguideButton } from '../../components';
import { useEventTracker } from '../../hooks';
import { isB2b, stepperVariant as variant } from '../../utils';

import { Steps } from './index';

export type Answer = {
  attribute: string;
  value: string;
};

export type StepTypeUnion = 'SURFACE_CALC' | 'OPTION_SELECTOR' | 'COLOR_PICKER' | 'RESULT';

type Props = {
  attribute: string;
  options: IPaintsystemOption[];
  onSetAnswer: (answer: Answer) => void;
  selectedOption: string;
  type?: StepTypeUnion;
  paintsystems: ISearchPaintSystem[] | null | undefined;
  handleStepChange: (nextI: number, stepperEv?: boolean) => void;
  resetAnswers: () => void;
  currentStep: number;
  islastStep: boolean;
  colorGroups: IColorGroup[];
  steps: Steps;
};

type Surfaces = Exclude<ComponentProps<typeof SurfaceCalculator>['preFilledSurfaces'], undefined>;

const ButtonStyles = cva('md:px-7 md:py-3', {
  variants: {
    variant: {
      reset: 'mt-3 sm:ml-3 sm:mt-0',
    },
  },
});

const Step = ({
  attribute,
  colorGroups,
  currentStep,
  handleStepChange,
  islastStep,
  onSetAnswer: handleSetAnswer,
  options,
  paintsystems,
  resetAnswers,
  selectedOption,
  steps,
  type = 'OPTION_SELECTOR',
}: Props) => {
  const { t } = useTranslation(['paintguide', 'common', 'product']);
  const { locale } = useRouter();
  const { width: windowWidth } = useWindowSize();
  const { trackCustomEvent } = useEventTracker();

  const productId = paintsystems?.[0]?.['finish-product-id'][0] ?? '';
  const { data: product, isLoading } = useProductById(productId, !!productId && type === 'COLOR_PICKER');

  const [paintSurfaces, setPaintSurfaces] = useState<Surfaces>([]);
  const [noPaintSurfaces, setNoPaintSurfaces] = useState<Surfaces>([]);
  const [activeTTipIndex, setActiveTTipIndex] = useState(0);
  const [openTTip, setOpenTTip] = useState(false);

  const [optionTTipRef, setOptionTTipRef] = useState(0);

  const totalPaint = paintSurfaces.reduce((acc, curr) => acc + curr.total, 0);
  const totalNoPaint = noPaintSurfaces.reduce((acc, curr) => acc + curr.total, 0);
  const totalPaintNeeded = String(Math.max(0, totalPaint - totalNoPaint).toFixed(2));
  const optionWrapperRef = useRef<HTMLDivElement | null>(null);

  const setAnswer = (answer: Answer) => {
    handleSetAnswer(answer);
  };

  const genericTranslations = {
    add: t('add', { ns: 'common' }),
    height: t('height', { ns: 'common' }),
    length: t('length', { ns: 'common' }),
    width: t('width', { ns: 'common' }),
  };

  useEffect(() => {
    // Keeping the TTip open, will alter the correct position
    setOpenTTip(false);
  }, [windowWidth]);

  useOnClickOutside(optionWrapperRef, () => setOpenTTip(false));

  const handleTooltipClick = (index: number) => {
    let numRows = 1;

    if (windowWidth >= SCREENS.md && windowWidth <= SCREENS.xl) {
      numRows = 2;
    } else if (windowWidth >= SCREENS.xl) {
      numRows = 3;
    }

    const indexToAdd = numRows - (index % numRows);

    setActiveTTipIndex(index + indexToAdd);
    setOpenTTip(currState => (optionTTipRef !== index && currState) || !currState);
    setOptionTTipRef(index);
  };

  return (
    <>
      {type === 'OPTION_SELECTOR' && !!options.length && (
        <div className="mb-2 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3" ref={optionWrapperRef}>
          {options.map(({ extraInformation, id, image, answerText }, i) => (
            <Fragment key={id}>
              {openTTip && activeTTipIndex === i && (
                <InfoBox info={options[optionTTipRef].extraInformation} locale={locale} />
              )}
              <OptionCard
                active={selectedOption === id}
                hideImage={isB2b}
                imageAlt={image?.altText}
                imageSrc={image?.image.file.url}
                onClick={() => {
                  const trackingProps = {
                    wizardName: t('paintguideLabel'),
                    step: currentStep + 1,
                    stepLabel: steps[currentStep].label,
                    stepValue: i + 1,
                    stepValueLabel: answerText,
                  };

                  setAnswer({ attribute, value: id });
                  trackCustomEvent(trackEvents.WIZARD_INTERACTED, trackingProps);
                }}
                onToolTipClick={() => handleTooltipClick(i)}
                optionId={id}
                title={answerText}
                tooltip={!!extraInformation}
              />
            </Fragment>
          ))}
          {openTTip && activeTTipIndex >= options.length && (
            <InfoBox info={options[optionTTipRef].extraInformation} locale={locale} />
          )}
        </div>
      )}

      {type === 'SURFACE_CALC' && (
        <>
          <h3 className="mb-12 mt-7">{t('surfaceTitle', { ns: 'paintguide' })}</h3>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-x-10 md:gap-y-10">
            <SurfaceCalculator
              onCalculate={setPaintSurfaces}
              translations={{
                ...genericTranslations,
                title: t('productPanel.surfaceCalculator.includeSurfaceTitle', { ns: 'product' }) ?? '',
              }}
            />

            <SurfaceCalculator
              onCalculate={setNoPaintSurfaces}
              translations={{
                ...genericTranslations,
                title: t('productPanel.surfaceCalculator.excludeSurfaceTitle', { ns: 'product' }) ?? '',
              }}
            />
          </div>
          <div className="mt-20 flex w-full items-center justify-between">
            <span className="h4">
              {t('total', { ns: 'common' })}: {totalPaintNeeded} m<sup>2</sup>
            </span>
            <Button
              className="md:px-7 md:py-3"
              icon={faArrowRight}
              iconPosition="right"
              label={t('next')}
              onClick={() => setAnswer({ attribute, value: totalPaintNeeded })}
              type="primary"
            />
          </div>
        </>
      )}

      {type === 'COLOR_PICKER' && (
        <Presence id="paintguide_colorpicker" isLoading={isLoading} visible={!!product}>
          {product && (
            <PaintguideColorPicker
              colorGroups={colorGroups}
              onNextClick={(colorCode, colorBase) => setAnswer({ attribute, value: `${colorCode}+${colorBase}` })}
              preSelectedColor={selectedOption}
            />
          )}
        </Presence>
      )}

      {type === 'RESULT' && !!paintsystems?.length && (
        <PaintGuideResults colorGroups={colorGroups} paintsystems={paintsystems} />
      )}

      <div className="mt-15 flex justify-between">
        <div className="sm:flex sm:items-center">
          <Button
            className={ButtonStyles()}
            disabled={currentStep === 0}
            icon={faArrowLeft}
            iconPosition="left"
            label={t('previous')}
            onClick={() => handleStepChange(currentStep - 1, true)}
            type="secondary"
          />
          {variant === 'secondary' && (
            <Button
              className={twMerge(ButtonStyles({ variant: 'reset' }))}
              label={t('reset')}
              onClick={() => resetAnswers()}
              type="primary"
            />
          )}
        </div>
        {variant === 'secondary' &&
          (!islastStep ? (
            <Button
              className={ButtonStyles()}
              disabled={islastStep}
              icon={faArrowRight}
              iconPosition="right"
              label={t('next')}
              onClick={() => {
                const trackingProps = {
                  wizardName: t('paintguideLabel'),
                  step: currentStep + 1,
                  stepLabel: steps[currentStep].label,
                  stepValue: undefined,
                  stepValueLabel: undefined,
                };

                handleStepChange(currentStep + 1, true);
                trackCustomEvent(trackEvents.WIZARD_INTERACTED, trackingProps);
              }}
              type="primary"
            />
          ) : (
            // TODO: uncomment when asked
            //<SavePaintguideButton mailPaintguide />
            <MailPaintguideButton />
          ))}
      </div>
    </>
  );
};

export default Step;
