import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface Props {
  icon: IconDefinition;
  title: string;
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
  isSelected?: boolean;
  className?: string;
  children?: ReactNode;
  errorMessage?: ReactNode;
}

const CardStyles = cva('flex w-full min-h-[10em]', {
  variants: {
    variant: {
      primary: 'rounded-md shadow-md cursor-pointer items-stretch flex-col gap-3 md:flex-row py-5 md:py-0',
      secondary: 'flex-col gap-3 py-2',
    },
  },
});

const IconStyles = cva('flex-grow', {
  variants: {
    variant: {
      primary: 'w-full md:w-30 min-w-30 flex flex-col items-center justify-center gap-3 rounded-md bg-[#F5F5F5] p-3',
      secondary: 'flex text-blue gap-3 items-center pl-3',
    },
    isSelected: {
      true: '',
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      isSelected: true,
      class: 'bg-blue text-white',
    },
  ],
});

const IconTextStyles = cva('', {
  variants: {
    variant: {
      primary: 'small color-brown text-center font-bold',
      secondary: 'font-bold',
    },
    isSelected: {
      true: '',
    },
  },
  compoundVariants: [
    {
      variant: 'primary',
      isSelected: true,
      class: 'text-white font-normal',
    },
  ],
});

const ContentStyles = cva('flex', {
  variants: {
    variant: {
      primary: 'w-full items-center p-5',
      secondary: 'w-full shadow-md p-3 rounded-md border-1',
    },
    isSelected: {
      true: '',
    },
  },
  compoundVariants: [
    {
      variant: 'secondary',
      isSelected: true,
      class: 'border-blue',
    },
    {
      variant: 'secondary',
      isSelected: false,
      class: 'border-white',
    },
  ],
});

const DeliveryMethod = ({
  title,
  icon,
  onClick,
  variant = 'primary',
  isSelected,
  className,
  children,
  errorMessage,
}: Props) => {
  return (
    <button className={twMerge(className, CardStyles({ variant }))} onClick={onClick}>
      <div className={IconStyles({ variant, isSelected })}>
        <FontAwesomeIcon icon={icon} />
        <h3 className={IconTextStyles({ variant, isSelected })}>{title}</h3>
      </div>
      {errorMessage}
      <div className={ContentStyles({ variant, isSelected })}>{children}</div>
    </button>
  );
};

export default DeliveryMethod;
