import { cva } from 'class-variance-authority';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { IAccountPriceInfBlock, IArticle, IArticleStock } from '@boss/types/b2b-b2c';
import { CardLabel, Counter, Presence, Price, Skeleton, Table } from '@boss/ui';

import StockCheckOptions from './StockCheckOptions';
import type { Props as CommonProps } from '../';
import { useAccountPriceInfo, useArticleDiscountInfo, useStockByWarehouseIds } from '../../../client-queries';
import { STOCK_STATUS } from '../../../constants';
import { getPackagingOptions, unitConfig } from '../../../utils';
import Packaging from '../Packaging';
import type { ArticleIdsObject, ArticleValues } from '../Primary';

const BASE_HEADER_KEYS = ['netPrice', 'grossPrice', 'privatePrice'] as const;
const NON_PAINT_HEADER_KEYS = [...BASE_HEADER_KEYS, 'variant'] as const;
const PAINT_HEADER_KEYS = [...BASE_HEADER_KEYS, 'content'] as const;

type NonPaintHeaderKey = (typeof NON_PAINT_HEADER_KEYS)[number];
type PaintHeaderKey = (typeof PAINT_HEADER_KEYS)[number];

type Props = CommonProps & {
  updateSelectedArticlesIdsObject: (id: string, newObject: ArticleValues) => void;
};

const stockStyle = cva('mb-4', {
  variants: {
    variant: {
      AVAILABLE: 'text-green',
      LOW_STOCK: 'text-yellow-500',
      NOT_AVAILABLE: 'text-red',
    },
  },
});

const renderPrice = (
  price?: number,
  size: 'small' | 'medium' | 'large' | 'xSmall' | undefined = 'small',
  valueClassName = '',
) => {
  if (price) {
    return <Price size={size} value={price} valueClassName={valueClassName} variant="info" />;
  } else {
    return <Skeleton className="w-15 h-4" />;
  }
};

const createTableDataItems = ({
  articles,
  accountPriceInfo,
}: {
  articles?: IArticle[];
  accountPriceInfo?: IAccountPriceInfBlock[];
}) => {
  if (!articles?.length || !getPackagingOptions) {
    return null;
  }

  return articles.map(article => {
    const accountPrice = accountPriceInfo?.find(priceInfo => priceInfo.price.id === article.id)?.price;

    const { netunitprice: netPrice, grossunitprice: grossPrice, linediscount } = accountPrice || {};
    const privatePrice = article.pricing?.privatePrice ?? 0;

    return {
      netPrice: renderPrice(netPrice),
      grossPrice: renderPrice(grossPrice),
      privatePrice: renderPrice(privatePrice, 'xSmall', 'text-blue'),
      discount: (
        <CardLabel className="bg-yellow float-right w-fit" round="left">
          {linediscount}%
        </CardLabel>
      ),
    };
  });
};

const getTrPrefix = ({
  articles,
  index,
  selectedArticleIdsObject,
  updateSelectedArticlesIdsObject,
  stock,
  stockStatusTranslations,
}: {
  articles: IArticle[];
  index: number;
  selectedArticleIdsObject: ArticleIdsObject;
  updateSelectedArticlesIdsObject: (id: string, newObject: ArticleValues) => void;
  stock?: IArticleStock;
  stockStatusTranslations?: { [key: string]: string };
}) => {
  const { visible: showUnits } = unitConfig;
  const article = articles[index];
  const packagingOptions = getPackagingOptions(article);

  const { id: articleId, stockproductnumber, unit: defaultUnit, name } = article;
  const { unit = defaultUnit, quantity = 0 } = selectedArticleIdsObject[articleId] || {};

  const stockStatus = stock?.find(stockItem => stockItem.id === stockproductnumber)?.availability;

  return (
    <tr className="border-t-[0.1rem] px-3 py-4">
      <td colSpan={3}>
        <div className="flex flex-col gap-5 px-3 py-2">
          <div className="flex flex-row justify-between">
            <div>{name}</div>
            <div>
              {stockStatus ? (
                <div className={stockStyle({ variant: STOCK_STATUS[stockStatus] })}>
                  {stockStatusTranslations?.[STOCK_STATUS[stockStatus]]}
                </div>
              ) : (
                <Skeleton className="w-15 h-4" />
              )}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div>
              <Counter
                className="mx-auto"
                onChange={quantity => updateSelectedArticlesIdsObject(articleId, { quantity, unit })}
                value={quantity}
              />
            </div>
            {showUnits && (
              <div>
                <Packaging
                  onChange={e => updateSelectedArticlesIdsObject(articleId, { quantity, unit: e.target.value })}
                  packagingOptions={packagingOptions}
                  unit={unit}
                />
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

const onGetTrSuffix = ({ article, discountInfo }: { article?: IArticle; discountInfo: Record<string, string[]> }) => {
  const discounts = discountInfo?.[article?.id ?? ''];

  return discounts?.length ? (
    <tr className="border-b-[0.1rem] px-3 py-4 text-yellow-500">
      <td className="px-3 pb-2" colSpan={3}>
        {discounts.map((discount, index) => (
          <div className="flex flex-col gap-1" key={`${discount}-${index}`}>
            <span>{discount}</span>
          </div>
        ))}
      </td>
    </tr>
  ) : null;
};

const ArticlesTableSecondary = ({
  articles,
  type = 'default',
  isLoading,
  updateSelectedArticlesIdsObject,
  selectedArticleIdsObject,
  showPrivatePrices,
  isLoggedIn,
}: Props) => {
  const { t } = useTranslation(['common', 'product']);
  const [selectedWarehouse, setSelectedWarehouse] = useState<string>('HM');

  const accountPriceInfoData = useAccountPriceInfo(
    articles?.map(article => ({
      skuId: article.id,
      quantity: selectedArticleIdsObject[article.id]?.quantity ?? 0,
      unit: selectedArticleIdsObject[article.id]?.unit ?? article.unit,
    })) ?? [],
  );

  const accountPriceInfo: IAccountPriceInfBlock[] = accountPriceInfoData
    ?.map(data => data.data)
    .filter(price => !!price) as IAccountPriceInfBlock[];

  const { data: articleDiscountInfo } = useArticleDiscountInfo(
    articles?.map(article => {
      const selectedArticle = selectedArticleIdsObject[article.id];

      return {
        skuId: article.id,
        quantity: selectedArticle?.quantity ?? 0,
        unit: selectedArticle?.unit ?? article.unit,
      };
    }) ?? [],
  );

  const setSelectedArticleIdsObject = (id: string, newObject: ArticleValues) => {
    const accountPrice = accountPriceInfo?.find(priceInfo => priceInfo.price.id === id)?.price;

    updateSelectedArticlesIdsObject(id, {
      ...newObject,
      price: accountPrice?.netunitprice,
      discount: accountPrice?.linediscount,
    });
  };

  // Update selected article prices and discounts when article discount info changes
  useEffect(() => {
    articles?.forEach(article => {
      const price = accountPriceInfo?.find(priceInfo => priceInfo.price.id === article.id)?.price;
      const selectedArticle = selectedArticleIdsObject[article.id];

      if (selectedArticle && price?.netunitprice !== selectedArticle?.price) {
        updateSelectedArticlesIdsObject(article.id, {
          ...selectedArticle,
          price: price?.netunitprice,
          discount: price?.linediscount,
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleDiscountInfo]);

  const { data: stock } = useStockByWarehouseIds(
    {
      skus:
        articles?.map(article => ({
          skuid: article.stockproductnumber,
        })) ?? [],
      warehouseIds: [selectedWarehouse],
    },
    !!articles?.length,
  );

  const tableData = useMemo(() => {
    const headerTranslations: {
      [key: string]: string;
    } = t('productPanel.table.headers', {
      ns: 'product',
      returnObjects: true,
    });

    const headers: { key: string; label: string | JSX.Element }[] = [
      {
        key: 'grossPrice',
        label: (
          <div className="flex w-fit flex-col items-end gap-1">
            <span>{headerTranslations?.grossPrice}</span>
            <span>{headerTranslations.unitPrice}</span>
            <span className="text-xs">({headerTranslations.exVat})</span>
          </div>
        ),
      },
      {
        key: 'netPrice',
        label: (
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-end gap-1">
              <span>{headerTranslations?.netPrice}</span>
              <span>{headerTranslations.unitPrice}</span>
              <span className="text-xs">({headerTranslations.exVat})</span>
            </div>
          </div>
        ),
      },
    ];

    if (showPrivatePrices) {
      headers.push({
        key: 'privatePrice',
        label: (
          <div className="flex flex-col content-start items-end gap-1">
            <span>{headerTranslations?.privatePrice}</span>
            <span>{headerTranslations.unitPrice}</span>
          </div>
        ),
      });
    } else {
      headers.push({
        key: 'discount',
        label: '',
      });
    }
    const items = createTableDataItems({
      articles,
      accountPriceInfo: accountPriceInfo ?? [],
    });

    return {
      headers: headers.map(header => ({
        key: header.key as NonPaintHeaderKey | PaintHeaderKey,
        label: header.label,
      })),
      items,
    };
  }, [articles, t, showPrivatePrices, accountPriceInfo]);

  const headers = tableData.headers;

  return (
    <>
      {isLoggedIn && (
        <>
          <StockCheckOptions onUpdateWarehouseId={setSelectedWarehouse} />
          <Presence
            id="articles-table-presence"
            isLoading={isLoading}
            loader={<Skeleton />}
            visible={!!articles?.length}
          >
            {!!tableData.items?.length && (
              <div className="flex flex-col gap-2 overflow-x-auto">
                <Table
                  headers={headers}
                  items={tableData.items}
                  onGetTrPrefix={index =>
                    getTrPrefix({
                      articles: articles ?? [],
                      index,
                      selectedArticleIdsObject,
                      updateSelectedArticlesIdsObject: setSelectedArticleIdsObject,
                      stock,
                      stockStatusTranslations: t('productPanel.stock.status', {
                        ns: 'product',
                        returnObjects: true,
                      }) as {
                        [key: string]: string;
                      },
                    })
                  }
                  onGetTrSuffix={
                    !showPrivatePrices
                      ? index =>
                          onGetTrSuffix({
                            article: articles?.[index],
                            discountInfo: articleDiscountInfo,
                          })
                      : undefined
                  }
                  tdClassName="px-1 py-2 first:text-left last:text-right px-3"
                  thClassName={twMerge('content-start border-transparent last:text-blue px-3 pb-2')}
                  trClassName={type === 'slim' ? 'inline-block mr-4' : 'last:border-b-[0.1rem]'}
                />
              </div>
            )}
          </Presence>
        </>
      )}
    </>
  );
};

export default ArticlesTableSecondary;
