import * as bossClient from '@boss/boss-client';

/**
 * Save service request
 * @param {Record<string, string>} data - Service request data
 * @returns {Promise<void>}
 */
export const saveServiceRequest = async (data: Record<string, unknown>, locale: string): Promise<void> => {
  await bossClient.serviceRequest.saveServiceRequest(data, locale);
};
